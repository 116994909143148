import { InMemoryCache } from '@apollo/client'
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist'
import localforage from 'localforage'

const cache = new InMemoryCache({
  typePolicies: {
    AutosavedForm: {
      keyFields: ['key'],
      fields: {
        clientData: {
          read: (_, { variables }) => {
            const raw = localStorage.getItem(
              variables?.key && `autosave_${variables.key}`
            )
            return JSON.parse(raw || 'null')
          },
        },
        clientLastUpdated: {
          read: (_, { variables }) => {
            const raw = localStorage.getItem(
              variables?.key && `autosave_${variables.key}_lastUpdated`
            )
            return raw ? new Date(raw) : null
          },
        },
        lastUpdated: {
          read: (existing) => {
            if (typeof existing === 'string') return new Date(existing)
            return existing
          },
        },
      },
    },
    User: {
      keyFields: ['id'],
      merge: (existing, incoming) => ({
        ...existing,
        ...incoming,
      }),
    },
    Animator: {
      keyFields: ['id'],
      merge: (existing, incoming) => ({
        ...existing,
        ...incoming,
      }),
    },
    AnimatorStatus: {
      keyFields: ['id'],
      merge: (existing, incoming) => ({
        ...existing,
        ...incoming,
      }),
    },
  },
})

await persistCache({
  cache,
  storage: new LocalForageWrapper(
    localforage.createInstance({
      name: 'apollo-cache-persist',
      driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
      ],
    })
  ),
})

export default cache
