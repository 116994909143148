/* c8 ignore start */

import { useRef, useState } from 'react'
import { MathUtils } from 'three'
import {
  Environment,
  PerformanceMonitor,
  useEnvironment,
} from '@react-three/drei'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import {
  Bloom,
  DepthOfField,
  EffectComposer,
  Noise,
  Vignette,
} from '@react-three/postprocessing'
import { Model as Itklogo } from '@/shared/assets/logos/ItkLogo3d'
import { Model as AnimatorLogo } from '@/shared/assets/logos/animatorLogo3d'
import useNormalizedPathname from '@/shared/hooks/useNormalizedPathname'

// Taken from:
//  https://docs.pmnd.rs/react-three-fiber/getting-started/examples
//  https://codesandbox.io/s/2ycs3

function Logo(props: { index: number; z: number; speed: number }) {
  const ref = useRef<THREE.Mesh>(null!)

  const { viewport, camera } = useThree()
  const { width, height } = viewport.getCurrentViewport(camera, [
    0,
    0,
    -props.z,
  ])

  const [data] = useState({
    // Randomly distributing the objects along the vertical
    y: MathUtils.randFloatSpread(height * 2) - height * 2,
    // This gives us a random value between -1 and 1, we will multiply it with the viewport width
    x: MathUtils.randFloatSpread(2),
    // How fast objects spin, randFlost gives us a value between min and max, in this case 8 and 12
    spin: MathUtils.randFloat(8, 12),
    // Some random rotations, Math.PI represents 360 degrees in radian
    rX: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI,
  })

  // useFrame executes 60 times per second
  useFrame((state, dt) => {
    // Make the X position responsive, slowly scroll objects up at the Y, distribute it along the Z
    // dt is the delta, the time between this frame and the previous, we can use it to be independent of the screens refresh rate
    // We cap dt at 0.1 because now it can't accumulate while the user changes the tab, it will simply stop
    if (dt < 0.1)
      ref.current.position.set(
        props.index === 0 ? 0 : data.x * width,
        (data.y += dt * props.speed),
        -props.z
      )
    // Rotate the object around
    ref.current.rotation.set(
      (data.rX += dt / data.spin),
      Math.sin(props.index * 1000 + state.clock.elapsedTime / 10) * Math.PI,
      (data.rZ += dt / data.spin)
    )
    // If they're too far up, set them back to the bottom
    if (data.y > height * (props.index === 0 ? 4 : 1))
      data.y = -(height * (props.index === 0 ? 4 : 1))
  })

  const [, pathTree] = useNormalizedPathname()

  return (
    <mesh ref={ref}>
      {pathTree[0] === 'animator' ? <AnimatorLogo /> : <Itklogo />}
    </mesh>
  )
}

function LogosEnvironment() {
  const env = useEnvironment({ files: '/env.hdr' })
  return <Environment map={env} />
}

const loginBackdropFactor = 'loginBackdropFactor'

export default function Logos({
  speed = 1,
  count = 30,
  depth = 50,
  easing = (x: number) => Math.sqrt(1 - Math.pow(x - 1, 2)),
}) {
  const calcDpr = (factor: number) => Math.round((0.5 + 1.5 * factor) * 10) / 10
  const calcBloomEnabled = (factor: number) => factor >= 0.7
  const calcBloomHeight = (factor: number) => Math.round(250 + 400 * factor)
  const calcDofEnabled = (factor: number) => factor >= 0.95

  const savedFactor = parseFloat(
    localStorage.getItem(loginBackdropFactor) || ''
  )
  const initialFactor = isNaN(savedFactor) ? 0.5 : savedFactor

  const [adaptiveRendering, setAdaptiveRendering] = useState(false)
  const [dpr, setDpr] = useState(calcDpr(initialFactor))
  const [bloomHeight, setBloomHeight] = useState(calcBloomHeight(initialFactor))
  const [bloomEnabled, setBloomEnabled] = useState(
    calcBloomEnabled(initialFactor)
  )
  const [dofEnabled, setDofEnabled] = useState(calcDofEnabled(initialFactor))

  return (
    <Canvas
      gl={{ antialias: false }}
      dpr={dpr}
      camera={{ position: [0, 0, 10], fov: 20, near: 0.01, far: depth + 15 }}
    >
      <PerformanceMonitor
        onChange={({ factor }) => {
          if (!adaptiveRendering && Math.abs(factor - initialFactor) < 0.2)
            setAdaptiveRendering(true)
          if (adaptiveRendering) {
            setDpr(calcDpr(factor))
            setBloomEnabled(calcBloomEnabled(factor))
            setBloomHeight(calcBloomHeight(factor))
            setDofEnabled(calcDofEnabled(factor))
            localStorage.setItem(loginBackdropFactor, factor.toString())
          }
        }}
      />
      <spotLight position={[10, 20, 10]} penumbra={0.6} intensity={1} />
      {Array.from({ length: count }, (_, i) => (
        <Logo
          key={i}
          index={i}
          z={Math.round(easing(i / count) * depth)}
          speed={speed}
        />
      ))}
      <LogosEnvironment />
      <EffectComposer resolutionScale={0.2}>
        {bloomEnabled ? (
          <Bloom
            luminanceThreshold={0}
            luminanceSmoothing={0.9}
            height={bloomHeight}
            opacity={0.3}
          />
        ) : (
          <></>
        )}
        {dofEnabled ? (
          <DepthOfField
            height={700}
            target={[0, 0, 40]}
            focalLength={1.2}
            bokehScale={12}
          />
        ) : (
          <></>
        )}

        <Noise opacity={0.005} />
        <Vignette eskil={false} offset={0.3} darkness={0.3} />
      </EffectComposer>
    </Canvas>
  )
}

/* c8 ignore end */
