import { Key } from '@mui/icons-material'
import { Button, ButtonProps, CircularProgress, Tooltip } from '@mui/material'
import { useFeatureFlag } from '@/shared/hooks/useFeatureFlag'
import usePasswordlessAuth from '@/shared/hooks/usePasswordlessAuth'

type PasswordlessLoginProps = {
  sx?: ButtonProps['sx']
  disabled?: boolean
  onLoginClicked?: () => void
  onLoginError?: (error: Error) => void
}

export default function PasswordlessLogin(props: PasswordlessLoginProps) {
  const featureFlagEnabled = useFeatureFlag('passwordless_auth')
  const { login, passwordlessLoginSupported, loading } = usePasswordlessAuth()

  const loginClicked = async () => {
    props.onLoginClicked?.()
    try {
      await login()
    } catch (e) {
      console.error('Passwordless login failed:', e)
      props.onLoginError?.(e as Error)
    }
  }

  return featureFlagEnabled && passwordlessLoginSupported ? (
    <Tooltip title="Jelszó nélküli belépés" placement="top">
      <Button
        disabled={props.disabled || loading}
        variant="contained"
        color="secondary"
        sx={props.sx}
        onClick={loginClicked}
      >
        {loading ? <CircularProgress size={20} color="inherit" /> : <Key />}
      </Button>
    </Tooltip>
  ) : (
    <></>
  )
}
