import {
  Button,
  Badge,
  IconButton,
  styled,
  keyframes,
  BadgeProps,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { NotificationsRounded, PersonRounded } from '@mui/icons-material'
import LoginIcon from '@mui/icons-material/Login'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import { useModals } from '@/routes'
import { useIsLoggedInQuery } from '@/gql/generated'
import SessionTimeoutNotification from './SessionTimeoutNotification'
import OfflineModeNotification from './OfflineModeNotification'

const AppBarIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  appRegion: 'no-drag',
  '-webkit-app-region': 'no-drag',
}))

const spin = keyframes`
  from {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2)
  }
  to {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)
  }
}`

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    overflow: 'visible',
    zIndex: 1600,
    animation: `${spin} 1.5s infinite ease`,
  },
}))

export function AppBarContext({
  handleProfileClick,
  pwaUpdateAvailable,
  updateServiceWorker,
  onNotificationClicked,
  titlebar,
  notificationButtonRef,
}: {
  handleProfileClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  pwaUpdateAvailable: boolean
  updateServiceWorker: () => void
  onNotificationClicked?: () => void
  titlebar?: boolean
  notificationButtonRef: React.RefObject<HTMLButtonElement>
}) {
  const isLoggedInQuery = useIsLoggedInQuery()
  const modals = useModals()
  const theme = useTheme()
  const enableDesktopSessionTimeoutNotification = useMediaQuery(
    theme.breakpoints.up('md')
  )

  return (
    <>
      {enableDesktopSessionTimeoutNotification && (
        <SessionTimeoutNotification titlebar={titlebar} />
      )}

      <OfflineModeNotification titlebar={titlebar} />

      {pwaUpdateAvailable && (
        <Tooltip title="Frissítés új verzióra!">
          <AppBarIconButton
            onClick={updateServiceWorker}
            role="button"
            aria-label="update"
            size={titlebar ? 'small' : undefined}
            sx={{ mr: isLoggedInQuery.data?.isLoggedIn ? 0 : 2 }}
          >
            <StyledBadge color="secondary" variant="dot">
              <BrowserUpdatedIcon fontSize={titlebar ? 'small' : undefined} />
            </StyledBadge>
          </AppBarIconButton>
        </Tooltip>
      )}
      {!isLoggedInQuery.data?.isLoggedIn ? (
        <Button
          role="button"
          aria-label="login"
          color="secondary"
          variant="contained"
          size={titlebar ? 'small' : undefined}
          startIcon={<LoginIcon />}
          onClick={() => modals.open('/login')}
          sx={{
            appRegion: 'no-drag',
            '-webkit-app-region': 'no-drag',
          }}
        >
          Bejelentkezés
        </Button>
      ) : (
        <>
          <AppBarIconButton
            onClick={handleProfileClick}
            role="button"
            aria-label="profile"
            size={titlebar ? 'small' : undefined}
          >
            <PersonRounded fontSize={titlebar ? 'small' : undefined} />
          </AppBarIconButton>
          <AppBarIconButton
            ref={notificationButtonRef}
            role="button"
            aria-label="notifications"
            onClick={onNotificationClicked}
            size={titlebar ? 'small' : undefined}
          >
            <NotificationsRounded fontSize={titlebar ? 'small' : undefined} />
          </AppBarIconButton>
        </>
      )}
    </>
  )
}
