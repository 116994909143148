import { Breadcrumbs } from '@mui/material'
import { TransitionLink } from '@/shared/components/RouteComponents'
import usePageTitle from '@/shared/hooks/usePageTitle'

export default function NavigationBreadcrumbs() {
  const [, , tree] = usePageTitle()

  return (
    <Breadcrumbs maxItems={3}>
      {tree.map((page, i) => (
        <TransitionLink
          key={page.path}
          underline="hover"
          color="inherit"
          href={page.path}
          routerLinkProps={{
            replace: i < tree.length - 1,
          }}
        >
          {page.title}
        </TransitionLink>
      ))}
    </Breadcrumbs>
  )
}
