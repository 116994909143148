import {
  Alert,
  Backdrop,
  BackdropProps,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Dialog,
  Snackbar,
  SnackbarCloseReason,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  useIsLoggedInQuery,
  useLoginMutation,
  useHandleLoginMutation,
} from '@/gql/generated'
import useInput from '@/shared/hooks/useInput'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useTheme } from '@mui/material/styles'
import LoginBackdropAnimation from './LoginBackdropAnimation'
import useBattery from '@/shared/hooks/useBattery'
import ContextLogo from '../ContextLogo'
import { useModals } from '@/routes'
import { useFeatureFlag } from '@/shared/hooks/useFeatureFlag'
import PasswordlessLogin from './PasswordlessLogin'

function LoginBackdrop(props: BackdropProps) {
  const featureFlagEnabled = useFeatureFlag('login_backdrop_animation', {
    fetchPolicy: 'cache-and-network',
  })
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion)')
  const battery = useBattery()

  const animationEnabled =
    !sm &&
    !prefersReducedMotion &&
    battery !== undefined &&
    battery.charging &&
    featureFlagEnabled

  return (
    <Backdrop
      sx={{
        backdropFilter: 'blur(3px)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      }}
      {...props}
    >
      <LoginBackdropAnimation
        enabled={animationEnabled}
        data-testid="backdrop"
      />
    </Backdrop>
  )
}

function LoginSnackbar(props: {
  open: boolean
  setOpen: (open: boolean) => void
  error?: Error
}) {
  const snackbarHandleClose = (_: any, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') return
    props.setOpen(false)
  }

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={4000}
      onClose={snackbarHandleClose}
    >
      <Alert onClose={snackbarHandleClose} severity={'error'}>
        {props.error?.message}
      </Alert>
    </Snackbar>
  )
}

export default function Component() {
  const [dialogOpen, setDialogOpen] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [canLogin, setCanLogin] = useState(false)
  const [loginError, setLoginError] = useState<Error | null>(null)

  const name = useInput()
  const password = useInput()

  const [login, { loading }] = useLoginMutation()
  const [handleLogin] = useHandleLoginMutation()
  const isLoggedInQuery = useIsLoggedInQuery()

  useEffect(() => {
    if (!dialogOpen && !isLoggedInQuery.data?.isLoggedIn) {
      setSnackbarOpen(false)
    }
    setDialogOpen(!isLoggedInQuery.data?.isLoggedIn)
  }, [isLoggedInQuery.data, dialogOpen])

  useEffect(() => {
    setCanLogin(name.props.value !== '' && password.props.value !== '')
  }, [name.props.value, password.props.value])

  const modals = useModals()
  useEffect(() => {
    let t: any = null
    if (!dialogOpen) t = setTimeout(() => modals.close(), 250)

    return () => {
      if (t !== null) clearTimeout(t)
    }
  }, [dialogOpen, modals])

  const doLogin = async () => {
    return (
      await login({
        variables: {
          name: name.props.value,
          password: password.props.value,
        },
      })
    ).data!.login
  }

  const loginClicked = async () => {
    setSnackbarOpen(false)

    try {
      const loginResult = await doLogin()

      name.setValue('')
      password.setValue('')
      setSnackbarOpen(false)
      await handleLogin({ variables: { loginResult: loginResult as any } })
      setDialogOpen(false)
    } catch (e) {
      setLoginError(e as Error)
      setSnackbarOpen(true)
    }
  }

  const onInputKeyUp = (event: any) => {
    // if enter pressed
    if (event.keyCode === 13 && canLogin) {
      loginClicked()
    }
  }

  const onPasswordlessLoginClicked = () => {
    setSnackbarOpen(false)
    setCanLogin(false)
  }

  const onPasswordlessLoginError = (e: Error) => {
    setLoginError(e)
    setSnackbarOpen(true)
    setCanLogin(name.props.value !== '' && password.props.value !== '')
  }

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Dialog
        open={dialogOpen}
        slots={{ backdrop: LoginBackdrop }}
        fullScreen={fullscreen}
        PaperProps={{ elevation: 4 }}
      >
        <Card sx={{ height: '100%' }} data-testid="login-dialog">
          <Stack
            sx={{ p: 5, height: '100%' }}
            spacing={4}
            direction="column"
            alignItems="stretch"
          >
            <ContextLogo />

            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              Bejelentkezés
            </Typography>

            <TextField
              label="Shibboleth azonosító"
              required
              // taken from: https://stackoverflow.com/a/69972449
              autoCapitalize="none"
              autoCorrect="off"
              autoComplete="off"
              spellCheck="false"
              // reference: https://github.com/mui/material-ui/issues/10278#issuecomment-818777662
              inputProps={{
                autoCapitalize: 'none',
                autoCorrect: 'off',
                autoComplete: 'off',
                spellCheck: 'false',
              }}
              onKeyUp={onInputKeyUp}
              {...name.props}
            />

            <TextField
              label="Jelszó"
              type="password"
              required
              onKeyUp={onInputKeyUp}
              {...password.props}
            />

            <Stack
              justifyContent="space-between"
              alignSelf="stretch"
              direction="row-reverse"
            >
              <ButtonGroup>
                <PasswordlessLogin
                  disabled={loading}
                  onLoginClicked={onPasswordlessLoginClicked}
                  onLoginError={onPasswordlessLoginError}
                />

                <Button
                  color="secondary"
                  onClick={loginClicked}
                  disabled={!canLogin}
                  variant="contained"
                  endIcon={
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <ExitToAppIcon />
                    )
                  }
                >
                  Belépés
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </Card>
      </Dialog>
      <LoginSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        error={loginError ?? undefined}
      />
    </>
  )
}
