import { useParams } from '@/routes'
import AppShell from '@/shared/components/AppShell'
import NotFound from './404'
import { Outlet } from 'react-router-dom'

export default function App() {
  const params = useParams('/:lang?')

  if (params.lang !== undefined) {
    if (params.lang !== 'en' && params.lang !== 'hu') {
      return (
        <AppShell>
          <NotFound />
        </AppShell>
      )
    }
  }

  return (
    <AppShell>
      <Outlet />
    </AppShell>
  )
}
