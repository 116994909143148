import { light as defaultLightTheme } from '@/shared/components/GlobalThemeProvider/defaultTheme'
import { ReactComponent as ItkAppLogoSVG } from '@/shared/assets/logos/itkapp.svg'
import { ReactComponent as AnimatorLogoSVG } from '@/shared/assets/logos/animator.svg'
import { useTheme } from '@mui/material'

export function ITKAppLogo() {
  return <ItkAppLogoSVG fill={defaultLightTheme.palette.primary.main} />
}

export function AnimatorLogo() {
  const theme = useTheme()
  return <AnimatorLogoSVG style={{ fill: theme.palette.text.primary }} />
}
