import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'

export const OfflineModeContext = createContext({
  isOffline: false,
})

export default function OfflineModeProvider(props: PropsWithChildren) {
  // reference:
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine#basic_usage
  const [isOffline, setIsOffline] = useState(navigator.onLine === false)

  const handleOnline = useCallback(() => setIsOffline(false), [])
  const handleOffline = useCallback(() => setIsOffline(true), [])

  useEffect(() => {
    // reference:
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine#listening_for_changes_in_network_status
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [handleOffline, handleOnline])

  return (
    <OfflineModeContext.Provider
      value={{
        isOffline,
      }}
    >
      {props.children}
    </OfflineModeContext.Provider>
  )
}
