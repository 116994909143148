/* c8 ignore start */

import { Suspense, lazy } from 'react'

const Animation = lazy(() => import('./Animation'))

function Component(props: { enabled?: boolean }) {
  const { enabled, ...other } = props
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: enabled ? 'inline' : 'none',
      }}
      {...other}
    >
      <Suspense fallback={null}>
        {enabled ? <Animation speed={1} /> : <></>}
      </Suspense>
    </div>
  )
}

export default Component

/* c8 ignore stop */
