import { ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  PaperProps,
  Stack,
} from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PickerButton } from '@/shared/components/AppShell/MobileNavigationPopup/ModulePicker'
import ModulePages from '@/shared/components/ModulePages'
import useNormalizedPathname from '@/shared/hooks/useNormalizedPathname'
import { metas, modules } from '@/modulePages'
import LogoPaperBase from '../../Logos/LogoPaperBase'
import { useMeQuery } from '@/gql/generated'
import { Transition, TransitionStatus } from 'react-transition-group'

/**
 * Manages the persistent state if the user prefers to leave the desktop side bar open or closed.
 *
 * @param defaultState if the user has no preference, use a default state
 */
export function useSideBarPreference(
  defaultState?: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const storageKey = 'preferSidebarOpen'

  const read = () => {
    const value = localStorage.getItem(storageKey)
    if (value === null || value === undefined) return defaultState || false // default of default is false :P
    return value === '1'
  }

  const save = (state: boolean) =>
    localStorage.setItem(storageKey, state ? '1' : '0')

  const [currentState, setCurrentState] = useState(read())

  useEffect(() => {
    save(currentState)
  }, [currentState])

  return [currentState, setCurrentState]
}

function ModulePicker({ sideBarOpen }: { sideBarOpen: boolean }) {
  const [path] = useNormalizedPathname()
  const { data: meQuery } = useMeQuery()

  /**
   * determines if the currently visited path is under the given module root path
   */
  const isModuleCurrentPath = (moduleHref: string) => {
    if (moduleHref === '/') return path === '/'
    return path.startsWith(moduleHref)
  }

  const currentModule = modules.find((module) =>
    isModuleCurrentPath(module.path)
  ) ?? { path: '/', ...metas['/'] }

  const [openModuleSelector, setOpenModuleSelector] = useState(false)

  useEffect(() => {
    setOpenModuleSelector(false)
  }, [sideBarOpen, path])

  const modulePopdownClicked = () => {
    setOpenModuleSelector((open) => !open)
  }

  return (
    <>
      {/* Current module - popdown toggle button */}
      <ListItem sx={{ p: 0 }}>
        <ListItemButton
          sx={{ p: 2 }}
          selected={openModuleSelector}
          onClick={modulePopdownClicked}
        >
          <ListItemIcon>
            <LogoPaperBase small>
              {currentModule.icon && <currentModule.icon />}
            </LogoPaperBase>
          </ListItemIcon>
          <ListItemText primary={currentModule.label ?? currentModule.title} />
          <ListItemIcon sx={{ flexDirection: 'row-reverse', pr: 1 }}>
            <ExpandMore
              sx={{
                transform: openModuleSelector
                  ? 'rotate(0deg)'
                  : 'rotate(90deg)',
                transition: 'all 200ms ease-in-out',
              }}
            />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>

      {/* Available modules selector */}
      {/* Flex shrink has to be zero, otherwise an overflowing page list would squash this opened collapse */}
      <Collapse in={openModuleSelector} sx={{ flexShrink: 0 }}>
        <Divider />
        <ListItem>
          <Stack direction="row" flexWrap="wrap" useFlexGap={true} gap={2}>
            {modules
              .filter((module) => module.path !== currentModule.path)
              .filter(
                (module) =>
                  !(
                    module.path === '/animator' &&
                    !meQuery?.me?.roles?.includes('animator')
                  )
              )
              .map((module) => (
                <PickerButton
                  key={module.path}
                  href={module.path}
                  label={module.label ?? (module.title || module.path)}
                  stackPadding={0}
                  width={80}
                >
                  <LogoPaperBase small>
                    {module.icon && <module.icon />}
                  </LogoPaperBase>
                </PickerButton>
              ))}
          </Stack>
        </ListItem>
      </Collapse>
    </>
  )
}

type DesktopSideBarProps = Partial<PaperProps>

const DesktopSideBar = React.forwardRef<any, DesktopSideBarProps>(
  ({ ...paperProps }, ref) => {
    return (
      <Paper
        ref={ref}
        {...paperProps}
        sx={{
          pl: `${offset}px`,
          ml: `-${offset}px`,
          flexGrow: 1,
          maxWidth: `${maxWidth}px`,
          width: '100%',
        }}
      >
        <List
          sx={{
            pt: 0,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%',
          }}
        >
          <ModulePicker sideBarOpen={true} />

          <Divider />

          <ModulePages />
        </List>
      </Paper>
    )
  }
)

const defaultStyle = {
  transition: `transform 320ms cubic-bezier(.28,.28,.04,1.26), margin-right 320ms cubic-bezier(.28,.28,.04,1.26)`,
  transform: 'translateX(0)',
  marginRight: 0,
}

type TransitionStyles = Partial<{
  [key in TransitionStatus]: React.CSSProperties
}>

const maxWidth = 320
const offset = 48

const transitionStyles: TransitionStyles = {
  entering: { transform: 'translateX(0)', marginRight: 0 },
  entered: { transform: 'translateX(0)', marginRight: 0 },
  exiting: { transform: 'translateX(-100%)', marginRight: -320 + offset },
  exited: { transform: 'translateX(-100%)', marginRight: -320 + offset },
  unmounted: { transform: 'translateX(-100%)', marginRight: -320 + offset },
}

type WrapperProps = {
  enabled: boolean
  appBarButtonRef: React.RefObject<HTMLButtonElement>
} & DesktopSideBarProps

export default function Wrapper(props: WrapperProps) {
  const { enabled, appBarButtonRef, ...rest } = props
  const [openPreferred, setOpenPreferred] = useSideBarPreference(true)
  const [open, setOpen] = useState(openPreferred)
  const nodeRef = useRef(null)

  const onAppButtonClicked = useCallback(() => {
    if (!enabled) return
    setOpen((open) => !open)
  }, [enabled])

  useEffect(() => {
    if (!appBarButtonRef.current) return
    const button = appBarButtonRef.current

    button.addEventListener('click', onAppButtonClicked)
    return () => button.removeEventListener('click', onAppButtonClicked)
  }, [appBarButtonRef, onAppButtonClicked])

  useEffect(() => {
    if (enabled) setOpenPreferred(open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, open])

  return (
    <Transition
      nodeRef={nodeRef}
      in={open && openPreferred && enabled}
      timeout={{
        enter: 310,
        appear: 0,
        exit: 310,
      }}
      unmountOnExit
      mountOnEnter
    >
      {(state) => (
        <DesktopSideBar
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          {...rest}
        />
      )}
    </Transition>
  )
}
