import { AppBar, styled } from '@mui/material'
import { AppBarContent, AppBarProps } from './AppBar'

// reference:
// https://developer.mozilla.org/en-US/docs/Web/CSS/env#syntax
// https://developer.mozilla.org/en-US/docs/Web/API/WindowControlsOverlay
// https://developer.mozilla.org/en-US/docs/Web/Manifest/display_override#values

// for testing different layouts:
// https://devtoolstips.org/tips/en/simulate-pwa-wco/

const Wrapper = styled('div')(({ theme }) => ({
  appRegion: 'drag',
  '-webkit-app-region': 'drag',
  position: 'fixed',
  left: 0,
  top: 0,
  zIndex: 1200,
  width: '100%',
  height: 'env(titlebar-area-height, 64px)',
}))

const Offset = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  minHeight: 'env(titlebar-area-height, 64px) !important',
  height: 'env(titlebar-area-height, 64px) !important',
}))

export default function StandaloneAppBar(props: AppBarProps) {
  return (
    <>
      <Offset />
      <Wrapper>
        <AppBar
          elevation={2}
          sx={{
            position: 'relative',
            height: '100%',
            borderRadius: 0,
            justifyContent: 'center',
            pl: '1em',
          }}
        >
          <AppBarContent titlebar {...props} />
        </AppBar>
      </Wrapper>
      {props.children}
    </>
  )
}
