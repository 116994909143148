import { Button, ButtonProps, Link, LinkProps } from '@mui/material'
import React, { MouseEvent } from 'react'
import { flushSync } from 'react-dom'
import {
  NavigateFunction,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useNavigate,
} from 'react-router-dom'

// Routing components that use React Router to navigate

export function RouteLink(props: LinkProps) {
  const { href, ...others } = props
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={href || ''} ref={ref} {...itemProps} />
      )),
    [href]
  )
  return <Link component={renderLink} {...others} />
}

export function RouteButton(props: ButtonProps) {
  const { href, ...others } = props
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={href as string} ref={ref} {...itemProps} />
      )),
    [href]
  )
  return <Button href={href} LinkComponent={renderLink} {...others} />
}

// Routing components that use View Transition API and React Router to navigate
// reference: https://developer.chrome.com/docs/web-platform/view-transitions/

function navigateWithTransition(
  href: string | undefined,
  navigate: NavigateFunction
) {
  if (!href) return

  /* c8 ignore next 8 */
  // taken from: https://dev.to/link2twenty/view-transitions-api-5980
  if (document.startViewTransition) {
    document.startViewTransition(() => flushSync(() => navigate(href)))
  } else {
    navigate(href)
  }
}

export function TransitionLink(
  props: LinkProps & { routerLinkProps?: Partial<RouterLinkProps> }
) {
  const { href, routerLinkProps, ...others } = props
  const navigate = useNavigate()

  const renderLink = React.useMemo(() => {
    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      navigateWithTransition(href, navigate)
    }

    return React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
      (itemProps, ref) => (
        <RouterLink
          ref={ref}
          {...itemProps}
          {...routerLinkProps}
          to={href as string}
          onClick={onClick}
        />
      )
    )
  }, [href, navigate, routerLinkProps])

  if (href?.startsWith('http')) return <Link href={href} {...others} />

  return <Link href={href} component={renderLink} {...others} />
}

export function TransitionButton(
  props: ButtonProps & { routerLinkProps?: Partial<RouterLinkProps> }
) {
  const { href, routerLinkProps, ...others } = props
  const navigate = useNavigate()

  const renderLink = React.useMemo(() => {
    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      navigateWithTransition(href, navigate)
    }

    return React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
      (itemProps, ref) => (
        <RouterLink
          ref={ref}
          {...itemProps}
          {...routerLinkProps}
          to={href as string}
          onClick={onClick}
        />
      )
    )
  }, [href, navigate, routerLinkProps])

  if (href?.startsWith('http')) return <Button href={href} {...others} />

  return <Button href={href} LinkComponent={renderLink} {...others} />
}
