import { MDXComponents } from '@mdx-js/react/lib'
import { Typography } from '@mui/material'
import { TransitionLink } from './shared/components/RouteComponents'

function propsWithoutRef(props: any) {
  const { ref, ...other } = props
  return other
}

type HeadingShiftLevel = 1 | 2 | 3 | 4 | 5 | 6

export function componentsWithShiftedHeadings(shiftLevel: HeadingShiftLevel) {
  const shiftedComponents: MDXComponents = {}
  for (const [key, value] of Object.entries(components)) {
    if (key.startsWith('h')) {
      const headingLevel = parseInt(key[1])
      const shiftedHeadingLevel = headingLevel - shiftLevel
      if (shiftedHeadingLevel < 1 || shiftedHeadingLevel > 6) {
        continue
      }
      shiftedComponents[`h${shiftedHeadingLevel}` as keyof MDXComponents] =
        value
    } else {
      shiftedComponents[key as keyof MDXComponents] = value
    }
  }
  return shiftedComponents
}

const components = {
  p: (props) => (
    <Typography paragraph textAlign={'justify'} {...propsWithoutRef(props)} />
  ),
  h1: (props) => <Typography variant="h1" {...propsWithoutRef(props)} />,
  h2: (props) => <Typography variant="h2" {...propsWithoutRef(props)} />,
  h3: (props) => <Typography variant="h3" {...propsWithoutRef(props)} />,
  h4: (props) => <Typography variant="h4" {...propsWithoutRef(props)} />,
  h5: (props) => <Typography variant="h5" {...propsWithoutRef(props)} />,
  h6: (props) => <Typography variant="h6" {...propsWithoutRef(props)} />,
  a: (props) => <TransitionLink {...propsWithoutRef(props)} />,
} as const as MDXComponents

export default components
