import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: object;
  UUID: string;
};

/** ANIMATOR */
export type Animator = {
  __typename?: 'Animator';
  events: Array<AnimatorEvent>;
  id: Scalars['Int'];
  role: Scalars['String'];
  status: Scalars['String'];
  user: User;
};

export type AnimatorEvent = {
  __typename?: 'AnimatorEvent';
  content?: Maybe<Scalars['JSON']>;
  date: Scalars['DateTime'];
  id: Scalars['Int'];
  presence: Array<Animator>;
  title: Scalars['String'];
  type: Scalars['String'];
  year: AnimatorYear;
};

export type AnimatorEventInput = {
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type: Scalars['String'];
  year: AnimatorYearInput;
};

/** ANIMATOR GATHERING */
export type AnimatorGathering = {
  __typename?: 'AnimatorGathering';
  amITheChairman: Scalars['Boolean'];
  chairman: Animator;
  completed: Scalars['Boolean'];
  event: AnimatorEvent;
  id: Scalars['Int'];
  location: Scalars['String'];
  participantCount: Scalars['Int'];
  scheduledStartTime: Scalars['DateTime'];
  stages: Array<AnimatorGatheringStage>;
  started: Scalars['Boolean'];
};

export type AnimatorGatheringAgendaItem = {
  __typename?: 'AnimatorGatheringAgendaItem';
  completed: Scalars['Boolean'];
  content: Scalars['JSON'];
  id: Scalars['Int'];
  order: Scalars['Float'];
  polls: Array<AnimatorGatheringPoll>;
  title: Scalars['String'];
};

export type AnimatorGatheringAgendaItemInput = {
  content?: InputMaybe<Scalars['JSON']>;
  id: Scalars['Int'];
  order: Scalars['Float'];
  polls?: InputMaybe<Array<AnimatorGatheringPollInput>>;
  title: Scalars['String'];
};

export type AnimatorGatheringAgendaStage = AnimatorGatheringStage & {
  __typename?: 'AnimatorGatheringAgendaStage';
  agendaItems: Array<AnimatorGatheringAgendaItem>;
  completed: Scalars['Boolean'];
  id: Scalars['Int'];
  order: Scalars['Float'];
  title: Scalars['String'];
};

/** ANIMATOR GATHERING INPUTS */
export type AnimatorGatheringInput = {
  chairmanAnimatorId: Scalars['Int'];
  event: AnimatorEventInput;
  id?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
  scheduledStartTime: Scalars['DateTime'];
  stages: Array<AnimatorGatheringStageInput>;
};

export type AnimatorGatheringPoll = {
  __typename?: 'AnimatorGatheringPoll';
  closed: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  final: Scalars['Boolean'];
  id: Scalars['Int'];
  opened: Scalars['Boolean'];
  options: Array<AnimatorGatheringPollOption>;
  order: Scalars['Float'];
  ownVote?: Maybe<AnimatorGatheringPollOption>;
  predecessor?: Maybe<AnimatorGatheringPoll>;
  round: Scalars['Int'];
  successor?: Maybe<AnimatorGatheringPoll>;
  title: Scalars['String'];
  totalVoteCount: Scalars['Int'];
  valid: Scalars['Boolean'];
  winningOption?: Maybe<AnimatorGatheringPollOption>;
};

export type AnimatorGatheringPollInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  options: Array<AnimatorGatheringPollOptionInput>;
  order: Scalars['Float'];
  title: Scalars['String'];
};

export type AnimatorGatheringPollOption = {
  __typename?: 'AnimatorGatheringPollOption';
  abstention: Scalars['Boolean'];
  id: Scalars['Int'];
  order: Scalars['Float'];
  title: Scalars['String'];
  voteCount: Scalars['Int'];
};

export type AnimatorGatheringPollOptionInput = {
  id: Scalars['Int'];
  order: Scalars['Float'];
  title: Scalars['String'];
};

export type AnimatorGatheringPollStage = AnimatorGatheringStage & {
  __typename?: 'AnimatorGatheringPollStage';
  completed: Scalars['Boolean'];
  id: Scalars['Int'];
  order: Scalars['Float'];
  poll: AnimatorGatheringPoll;
  title: Scalars['String'];
};

export type AnimatorGatheringPresenceQueryOutput = {
  __typename?: 'AnimatorGatheringPresenceQueryOutput';
  activeMemberCount?: Maybe<Scalars['Int']>;
  activeMemberPresenceCount?: Maybe<Scalars['Int']>;
  gatheringEligibleToStart?: Maybe<Scalars['Boolean']>;
  presence: Array<Animator>;
  validVoterCount?: Maybe<Scalars['Int']>;
};

export type AnimatorGatheringStage = {
  completed: Scalars['Boolean'];
  id: Scalars['Int'];
  order: Scalars['Float'];
  title: Scalars['String'];
};

export type AnimatorGatheringStageInput = {
  agendaItems?: InputMaybe<Array<AnimatorGatheringAgendaItemInput>>;
  content?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['Int']>;
  order: Scalars['Float'];
  poll?: InputMaybe<AnimatorGatheringPollInput>;
  title: Scalars['String'];
};

export type AnimatorGatheringTextStage = AnimatorGatheringStage & {
  __typename?: 'AnimatorGatheringTextStage';
  completed: Scalars['Boolean'];
  content: Scalars['JSON'];
  id: Scalars['Int'];
  order: Scalars['Float'];
  title: Scalars['String'];
};

export type AnimatorRole = {
  __typename?: 'AnimatorRole';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AnimatorStatus = {
  __typename?: 'AnimatorStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AnimatorYear = {
  __typename?: 'AnimatorYear';
  events: Array<AnimatorEvent>;
  id: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
};

export type AnimatorYearInput = {
  id?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type AutosavedForm = {
  __typename?: 'AutosavedForm';
  clientData?: Maybe<Scalars['JSON']>;
  clientLastUpdated?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  key: Scalars['String'];
  lastUpdated?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagDefiniton = {
  __typename?: 'FeatureFlagDefiniton';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type HandleLoginInput = {
  roles: Array<Scalars['String']>;
  token: Scalars['String'];
  user: UserInput;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  roles?: Maybe<Array<Scalars['String']>>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type MeResult = {
  __typename?: 'MeResult';
  roles: Array<Scalars['String']>;
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  advanceAnimatorGatheringStage: Scalars['Boolean'];
  animatorGatheringPresenceAddParticipant: Scalars['Boolean'];
  closeAnimatorGatheringPoll: Scalars['Boolean'];
  completeAnimatorGathering: Scalars['Boolean'];
  createAnimatorEvent: Scalars['Boolean'];
  createAnimatorGathering: Scalars['Int'];
  createAnimatorGatheringPoll: Scalars['Boolean'];
  createAnimatorGatheringPollOption: AnimatorGatheringPollOption;
  createAnimatorGatheringPollRevote: Scalars['Int'];
  deleteAnimatorGatheringPollOption: Scalars['Boolean'];
  deleteAutosavedForm?: Maybe<Scalars['Boolean']>;
  finalizeAnimatorGatheringPoll: Scalars['Boolean'];
  handleLogin: Scalars['Boolean'];
  handleTokenRenewal: Scalars['Boolean'];
  joinAnimatorGatheringManually: Scalars['Boolean'];
  login: LoginResult;
  logout: Scalars['Boolean'];
  openAnimatorGatheringPoll: Scalars['Boolean'];
  passwordlessLogin: LoginResult;
  recedeAnimatorGatheringStage: Scalars['Boolean'];
  registerPasswordlessCredentials?: Maybe<Scalars['Boolean']>;
  renameAnimatorGatheringPoll: Scalars['Boolean'];
  renewUserSessionToken?: Maybe<Scalars['String']>;
  reorderAnimatorGatheringPollOptions: Scalars['Boolean'];
  requestPasswordlessChallenge: Scalars['UUID'];
  saveAutosavedForm?: Maybe<AutosavedForm>;
  startAnimatorGathering?: Maybe<Scalars['Boolean']>;
  voteOnAnimatorGatheringPoll: Scalars['Boolean'];
};


export type MutationAdvanceAnimatorGatheringStageArgs = {
  gatheringId: Scalars['Int'];
};


export type MutationAnimatorGatheringPresenceAddParticipantArgs = {
  animatorId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationCloseAnimatorGatheringPollArgs = {
  id: Scalars['Int'];
};


export type MutationCompleteAnimatorGatheringArgs = {
  id: Scalars['Int'];
};


export type MutationCreateAnimatorEventArgs = {
  event: AnimatorEventInput;
};


export type MutationCreateAnimatorGatheringArgs = {
  creator: Scalars['Int'];
  gathering: AnimatorGatheringInput;
};


export type MutationCreateAnimatorGatheringPollArgs = {
  afterStageId?: InputMaybe<Scalars['Int']>;
  agendaItemId?: InputMaybe<Scalars['Int']>;
  poll: AnimatorGatheringPollInput;
};


export type MutationCreateAnimatorGatheringPollOptionArgs = {
  abstention?: InputMaybe<Scalars['Boolean']>;
  pollId: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationCreateAnimatorGatheringPollRevoteArgs = {
  fromId: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteAnimatorGatheringPollOptionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteAutosavedFormArgs = {
  key: Scalars['String'];
};


export type MutationFinalizeAnimatorGatheringPollArgs = {
  id: Scalars['Int'];
  valid: Scalars['Boolean'];
};


export type MutationHandleLoginArgs = {
  loginResult: HandleLoginInput;
};


export type MutationHandleTokenRenewalArgs = {
  token: Scalars['String'];
};


export type MutationJoinAnimatorGatheringManuallyArgs = {
  gatheringId: Scalars['Int'];
};


export type MutationLoginArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationOpenAnimatorGatheringPollArgs = {
  id: Scalars['Int'];
};


export type MutationPasswordlessLoginArgs = {
  authentication: Scalars['JSONObject'];
};


export type MutationRecedeAnimatorGatheringStageArgs = {
  gatheringId: Scalars['Int'];
};


export type MutationRegisterPasswordlessCredentialsArgs = {
  registration: Scalars['JSONObject'];
};


export type MutationRenameAnimatorGatheringPollArgs = {
  id: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationReorderAnimatorGatheringPollOptionsArgs = {
  afterId?: InputMaybe<Scalars['Int']>;
  beforeId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationSaveAutosavedFormArgs = {
  data: Scalars['JSON'];
  key: Scalars['String'];
};


export type MutationStartAnimatorGatheringArgs = {
  id: Scalars['Int'];
};


export type MutationVoteOnAnimatorGatheringPollArgs = {
  optionId: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  animator: Array<Animator>;
  animatorActiveGathering?: Maybe<AnimatorGathering>;
  animatorCurrentLeadershipMembers: Array<Animator>;
  animatorEvent: Array<AnimatorEvent>;
  animatorGathering: Array<AnimatorGathering>;
  animatorGatheringPoll: AnimatorGatheringPoll;
  animatorGatheringPresence: AnimatorGatheringPresenceQueryOutput;
  animatorGatheringPresenceAddParticipantsList: Array<Animator>;
  animatorSelf?: Maybe<Animator>;
  animatorStatus: Array<AnimatorStatus>;
  animatorUpcomingGathering?: Maybe<AnimatorGathering>;
  animatorYear?: Maybe<Array<Maybe<AnimatorYear>>>;
  autosavedForm: AutosavedForm;
  featureFlag: Scalars['Boolean'];
  featureFlagDefinitions: Array<FeatureFlagDefiniton>;
  getAllowedPasswordlessCredentials: Array<Scalars['String']>;
  isLoggedIn: Scalars['Boolean'];
  me?: Maybe<MeResult>;
};


export type QueryAnimatorArgs = {
  id?: InputMaybe<Scalars['Int']>;
  nameSearch?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type QueryAnimatorEventArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryAnimatorGatheringArgs = {
  completed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  started?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryAnimatorGatheringPollArgs = {
  id: Scalars['Int'];
};


export type QueryAnimatorGatheringPresenceArgs = {
  id: Scalars['Int'];
};


export type QueryAnimatorGatheringPresenceAddParticipantsListArgs = {
  gatheringId: Scalars['Int'];
};


export type QueryAnimatorYearArgs = {
  id?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryAutosavedFormArgs = {
  key: Scalars['String'];
};


export type QueryFeatureFlagArgs = {
  name: Scalars['String'];
};


export type QueryFeatureFlagDefinitionsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  searchName?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllowedPasswordlessCredentialsArgs = {
  username: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  animatorGatheringParticipantJoined?: Maybe<Animator>;
  animatorGatheringPollUpdated?: Maybe<AnimatorGatheringPoll>;
  animatorGatheringUpdated?: Maybe<AnimatorGathering>;
  autosavedFormUpdated?: Maybe<AutosavedForm>;
};


export type SubscriptionAnimatorGatheringParticipantJoinedArgs = {
  id: Scalars['Int'];
};


export type SubscriptionAnimatorGatheringPollUpdatedArgs = {
  id: Scalars['Int'];
};


export type SubscriptionAnimatorGatheringUpdatedArgs = {
  id: Scalars['Int'];
};


export type SubscriptionAutosavedFormUpdatedArgs = {
  key: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Int'];
  ldapUsername: Scalars['String'];
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Int'];
  ldapUsername: Scalars['String'];
};

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type IsLoggedInQuery = { __typename?: 'Query', isLoggedIn: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'MeResult', roles: Array<string>, user: { __typename?: 'User', id: number, ldapUsername: string, email?: string | null, familyName: string, givenName: string } } | null };

export type LoginMutationVariables = Exact<{
  name: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResult', token?: string | null, roles?: Array<string> | null, user?: { __typename?: 'User', id: number, ldapUsername: string, email?: string | null, familyName: string, givenName: string } | null } };

export type RenewUserSessionTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RenewUserSessionTokenMutation = { __typename?: 'Mutation', renewUserSessionToken?: string | null };

export type HandleLoginMutationVariables = Exact<{
  loginResult: HandleLoginInput;
}>;


export type HandleLoginMutation = { __typename?: 'Mutation', handleLogin: boolean };

export type HandleTokenRenewalMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type HandleTokenRenewalMutation = { __typename?: 'Mutation', handleTokenRenewal: boolean };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type AnimatorEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnimatorEventsQuery = { __typename?: 'Query', animatorEvent: Array<{ __typename?: 'AnimatorEvent', id: number, type: string, title: string, date: any }> };

export type AnimatorEventDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnimatorEventDetailsQuery = { __typename?: 'Query', animatorEvent: Array<{ __typename?: 'AnimatorEvent', type: string, title: string, date: any }> };

export type StartAnimatorGatheringMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StartAnimatorGatheringMutation = { __typename?: 'Mutation', startAnimatorGathering?: boolean | null };

export type AdvanceAnimatorGatheringStageMutationVariables = Exact<{
  gatheringId: Scalars['Int'];
}>;


export type AdvanceAnimatorGatheringStageMutation = { __typename?: 'Mutation', advanceAnimatorGatheringStage: boolean };

export type RecedeAnimatorGatheringStageMutationVariables = Exact<{
  gatheringId: Scalars['Int'];
}>;


export type RecedeAnimatorGatheringStageMutation = { __typename?: 'Mutation', recedeAnimatorGatheringStage: boolean };

export type CompleteAnimatorGatheringMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteAnimatorGatheringMutation = { __typename?: 'Mutation', completeAnimatorGathering: boolean };

export type CreateAnimatorGatheringPollMutationVariables = Exact<{
  afterStageId?: InputMaybe<Scalars['Int']>;
  agendaItemId?: InputMaybe<Scalars['Int']>;
  poll: AnimatorGatheringPollInput;
}>;


export type CreateAnimatorGatheringPollMutation = { __typename?: 'Mutation', createAnimatorGatheringPoll: boolean };

export type JoinAnimatorGatheringInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type JoinAnimatorGatheringInfoQuery = { __typename?: 'Query', animatorSelf?: { __typename?: 'Animator', id: number, status: string } | null };

export type JoinAnimatorGatheringManuallyMutationVariables = Exact<{
  gatheringId: Scalars['Int'];
}>;


export type JoinAnimatorGatheringManuallyMutation = { __typename?: 'Mutation', joinAnimatorGatheringManually: boolean };

export type AnimatorGatheringPresenceAddParticipantsListQueryVariables = Exact<{
  gatheringId: Scalars['Int'];
}>;


export type AnimatorGatheringPresenceAddParticipantsListQuery = { __typename?: 'Query', animatorGatheringPresenceAddParticipantsList: Array<{ __typename?: 'Animator', id: number, status: string, user: { __typename?: 'User', id: number, ldapUsername: string, familyName: string, givenName: string } }> };

export type AnimatorGatheringPresenceAddParticipantMutationVariables = Exact<{
  id: Scalars['Int'];
  animatorId: Scalars['Int'];
}>;


export type AnimatorGatheringPresenceAddParticipantMutation = { __typename?: 'Mutation', animatorGatheringPresenceAddParticipant: boolean };

export type AnimatorGatheringMemberListStatusListQueryVariables = Exact<{ [key: string]: never; }>;


export type AnimatorGatheringMemberListStatusListQuery = { __typename?: 'Query', animatorStatus: Array<{ __typename?: 'AnimatorStatus', id: number, name: string }> };

export type AnimatorGatheringPresenceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnimatorGatheringPresenceQuery = { __typename?: 'Query', animatorGatheringPresence: { __typename?: 'AnimatorGatheringPresenceQueryOutput', gatheringEligibleToStart?: boolean | null, activeMemberCount?: number | null, activeMemberPresenceCount?: number | null, validVoterCount?: number | null, presence: Array<{ __typename?: 'Animator', id: number, status: string, user: { __typename?: 'User', id: number, ldapUsername: string, familyName: string, givenName: string } }> } };

export type AnimatorGatheringParticipantJoinedSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnimatorGatheringParticipantJoinedSubscription = { __typename?: 'Subscription', animatorGatheringParticipantJoined?: { __typename?: 'Animator', id: number, status: string, user: { __typename?: 'User', id: number, ldapUsername: string, familyName: string, givenName: string } } | null };

export type AnimatorGatheringUpdatedSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnimatorGatheringUpdatedSubscription = { __typename?: 'Subscription', animatorGatheringUpdated?: { __typename?: 'AnimatorGathering', id: number, completed: boolean, started: boolean, participantCount: number, amITheChairman: boolean, event: { __typename?: 'AnimatorEvent', title: string }, stages: Array<{ __typename?: 'AnimatorGatheringAgendaStage', id: number, title: string, completed: boolean, order: number, agendaItems: Array<{ __typename?: 'AnimatorGatheringAgendaItem', id: number, title: string, content: any, completed: boolean, order: number, polls: Array<{ __typename?: 'AnimatorGatheringPoll', id: number, opened: boolean, closed: boolean, final: boolean }> }> } | { __typename?: 'AnimatorGatheringPollStage', id: number, title: string, completed: boolean, order: number, poll: { __typename?: 'AnimatorGatheringPoll', id: number } } | { __typename?: 'AnimatorGatheringTextStage', content: any, id: number, title: string, completed: boolean, order: number }> } | null };

export type OpenAnimatorGatheringPollMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OpenAnimatorGatheringPollMutation = { __typename?: 'Mutation', openAnimatorGatheringPoll: boolean };

export type CloseAnimatorGatheringPollMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseAnimatorGatheringPollMutation = { __typename?: 'Mutation', closeAnimatorGatheringPoll: boolean };

export type FinalizeAnimatorGatheringPollMutationVariables = Exact<{
  id: Scalars['Int'];
  valid: Scalars['Boolean'];
}>;


export type FinalizeAnimatorGatheringPollMutation = { __typename?: 'Mutation', finalizeAnimatorGatheringPoll: boolean };

export type CreateAnimatorGatheringPollOptionMutationVariables = Exact<{
  pollId: Scalars['Int'];
  title: Scalars['String'];
  abstention?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateAnimatorGatheringPollOptionMutation = { __typename?: 'Mutation', createAnimatorGatheringPollOption: { __typename?: 'AnimatorGatheringPollOption', id: number } };

export type DeleteAnimatorGatheringPollOptionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAnimatorGatheringPollOptionMutation = { __typename?: 'Mutation', deleteAnimatorGatheringPollOption: boolean };

export type ReorderAnimatorGatheringPollOptionsMutationVariables = Exact<{
  id: Scalars['Int'];
  beforeId?: InputMaybe<Scalars['Int']>;
  afterId?: InputMaybe<Scalars['Int']>;
}>;


export type ReorderAnimatorGatheringPollOptionsMutation = { __typename?: 'Mutation', reorderAnimatorGatheringPollOptions: boolean };

export type CreateAnimatorGatheringPollRevoteMutationVariables = Exact<{
  fromId: Scalars['Int'];
}>;


export type CreateAnimatorGatheringPollRevoteMutation = { __typename?: 'Mutation', createAnimatorGatheringPollRevote: number };

export type RenameAnimatorGatheringPollMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
}>;


export type RenameAnimatorGatheringPollMutation = { __typename?: 'Mutation', renameAnimatorGatheringPoll: boolean };

export type AnimatorGatheringPollDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnimatorGatheringPollDetailsQuery = { __typename?: 'Query', animatorGatheringPoll: { __typename?: 'AnimatorGatheringPoll', id: number, title: string, round: number, description?: string | null, opened: boolean, closed: boolean, valid: boolean, final: boolean, totalVoteCount: number, options: Array<{ __typename?: 'AnimatorGatheringPollOption', id: number, title: string, order: number, voteCount: number, abstention: boolean }>, ownVote?: { __typename?: 'AnimatorGatheringPollOption', id: number } | null, winningOption?: { __typename?: 'AnimatorGatheringPollOption', id: number } | null, predecessor?: { __typename?: 'AnimatorGatheringPoll', id: number } | null, successor?: { __typename?: 'AnimatorGatheringPoll', id: number } | null }, animatorSelf?: { __typename?: 'Animator', id: number, status: string } | null };

export type VoteOnAnimatorGatheringPollMutationVariables = Exact<{
  optionId: Scalars['Int'];
}>;


export type VoteOnAnimatorGatheringPollMutation = { __typename?: 'Mutation', voteOnAnimatorGatheringPoll: boolean };

export type AnimatorGatheringPollUpdatedSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnimatorGatheringPollUpdatedSubscription = { __typename?: 'Subscription', animatorGatheringPollUpdated?: { __typename?: 'AnimatorGatheringPoll', id: number, title: string, round: number, description?: string | null, opened: boolean, closed: boolean, valid: boolean, final: boolean, totalVoteCount: number, options: Array<{ __typename?: 'AnimatorGatheringPollOption', id: number, title: string, order: number, voteCount: number, abstention: boolean }>, ownVote?: { __typename?: 'AnimatorGatheringPollOption', id: number } | null, winningOption?: { __typename?: 'AnimatorGatheringPollOption', id: number } | null, predecessor?: { __typename?: 'AnimatorGatheringPoll', id: number } | null, successor?: { __typename?: 'AnimatorGatheringPoll', id: number } | null } | null };

export type AnimatorGatheringHistoryQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
}>;


export type AnimatorGatheringHistoryQuery = { __typename?: 'Query', animatorGathering: Array<{ __typename?: 'AnimatorGathering', id: number, location: string, participantCount: number, event: { __typename?: 'AnimatorEvent', title: string, date: any }, chairman: { __typename?: 'Animator', id: number, user: { __typename?: 'User', id: number, ldapUsername: string, givenName: string, familyName: string } }, stages: Array<{ __typename?: 'AnimatorGatheringAgendaStage', id: number } | { __typename?: 'AnimatorGatheringPollStage', id: number } | { __typename?: 'AnimatorGatheringTextStage', id: number }> }> };

export type AnimatorGatheringBannerQueryVariables = Exact<{ [key: string]: never; }>;


export type AnimatorGatheringBannerQuery = { __typename?: 'Query', animatorActiveGathering?: { __typename?: 'AnimatorGathering', id: number, location: string, participantCount: number, event: { __typename?: 'AnimatorEvent', title: string } } | null, animatorUpcomingGathering?: { __typename?: 'AnimatorGathering', location: string, scheduledStartTime: any, event: { __typename?: 'AnimatorEvent', title: string } } | null };

export type AnimatorGatheringDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnimatorGatheringDetailsQuery = { __typename?: 'Query', animatorGathering: Array<{ __typename?: 'AnimatorGathering', id: number, completed: boolean, started: boolean, participantCount: number, amITheChairman: boolean, event: { __typename?: 'AnimatorEvent', title: string }, stages: Array<{ __typename?: 'AnimatorGatheringAgendaStage', id: number, title: string, completed: boolean, order: number, agendaItems: Array<{ __typename?: 'AnimatorGatheringAgendaItem', id: number, title: string, content: any, completed: boolean, order: number, polls: Array<{ __typename?: 'AnimatorGatheringPoll', id: number, opened: boolean, closed: boolean, final: boolean, order: number }> }> } | { __typename?: 'AnimatorGatheringPollStage', title: string, id: number, completed: boolean, order: number, poll: { __typename?: 'AnimatorGatheringPoll', id: number } } | { __typename?: 'AnimatorGatheringTextStage', content: any, id: number, title: string, completed: boolean, order: number }> }> };

export type AnimatorSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type AnimatorSelfQuery = { __typename?: 'Query', animatorSelf?: { __typename?: 'Animator', id: number } | null };

export type CreateAnimatorGatheringMutationVariables = Exact<{
  creator: Scalars['Int'];
  gathering: AnimatorGatheringInput;
}>;


export type CreateAnimatorGatheringMutation = { __typename?: 'Mutation', createAnimatorGathering: number };

export type AnimatorMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type AnimatorMembersQuery = { __typename?: 'Query', animator: Array<{ __typename?: 'Animator', id: number, status: string, user: { __typename?: 'User', id: number, ldapUsername: string, familyName: string, givenName: string } }> };

export type AnimatorDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnimatorDetailsQuery = { __typename?: 'Query', animator: Array<{ __typename?: 'Animator', id: number, status: string, role: string, user: { __typename?: 'User', id: number, ldapUsername: string, familyName: string, givenName: string, email?: string | null } }> };

export type AnimatorCurrentLeadershipMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type AnimatorCurrentLeadershipMembersQuery = { __typename?: 'Query', animatorCurrentLeadershipMembers: Array<{ __typename?: 'Animator', id: number, role: string, user: { __typename?: 'User', id: number, familyName: string, givenName: string } }> };

export type AnimatorYearQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
}>;


export type AnimatorYearQuery = { __typename?: 'Query', animatorYear?: Array<{ __typename?: 'AnimatorYear', id: number, year?: number | null } | null> | null };

export type AutosavedFormQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type AutosavedFormQuery = { __typename?: 'Query', autosavedForm: { __typename?: 'AutosavedForm', key: string, data?: any | null, lastUpdated?: any | null, clientData?: any | null, clientLastUpdated?: any | null } };

export type SaveAutosavedFormMutationVariables = Exact<{
  key: Scalars['String'];
  data: Scalars['JSON'];
}>;


export type SaveAutosavedFormMutation = { __typename?: 'Mutation', saveAutosavedForm?: { __typename?: 'AutosavedForm', key: string, data?: any | null, lastUpdated?: any | null, clientData?: any | null, clientLastUpdated?: any | null } | null };

export type DeleteAutosavedFormMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type DeleteAutosavedFormMutation = { __typename?: 'Mutation', deleteAutosavedForm?: boolean | null };

export type AutosavedFormUpdatedSubscriptionVariables = Exact<{
  key: Scalars['String'];
}>;


export type AutosavedFormUpdatedSubscription = { __typename?: 'Subscription', autosavedFormUpdated?: { __typename?: 'AutosavedForm', key: string, data?: any | null, lastUpdated?: any | null, clientData?: any | null, clientLastUpdated?: any | null } | null };

export type FeatureFlagQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type FeatureFlagQuery = { __typename?: 'Query', featureFlag: boolean };

export type RequestPasswordlessChallengeMutationVariables = Exact<{ [key: string]: never; }>;


export type RequestPasswordlessChallengeMutation = { __typename?: 'Mutation', requestPasswordlessChallenge: string };

export type RegisterPasswordlessCredentialsMutationVariables = Exact<{
  registration: Scalars['JSONObject'];
}>;


export type RegisterPasswordlessCredentialsMutation = { __typename?: 'Mutation', registerPasswordlessCredentials?: boolean | null };

export type PasswordlessLoginMutationVariables = Exact<{
  authentication: Scalars['JSONObject'];
}>;


export type PasswordlessLoginMutation = { __typename?: 'Mutation', passwordlessLogin: { __typename?: 'LoginResult', token?: string | null, roles?: Array<string> | null, user?: { __typename?: 'User', id: number, ldapUsername: string, email?: string | null, familyName: string, givenName: string } | null } };

export type GetAllowedPasswordlessCredentialsQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetAllowedPasswordlessCredentialsQuery = { __typename?: 'Query', getAllowedPasswordlessCredentials: Array<string> };

export type UsernameQueryVariables = Exact<{ [key: string]: never; }>;


export type UsernameQuery = { __typename?: 'Query', me?: { __typename?: 'MeResult', user: { __typename?: 'User', id: number, ldapUsername: string } } | null };


export const IsLoggedInDocument = gql`
    query isLoggedIn {
  isLoggedIn @client
}
    `;

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(baseOptions?: Apollo.QueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(IsLoggedInDocument, options);
      }
export function useIsLoggedInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(IsLoggedInDocument, options);
        }
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>;
export type IsLoggedInLazyQueryHookResult = ReturnType<typeof useIsLoggedInLazyQuery>;
export type IsLoggedInQueryResult = Apollo.QueryResult<IsLoggedInQuery, IsLoggedInQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    user {
      id
      ldapUsername
      email
      familyName
      givenName
    }
    roles
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
    mutation login($name: String!, $password: String!) {
  login(name: $name, password: $password) {
    token
    user {
      id
      ldapUsername
      email
      familyName
      givenName
    }
    roles
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      name: // value for 'name'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RenewUserSessionTokenDocument = gql`
    mutation renewUserSessionToken {
  renewUserSessionToken
}
    `;
export type RenewUserSessionTokenMutationFn = Apollo.MutationFunction<RenewUserSessionTokenMutation, RenewUserSessionTokenMutationVariables>;

/**
 * __useRenewUserSessionTokenMutation__
 *
 * To run a mutation, you first call `useRenewUserSessionTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewUserSessionTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewUserSessionTokenMutation, { data, loading, error }] = useRenewUserSessionTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRenewUserSessionTokenMutation(baseOptions?: Apollo.MutationHookOptions<RenewUserSessionTokenMutation, RenewUserSessionTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenewUserSessionTokenMutation, RenewUserSessionTokenMutationVariables>(RenewUserSessionTokenDocument, options);
      }
export type RenewUserSessionTokenMutationHookResult = ReturnType<typeof useRenewUserSessionTokenMutation>;
export type RenewUserSessionTokenMutationResult = Apollo.MutationResult<RenewUserSessionTokenMutation>;
export type RenewUserSessionTokenMutationOptions = Apollo.BaseMutationOptions<RenewUserSessionTokenMutation, RenewUserSessionTokenMutationVariables>;
export const HandleLoginDocument = gql`
    mutation handleLogin($loginResult: HandleLoginInput!) {
  handleLogin(loginResult: $loginResult) @client
}
    `;
export type HandleLoginMutationFn = Apollo.MutationFunction<HandleLoginMutation, HandleLoginMutationVariables>;

/**
 * __useHandleLoginMutation__
 *
 * To run a mutation, you first call `useHandleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleLoginMutation, { data, loading, error }] = useHandleLoginMutation({
 *   variables: {
 *      loginResult: // value for 'loginResult'
 *   },
 * });
 */
export function useHandleLoginMutation(baseOptions?: Apollo.MutationHookOptions<HandleLoginMutation, HandleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandleLoginMutation, HandleLoginMutationVariables>(HandleLoginDocument, options);
      }
export type HandleLoginMutationHookResult = ReturnType<typeof useHandleLoginMutation>;
export type HandleLoginMutationResult = Apollo.MutationResult<HandleLoginMutation>;
export type HandleLoginMutationOptions = Apollo.BaseMutationOptions<HandleLoginMutation, HandleLoginMutationVariables>;
export const HandleTokenRenewalDocument = gql`
    mutation handleTokenRenewal($token: String!) {
  handleTokenRenewal(token: $token) @client
}
    `;
export type HandleTokenRenewalMutationFn = Apollo.MutationFunction<HandleTokenRenewalMutation, HandleTokenRenewalMutationVariables>;

/**
 * __useHandleTokenRenewalMutation__
 *
 * To run a mutation, you first call `useHandleTokenRenewalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleTokenRenewalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleTokenRenewalMutation, { data, loading, error }] = useHandleTokenRenewalMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useHandleTokenRenewalMutation(baseOptions?: Apollo.MutationHookOptions<HandleTokenRenewalMutation, HandleTokenRenewalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandleTokenRenewalMutation, HandleTokenRenewalMutationVariables>(HandleTokenRenewalDocument, options);
      }
export type HandleTokenRenewalMutationHookResult = ReturnType<typeof useHandleTokenRenewalMutation>;
export type HandleTokenRenewalMutationResult = Apollo.MutationResult<HandleTokenRenewalMutation>;
export type HandleTokenRenewalMutationOptions = Apollo.BaseMutationOptions<HandleTokenRenewalMutation, HandleTokenRenewalMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout @client
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const AnimatorEventsDocument = gql`
    query animatorEvents {
  animatorEvent {
    id
    type
    title
    date
  }
}
    `;

/**
 * __useAnimatorEventsQuery__
 *
 * To run a query within a React component, call `useAnimatorEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnimatorEventsQuery(baseOptions?: Apollo.QueryHookOptions<AnimatorEventsQuery, AnimatorEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorEventsQuery, AnimatorEventsQueryVariables>(AnimatorEventsDocument, options);
      }
export function useAnimatorEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorEventsQuery, AnimatorEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorEventsQuery, AnimatorEventsQueryVariables>(AnimatorEventsDocument, options);
        }
export type AnimatorEventsQueryHookResult = ReturnType<typeof useAnimatorEventsQuery>;
export type AnimatorEventsLazyQueryHookResult = ReturnType<typeof useAnimatorEventsLazyQuery>;
export type AnimatorEventsQueryResult = Apollo.QueryResult<AnimatorEventsQuery, AnimatorEventsQueryVariables>;
export const AnimatorEventDetailsDocument = gql`
    query animatorEventDetails($id: Int!) {
  animatorEvent(id: $id) {
    type
    title
    date
  }
}
    `;

/**
 * __useAnimatorEventDetailsQuery__
 *
 * To run a query within a React component, call `useAnimatorEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorEventDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnimatorEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<AnimatorEventDetailsQuery, AnimatorEventDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorEventDetailsQuery, AnimatorEventDetailsQueryVariables>(AnimatorEventDetailsDocument, options);
      }
export function useAnimatorEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorEventDetailsQuery, AnimatorEventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorEventDetailsQuery, AnimatorEventDetailsQueryVariables>(AnimatorEventDetailsDocument, options);
        }
export type AnimatorEventDetailsQueryHookResult = ReturnType<typeof useAnimatorEventDetailsQuery>;
export type AnimatorEventDetailsLazyQueryHookResult = ReturnType<typeof useAnimatorEventDetailsLazyQuery>;
export type AnimatorEventDetailsQueryResult = Apollo.QueryResult<AnimatorEventDetailsQuery, AnimatorEventDetailsQueryVariables>;
export const StartAnimatorGatheringDocument = gql`
    mutation startAnimatorGathering($id: Int!) {
  startAnimatorGathering(id: $id)
}
    `;
export type StartAnimatorGatheringMutationFn = Apollo.MutationFunction<StartAnimatorGatheringMutation, StartAnimatorGatheringMutationVariables>;

/**
 * __useStartAnimatorGatheringMutation__
 *
 * To run a mutation, you first call `useStartAnimatorGatheringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAnimatorGatheringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAnimatorGatheringMutation, { data, loading, error }] = useStartAnimatorGatheringMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartAnimatorGatheringMutation(baseOptions?: Apollo.MutationHookOptions<StartAnimatorGatheringMutation, StartAnimatorGatheringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartAnimatorGatheringMutation, StartAnimatorGatheringMutationVariables>(StartAnimatorGatheringDocument, options);
      }
export type StartAnimatorGatheringMutationHookResult = ReturnType<typeof useStartAnimatorGatheringMutation>;
export type StartAnimatorGatheringMutationResult = Apollo.MutationResult<StartAnimatorGatheringMutation>;
export type StartAnimatorGatheringMutationOptions = Apollo.BaseMutationOptions<StartAnimatorGatheringMutation, StartAnimatorGatheringMutationVariables>;
export const AdvanceAnimatorGatheringStageDocument = gql`
    mutation advanceAnimatorGatheringStage($gatheringId: Int!) {
  advanceAnimatorGatheringStage(gatheringId: $gatheringId)
}
    `;
export type AdvanceAnimatorGatheringStageMutationFn = Apollo.MutationFunction<AdvanceAnimatorGatheringStageMutation, AdvanceAnimatorGatheringStageMutationVariables>;

/**
 * __useAdvanceAnimatorGatheringStageMutation__
 *
 * To run a mutation, you first call `useAdvanceAnimatorGatheringStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvanceAnimatorGatheringStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advanceAnimatorGatheringStageMutation, { data, loading, error }] = useAdvanceAnimatorGatheringStageMutation({
 *   variables: {
 *      gatheringId: // value for 'gatheringId'
 *   },
 * });
 */
export function useAdvanceAnimatorGatheringStageMutation(baseOptions?: Apollo.MutationHookOptions<AdvanceAnimatorGatheringStageMutation, AdvanceAnimatorGatheringStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdvanceAnimatorGatheringStageMutation, AdvanceAnimatorGatheringStageMutationVariables>(AdvanceAnimatorGatheringStageDocument, options);
      }
export type AdvanceAnimatorGatheringStageMutationHookResult = ReturnType<typeof useAdvanceAnimatorGatheringStageMutation>;
export type AdvanceAnimatorGatheringStageMutationResult = Apollo.MutationResult<AdvanceAnimatorGatheringStageMutation>;
export type AdvanceAnimatorGatheringStageMutationOptions = Apollo.BaseMutationOptions<AdvanceAnimatorGatheringStageMutation, AdvanceAnimatorGatheringStageMutationVariables>;
export const RecedeAnimatorGatheringStageDocument = gql`
    mutation recedeAnimatorGatheringStage($gatheringId: Int!) {
  recedeAnimatorGatheringStage(gatheringId: $gatheringId)
}
    `;
export type RecedeAnimatorGatheringStageMutationFn = Apollo.MutationFunction<RecedeAnimatorGatheringStageMutation, RecedeAnimatorGatheringStageMutationVariables>;

/**
 * __useRecedeAnimatorGatheringStageMutation__
 *
 * To run a mutation, you first call `useRecedeAnimatorGatheringStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecedeAnimatorGatheringStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recedeAnimatorGatheringStageMutation, { data, loading, error }] = useRecedeAnimatorGatheringStageMutation({
 *   variables: {
 *      gatheringId: // value for 'gatheringId'
 *   },
 * });
 */
export function useRecedeAnimatorGatheringStageMutation(baseOptions?: Apollo.MutationHookOptions<RecedeAnimatorGatheringStageMutation, RecedeAnimatorGatheringStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecedeAnimatorGatheringStageMutation, RecedeAnimatorGatheringStageMutationVariables>(RecedeAnimatorGatheringStageDocument, options);
      }
export type RecedeAnimatorGatheringStageMutationHookResult = ReturnType<typeof useRecedeAnimatorGatheringStageMutation>;
export type RecedeAnimatorGatheringStageMutationResult = Apollo.MutationResult<RecedeAnimatorGatheringStageMutation>;
export type RecedeAnimatorGatheringStageMutationOptions = Apollo.BaseMutationOptions<RecedeAnimatorGatheringStageMutation, RecedeAnimatorGatheringStageMutationVariables>;
export const CompleteAnimatorGatheringDocument = gql`
    mutation completeAnimatorGathering($id: Int!) {
  completeAnimatorGathering(id: $id)
}
    `;
export type CompleteAnimatorGatheringMutationFn = Apollo.MutationFunction<CompleteAnimatorGatheringMutation, CompleteAnimatorGatheringMutationVariables>;

/**
 * __useCompleteAnimatorGatheringMutation__
 *
 * To run a mutation, you first call `useCompleteAnimatorGatheringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAnimatorGatheringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAnimatorGatheringMutation, { data, loading, error }] = useCompleteAnimatorGatheringMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteAnimatorGatheringMutation(baseOptions?: Apollo.MutationHookOptions<CompleteAnimatorGatheringMutation, CompleteAnimatorGatheringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteAnimatorGatheringMutation, CompleteAnimatorGatheringMutationVariables>(CompleteAnimatorGatheringDocument, options);
      }
export type CompleteAnimatorGatheringMutationHookResult = ReturnType<typeof useCompleteAnimatorGatheringMutation>;
export type CompleteAnimatorGatheringMutationResult = Apollo.MutationResult<CompleteAnimatorGatheringMutation>;
export type CompleteAnimatorGatheringMutationOptions = Apollo.BaseMutationOptions<CompleteAnimatorGatheringMutation, CompleteAnimatorGatheringMutationVariables>;
export const CreateAnimatorGatheringPollDocument = gql`
    mutation createAnimatorGatheringPoll($afterStageId: Int, $agendaItemId: Int, $poll: AnimatorGatheringPollInput!) {
  createAnimatorGatheringPoll(
    afterStageId: $afterStageId
    agendaItemId: $agendaItemId
    poll: $poll
  )
}
    `;
export type CreateAnimatorGatheringPollMutationFn = Apollo.MutationFunction<CreateAnimatorGatheringPollMutation, CreateAnimatorGatheringPollMutationVariables>;

/**
 * __useCreateAnimatorGatheringPollMutation__
 *
 * To run a mutation, you first call `useCreateAnimatorGatheringPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnimatorGatheringPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnimatorGatheringPollMutation, { data, loading, error }] = useCreateAnimatorGatheringPollMutation({
 *   variables: {
 *      afterStageId: // value for 'afterStageId'
 *      agendaItemId: // value for 'agendaItemId'
 *      poll: // value for 'poll'
 *   },
 * });
 */
export function useCreateAnimatorGatheringPollMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnimatorGatheringPollMutation, CreateAnimatorGatheringPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnimatorGatheringPollMutation, CreateAnimatorGatheringPollMutationVariables>(CreateAnimatorGatheringPollDocument, options);
      }
export type CreateAnimatorGatheringPollMutationHookResult = ReturnType<typeof useCreateAnimatorGatheringPollMutation>;
export type CreateAnimatorGatheringPollMutationResult = Apollo.MutationResult<CreateAnimatorGatheringPollMutation>;
export type CreateAnimatorGatheringPollMutationOptions = Apollo.BaseMutationOptions<CreateAnimatorGatheringPollMutation, CreateAnimatorGatheringPollMutationVariables>;
export const JoinAnimatorGatheringInfoDocument = gql`
    query joinAnimatorGatheringInfo {
  animatorSelf {
    id
    status
  }
}
    `;

/**
 * __useJoinAnimatorGatheringInfoQuery__
 *
 * To run a query within a React component, call `useJoinAnimatorGatheringInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinAnimatorGatheringInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinAnimatorGatheringInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoinAnimatorGatheringInfoQuery(baseOptions?: Apollo.QueryHookOptions<JoinAnimatorGatheringInfoQuery, JoinAnimatorGatheringInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JoinAnimatorGatheringInfoQuery, JoinAnimatorGatheringInfoQueryVariables>(JoinAnimatorGatheringInfoDocument, options);
      }
export function useJoinAnimatorGatheringInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JoinAnimatorGatheringInfoQuery, JoinAnimatorGatheringInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JoinAnimatorGatheringInfoQuery, JoinAnimatorGatheringInfoQueryVariables>(JoinAnimatorGatheringInfoDocument, options);
        }
export type JoinAnimatorGatheringInfoQueryHookResult = ReturnType<typeof useJoinAnimatorGatheringInfoQuery>;
export type JoinAnimatorGatheringInfoLazyQueryHookResult = ReturnType<typeof useJoinAnimatorGatheringInfoLazyQuery>;
export type JoinAnimatorGatheringInfoQueryResult = Apollo.QueryResult<JoinAnimatorGatheringInfoQuery, JoinAnimatorGatheringInfoQueryVariables>;
export const JoinAnimatorGatheringManuallyDocument = gql`
    mutation joinAnimatorGatheringManually($gatheringId: Int!) {
  joinAnimatorGatheringManually(gatheringId: $gatheringId)
}
    `;
export type JoinAnimatorGatheringManuallyMutationFn = Apollo.MutationFunction<JoinAnimatorGatheringManuallyMutation, JoinAnimatorGatheringManuallyMutationVariables>;

/**
 * __useJoinAnimatorGatheringManuallyMutation__
 *
 * To run a mutation, you first call `useJoinAnimatorGatheringManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinAnimatorGatheringManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinAnimatorGatheringManuallyMutation, { data, loading, error }] = useJoinAnimatorGatheringManuallyMutation({
 *   variables: {
 *      gatheringId: // value for 'gatheringId'
 *   },
 * });
 */
export function useJoinAnimatorGatheringManuallyMutation(baseOptions?: Apollo.MutationHookOptions<JoinAnimatorGatheringManuallyMutation, JoinAnimatorGatheringManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinAnimatorGatheringManuallyMutation, JoinAnimatorGatheringManuallyMutationVariables>(JoinAnimatorGatheringManuallyDocument, options);
      }
export type JoinAnimatorGatheringManuallyMutationHookResult = ReturnType<typeof useJoinAnimatorGatheringManuallyMutation>;
export type JoinAnimatorGatheringManuallyMutationResult = Apollo.MutationResult<JoinAnimatorGatheringManuallyMutation>;
export type JoinAnimatorGatheringManuallyMutationOptions = Apollo.BaseMutationOptions<JoinAnimatorGatheringManuallyMutation, JoinAnimatorGatheringManuallyMutationVariables>;
export const AnimatorGatheringPresenceAddParticipantsListDocument = gql`
    query animatorGatheringPresenceAddParticipantsList($gatheringId: Int!) {
  animatorGatheringPresenceAddParticipantsList(gatheringId: $gatheringId) {
    id
    status
    user {
      id
      ldapUsername
      familyName
      givenName
    }
  }
}
    `;

/**
 * __useAnimatorGatheringPresenceAddParticipantsListQuery__
 *
 * To run a query within a React component, call `useAnimatorGatheringPresenceAddParticipantsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringPresenceAddParticipantsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringPresenceAddParticipantsListQuery({
 *   variables: {
 *      gatheringId: // value for 'gatheringId'
 *   },
 * });
 */
export function useAnimatorGatheringPresenceAddParticipantsListQuery(baseOptions: Apollo.QueryHookOptions<AnimatorGatheringPresenceAddParticipantsListQuery, AnimatorGatheringPresenceAddParticipantsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorGatheringPresenceAddParticipantsListQuery, AnimatorGatheringPresenceAddParticipantsListQueryVariables>(AnimatorGatheringPresenceAddParticipantsListDocument, options);
      }
export function useAnimatorGatheringPresenceAddParticipantsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorGatheringPresenceAddParticipantsListQuery, AnimatorGatheringPresenceAddParticipantsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorGatheringPresenceAddParticipantsListQuery, AnimatorGatheringPresenceAddParticipantsListQueryVariables>(AnimatorGatheringPresenceAddParticipantsListDocument, options);
        }
export type AnimatorGatheringPresenceAddParticipantsListQueryHookResult = ReturnType<typeof useAnimatorGatheringPresenceAddParticipantsListQuery>;
export type AnimatorGatheringPresenceAddParticipantsListLazyQueryHookResult = ReturnType<typeof useAnimatorGatheringPresenceAddParticipantsListLazyQuery>;
export type AnimatorGatheringPresenceAddParticipantsListQueryResult = Apollo.QueryResult<AnimatorGatheringPresenceAddParticipantsListQuery, AnimatorGatheringPresenceAddParticipantsListQueryVariables>;
export const AnimatorGatheringPresenceAddParticipantDocument = gql`
    mutation animatorGatheringPresenceAddParticipant($id: Int!, $animatorId: Int!) {
  animatorGatheringPresenceAddParticipant(id: $id, animatorId: $animatorId)
}
    `;
export type AnimatorGatheringPresenceAddParticipantMutationFn = Apollo.MutationFunction<AnimatorGatheringPresenceAddParticipantMutation, AnimatorGatheringPresenceAddParticipantMutationVariables>;

/**
 * __useAnimatorGatheringPresenceAddParticipantMutation__
 *
 * To run a mutation, you first call `useAnimatorGatheringPresenceAddParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringPresenceAddParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [animatorGatheringPresenceAddParticipantMutation, { data, loading, error }] = useAnimatorGatheringPresenceAddParticipantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      animatorId: // value for 'animatorId'
 *   },
 * });
 */
export function useAnimatorGatheringPresenceAddParticipantMutation(baseOptions?: Apollo.MutationHookOptions<AnimatorGatheringPresenceAddParticipantMutation, AnimatorGatheringPresenceAddParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnimatorGatheringPresenceAddParticipantMutation, AnimatorGatheringPresenceAddParticipantMutationVariables>(AnimatorGatheringPresenceAddParticipantDocument, options);
      }
export type AnimatorGatheringPresenceAddParticipantMutationHookResult = ReturnType<typeof useAnimatorGatheringPresenceAddParticipantMutation>;
export type AnimatorGatheringPresenceAddParticipantMutationResult = Apollo.MutationResult<AnimatorGatheringPresenceAddParticipantMutation>;
export type AnimatorGatheringPresenceAddParticipantMutationOptions = Apollo.BaseMutationOptions<AnimatorGatheringPresenceAddParticipantMutation, AnimatorGatheringPresenceAddParticipantMutationVariables>;
export const AnimatorGatheringMemberListStatusListDocument = gql`
    query animatorGatheringMemberListStatusList {
  animatorStatus {
    id
    name
  }
}
    `;

/**
 * __useAnimatorGatheringMemberListStatusListQuery__
 *
 * To run a query within a React component, call `useAnimatorGatheringMemberListStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringMemberListStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringMemberListStatusListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnimatorGatheringMemberListStatusListQuery(baseOptions?: Apollo.QueryHookOptions<AnimatorGatheringMemberListStatusListQuery, AnimatorGatheringMemberListStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorGatheringMemberListStatusListQuery, AnimatorGatheringMemberListStatusListQueryVariables>(AnimatorGatheringMemberListStatusListDocument, options);
      }
export function useAnimatorGatheringMemberListStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorGatheringMemberListStatusListQuery, AnimatorGatheringMemberListStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorGatheringMemberListStatusListQuery, AnimatorGatheringMemberListStatusListQueryVariables>(AnimatorGatheringMemberListStatusListDocument, options);
        }
export type AnimatorGatheringMemberListStatusListQueryHookResult = ReturnType<typeof useAnimatorGatheringMemberListStatusListQuery>;
export type AnimatorGatheringMemberListStatusListLazyQueryHookResult = ReturnType<typeof useAnimatorGatheringMemberListStatusListLazyQuery>;
export type AnimatorGatheringMemberListStatusListQueryResult = Apollo.QueryResult<AnimatorGatheringMemberListStatusListQuery, AnimatorGatheringMemberListStatusListQueryVariables>;
export const AnimatorGatheringPresenceDocument = gql`
    query animatorGatheringPresence($id: Int!) {
  animatorGatheringPresence(id: $id) {
    gatheringEligibleToStart
    activeMemberCount
    activeMemberPresenceCount
    validVoterCount
    presence {
      id
      status
      user {
        id
        ldapUsername
        familyName
        givenName
      }
    }
  }
}
    `;

/**
 * __useAnimatorGatheringPresenceQuery__
 *
 * To run a query within a React component, call `useAnimatorGatheringPresenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringPresenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringPresenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnimatorGatheringPresenceQuery(baseOptions: Apollo.QueryHookOptions<AnimatorGatheringPresenceQuery, AnimatorGatheringPresenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorGatheringPresenceQuery, AnimatorGatheringPresenceQueryVariables>(AnimatorGatheringPresenceDocument, options);
      }
export function useAnimatorGatheringPresenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorGatheringPresenceQuery, AnimatorGatheringPresenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorGatheringPresenceQuery, AnimatorGatheringPresenceQueryVariables>(AnimatorGatheringPresenceDocument, options);
        }
export type AnimatorGatheringPresenceQueryHookResult = ReturnType<typeof useAnimatorGatheringPresenceQuery>;
export type AnimatorGatheringPresenceLazyQueryHookResult = ReturnType<typeof useAnimatorGatheringPresenceLazyQuery>;
export type AnimatorGatheringPresenceQueryResult = Apollo.QueryResult<AnimatorGatheringPresenceQuery, AnimatorGatheringPresenceQueryVariables>;
export const AnimatorGatheringParticipantJoinedDocument = gql`
    subscription animatorGatheringParticipantJoined($id: Int!) {
  animatorGatheringParticipantJoined(id: $id) {
    id
    status
    user {
      id
      ldapUsername
      familyName
      givenName
    }
  }
}
    `;

/**
 * __useAnimatorGatheringParticipantJoinedSubscription__
 *
 * To run a query within a React component, call `useAnimatorGatheringParticipantJoinedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringParticipantJoinedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringParticipantJoinedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnimatorGatheringParticipantJoinedSubscription(baseOptions: Apollo.SubscriptionHookOptions<AnimatorGatheringParticipantJoinedSubscription, AnimatorGatheringParticipantJoinedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AnimatorGatheringParticipantJoinedSubscription, AnimatorGatheringParticipantJoinedSubscriptionVariables>(AnimatorGatheringParticipantJoinedDocument, options);
      }
export type AnimatorGatheringParticipantJoinedSubscriptionHookResult = ReturnType<typeof useAnimatorGatheringParticipantJoinedSubscription>;
export type AnimatorGatheringParticipantJoinedSubscriptionResult = Apollo.SubscriptionResult<AnimatorGatheringParticipantJoinedSubscription>;
export const AnimatorGatheringUpdatedDocument = gql`
    subscription animatorGatheringUpdated($id: Int!) {
  animatorGatheringUpdated(id: $id) {
    id
    completed
    started
    participantCount
    amITheChairman
    event {
      title
    }
    stages {
      id
      title
      completed
      order
      ... on AnimatorGatheringTextStage {
        content
      }
      ... on AnimatorGatheringAgendaStage {
        agendaItems {
          id
          title
          content
          completed
          order
          polls {
            id
            opened
            closed
            final
          }
        }
      }
      ... on AnimatorGatheringPollStage {
        poll {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAnimatorGatheringUpdatedSubscription__
 *
 * To run a query within a React component, call `useAnimatorGatheringUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnimatorGatheringUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<AnimatorGatheringUpdatedSubscription, AnimatorGatheringUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AnimatorGatheringUpdatedSubscription, AnimatorGatheringUpdatedSubscriptionVariables>(AnimatorGatheringUpdatedDocument, options);
      }
export type AnimatorGatheringUpdatedSubscriptionHookResult = ReturnType<typeof useAnimatorGatheringUpdatedSubscription>;
export type AnimatorGatheringUpdatedSubscriptionResult = Apollo.SubscriptionResult<AnimatorGatheringUpdatedSubscription>;
export const OpenAnimatorGatheringPollDocument = gql`
    mutation openAnimatorGatheringPoll($id: Int!) {
  openAnimatorGatheringPoll(id: $id)
}
    `;
export type OpenAnimatorGatheringPollMutationFn = Apollo.MutationFunction<OpenAnimatorGatheringPollMutation, OpenAnimatorGatheringPollMutationVariables>;

/**
 * __useOpenAnimatorGatheringPollMutation__
 *
 * To run a mutation, you first call `useOpenAnimatorGatheringPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenAnimatorGatheringPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openAnimatorGatheringPollMutation, { data, loading, error }] = useOpenAnimatorGatheringPollMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpenAnimatorGatheringPollMutation(baseOptions?: Apollo.MutationHookOptions<OpenAnimatorGatheringPollMutation, OpenAnimatorGatheringPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpenAnimatorGatheringPollMutation, OpenAnimatorGatheringPollMutationVariables>(OpenAnimatorGatheringPollDocument, options);
      }
export type OpenAnimatorGatheringPollMutationHookResult = ReturnType<typeof useOpenAnimatorGatheringPollMutation>;
export type OpenAnimatorGatheringPollMutationResult = Apollo.MutationResult<OpenAnimatorGatheringPollMutation>;
export type OpenAnimatorGatheringPollMutationOptions = Apollo.BaseMutationOptions<OpenAnimatorGatheringPollMutation, OpenAnimatorGatheringPollMutationVariables>;
export const CloseAnimatorGatheringPollDocument = gql`
    mutation closeAnimatorGatheringPoll($id: Int!) {
  closeAnimatorGatheringPoll(id: $id)
}
    `;
export type CloseAnimatorGatheringPollMutationFn = Apollo.MutationFunction<CloseAnimatorGatheringPollMutation, CloseAnimatorGatheringPollMutationVariables>;

/**
 * __useCloseAnimatorGatheringPollMutation__
 *
 * To run a mutation, you first call `useCloseAnimatorGatheringPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAnimatorGatheringPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAnimatorGatheringPollMutation, { data, loading, error }] = useCloseAnimatorGatheringPollMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseAnimatorGatheringPollMutation(baseOptions?: Apollo.MutationHookOptions<CloseAnimatorGatheringPollMutation, CloseAnimatorGatheringPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseAnimatorGatheringPollMutation, CloseAnimatorGatheringPollMutationVariables>(CloseAnimatorGatheringPollDocument, options);
      }
export type CloseAnimatorGatheringPollMutationHookResult = ReturnType<typeof useCloseAnimatorGatheringPollMutation>;
export type CloseAnimatorGatheringPollMutationResult = Apollo.MutationResult<CloseAnimatorGatheringPollMutation>;
export type CloseAnimatorGatheringPollMutationOptions = Apollo.BaseMutationOptions<CloseAnimatorGatheringPollMutation, CloseAnimatorGatheringPollMutationVariables>;
export const FinalizeAnimatorGatheringPollDocument = gql`
    mutation finalizeAnimatorGatheringPoll($id: Int!, $valid: Boolean!) {
  finalizeAnimatorGatheringPoll(id: $id, valid: $valid)
}
    `;
export type FinalizeAnimatorGatheringPollMutationFn = Apollo.MutationFunction<FinalizeAnimatorGatheringPollMutation, FinalizeAnimatorGatheringPollMutationVariables>;

/**
 * __useFinalizeAnimatorGatheringPollMutation__
 *
 * To run a mutation, you first call `useFinalizeAnimatorGatheringPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeAnimatorGatheringPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeAnimatorGatheringPollMutation, { data, loading, error }] = useFinalizeAnimatorGatheringPollMutation({
 *   variables: {
 *      id: // value for 'id'
 *      valid: // value for 'valid'
 *   },
 * });
 */
export function useFinalizeAnimatorGatheringPollMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeAnimatorGatheringPollMutation, FinalizeAnimatorGatheringPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeAnimatorGatheringPollMutation, FinalizeAnimatorGatheringPollMutationVariables>(FinalizeAnimatorGatheringPollDocument, options);
      }
export type FinalizeAnimatorGatheringPollMutationHookResult = ReturnType<typeof useFinalizeAnimatorGatheringPollMutation>;
export type FinalizeAnimatorGatheringPollMutationResult = Apollo.MutationResult<FinalizeAnimatorGatheringPollMutation>;
export type FinalizeAnimatorGatheringPollMutationOptions = Apollo.BaseMutationOptions<FinalizeAnimatorGatheringPollMutation, FinalizeAnimatorGatheringPollMutationVariables>;
export const CreateAnimatorGatheringPollOptionDocument = gql`
    mutation createAnimatorGatheringPollOption($pollId: Int!, $title: String!, $abstention: Boolean) {
  createAnimatorGatheringPollOption(
    pollId: $pollId
    title: $title
    abstention: $abstention
  ) {
    id
  }
}
    `;
export type CreateAnimatorGatheringPollOptionMutationFn = Apollo.MutationFunction<CreateAnimatorGatheringPollOptionMutation, CreateAnimatorGatheringPollOptionMutationVariables>;

/**
 * __useCreateAnimatorGatheringPollOptionMutation__
 *
 * To run a mutation, you first call `useCreateAnimatorGatheringPollOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnimatorGatheringPollOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnimatorGatheringPollOptionMutation, { data, loading, error }] = useCreateAnimatorGatheringPollOptionMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      title: // value for 'title'
 *      abstention: // value for 'abstention'
 *   },
 * });
 */
export function useCreateAnimatorGatheringPollOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnimatorGatheringPollOptionMutation, CreateAnimatorGatheringPollOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnimatorGatheringPollOptionMutation, CreateAnimatorGatheringPollOptionMutationVariables>(CreateAnimatorGatheringPollOptionDocument, options);
      }
export type CreateAnimatorGatheringPollOptionMutationHookResult = ReturnType<typeof useCreateAnimatorGatheringPollOptionMutation>;
export type CreateAnimatorGatheringPollOptionMutationResult = Apollo.MutationResult<CreateAnimatorGatheringPollOptionMutation>;
export type CreateAnimatorGatheringPollOptionMutationOptions = Apollo.BaseMutationOptions<CreateAnimatorGatheringPollOptionMutation, CreateAnimatorGatheringPollOptionMutationVariables>;
export const DeleteAnimatorGatheringPollOptionDocument = gql`
    mutation deleteAnimatorGatheringPollOption($id: Int!) {
  deleteAnimatorGatheringPollOption(id: $id)
}
    `;
export type DeleteAnimatorGatheringPollOptionMutationFn = Apollo.MutationFunction<DeleteAnimatorGatheringPollOptionMutation, DeleteAnimatorGatheringPollOptionMutationVariables>;

/**
 * __useDeleteAnimatorGatheringPollOptionMutation__
 *
 * To run a mutation, you first call `useDeleteAnimatorGatheringPollOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnimatorGatheringPollOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnimatorGatheringPollOptionMutation, { data, loading, error }] = useDeleteAnimatorGatheringPollOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnimatorGatheringPollOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnimatorGatheringPollOptionMutation, DeleteAnimatorGatheringPollOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnimatorGatheringPollOptionMutation, DeleteAnimatorGatheringPollOptionMutationVariables>(DeleteAnimatorGatheringPollOptionDocument, options);
      }
export type DeleteAnimatorGatheringPollOptionMutationHookResult = ReturnType<typeof useDeleteAnimatorGatheringPollOptionMutation>;
export type DeleteAnimatorGatheringPollOptionMutationResult = Apollo.MutationResult<DeleteAnimatorGatheringPollOptionMutation>;
export type DeleteAnimatorGatheringPollOptionMutationOptions = Apollo.BaseMutationOptions<DeleteAnimatorGatheringPollOptionMutation, DeleteAnimatorGatheringPollOptionMutationVariables>;
export const ReorderAnimatorGatheringPollOptionsDocument = gql`
    mutation reorderAnimatorGatheringPollOptions($id: Int!, $beforeId: Int, $afterId: Int) {
  reorderAnimatorGatheringPollOptions(
    id: $id
    beforeId: $beforeId
    afterId: $afterId
  )
}
    `;
export type ReorderAnimatorGatheringPollOptionsMutationFn = Apollo.MutationFunction<ReorderAnimatorGatheringPollOptionsMutation, ReorderAnimatorGatheringPollOptionsMutationVariables>;

/**
 * __useReorderAnimatorGatheringPollOptionsMutation__
 *
 * To run a mutation, you first call `useReorderAnimatorGatheringPollOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderAnimatorGatheringPollOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderAnimatorGatheringPollOptionsMutation, { data, loading, error }] = useReorderAnimatorGatheringPollOptionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      beforeId: // value for 'beforeId'
 *      afterId: // value for 'afterId'
 *   },
 * });
 */
export function useReorderAnimatorGatheringPollOptionsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderAnimatorGatheringPollOptionsMutation, ReorderAnimatorGatheringPollOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderAnimatorGatheringPollOptionsMutation, ReorderAnimatorGatheringPollOptionsMutationVariables>(ReorderAnimatorGatheringPollOptionsDocument, options);
      }
export type ReorderAnimatorGatheringPollOptionsMutationHookResult = ReturnType<typeof useReorderAnimatorGatheringPollOptionsMutation>;
export type ReorderAnimatorGatheringPollOptionsMutationResult = Apollo.MutationResult<ReorderAnimatorGatheringPollOptionsMutation>;
export type ReorderAnimatorGatheringPollOptionsMutationOptions = Apollo.BaseMutationOptions<ReorderAnimatorGatheringPollOptionsMutation, ReorderAnimatorGatheringPollOptionsMutationVariables>;
export const CreateAnimatorGatheringPollRevoteDocument = gql`
    mutation createAnimatorGatheringPollRevote($fromId: Int!) {
  createAnimatorGatheringPollRevote(fromId: $fromId)
}
    `;
export type CreateAnimatorGatheringPollRevoteMutationFn = Apollo.MutationFunction<CreateAnimatorGatheringPollRevoteMutation, CreateAnimatorGatheringPollRevoteMutationVariables>;

/**
 * __useCreateAnimatorGatheringPollRevoteMutation__
 *
 * To run a mutation, you first call `useCreateAnimatorGatheringPollRevoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnimatorGatheringPollRevoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnimatorGatheringPollRevoteMutation, { data, loading, error }] = useCreateAnimatorGatheringPollRevoteMutation({
 *   variables: {
 *      fromId: // value for 'fromId'
 *   },
 * });
 */
export function useCreateAnimatorGatheringPollRevoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnimatorGatheringPollRevoteMutation, CreateAnimatorGatheringPollRevoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnimatorGatheringPollRevoteMutation, CreateAnimatorGatheringPollRevoteMutationVariables>(CreateAnimatorGatheringPollRevoteDocument, options);
      }
export type CreateAnimatorGatheringPollRevoteMutationHookResult = ReturnType<typeof useCreateAnimatorGatheringPollRevoteMutation>;
export type CreateAnimatorGatheringPollRevoteMutationResult = Apollo.MutationResult<CreateAnimatorGatheringPollRevoteMutation>;
export type CreateAnimatorGatheringPollRevoteMutationOptions = Apollo.BaseMutationOptions<CreateAnimatorGatheringPollRevoteMutation, CreateAnimatorGatheringPollRevoteMutationVariables>;
export const RenameAnimatorGatheringPollDocument = gql`
    mutation renameAnimatorGatheringPoll($id: Int!, $title: String!) {
  renameAnimatorGatheringPoll(id: $id, title: $title)
}
    `;
export type RenameAnimatorGatheringPollMutationFn = Apollo.MutationFunction<RenameAnimatorGatheringPollMutation, RenameAnimatorGatheringPollMutationVariables>;

/**
 * __useRenameAnimatorGatheringPollMutation__
 *
 * To run a mutation, you first call `useRenameAnimatorGatheringPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAnimatorGatheringPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAnimatorGatheringPollMutation, { data, loading, error }] = useRenameAnimatorGatheringPollMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useRenameAnimatorGatheringPollMutation(baseOptions?: Apollo.MutationHookOptions<RenameAnimatorGatheringPollMutation, RenameAnimatorGatheringPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameAnimatorGatheringPollMutation, RenameAnimatorGatheringPollMutationVariables>(RenameAnimatorGatheringPollDocument, options);
      }
export type RenameAnimatorGatheringPollMutationHookResult = ReturnType<typeof useRenameAnimatorGatheringPollMutation>;
export type RenameAnimatorGatheringPollMutationResult = Apollo.MutationResult<RenameAnimatorGatheringPollMutation>;
export type RenameAnimatorGatheringPollMutationOptions = Apollo.BaseMutationOptions<RenameAnimatorGatheringPollMutation, RenameAnimatorGatheringPollMutationVariables>;
export const AnimatorGatheringPollDetailsDocument = gql`
    query animatorGatheringPollDetails($id: Int!) {
  animatorGatheringPoll(id: $id) {
    id
    title
    round
    description
    opened
    closed
    valid
    final
    options {
      id
      title
      order
      voteCount
      abstention
    }
    totalVoteCount
    ownVote {
      id
    }
    winningOption {
      id
    }
    predecessor {
      id
    }
    successor {
      id
    }
  }
  animatorSelf {
    id
    status
  }
}
    `;

/**
 * __useAnimatorGatheringPollDetailsQuery__
 *
 * To run a query within a React component, call `useAnimatorGatheringPollDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringPollDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringPollDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnimatorGatheringPollDetailsQuery(baseOptions: Apollo.QueryHookOptions<AnimatorGatheringPollDetailsQuery, AnimatorGatheringPollDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorGatheringPollDetailsQuery, AnimatorGatheringPollDetailsQueryVariables>(AnimatorGatheringPollDetailsDocument, options);
      }
export function useAnimatorGatheringPollDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorGatheringPollDetailsQuery, AnimatorGatheringPollDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorGatheringPollDetailsQuery, AnimatorGatheringPollDetailsQueryVariables>(AnimatorGatheringPollDetailsDocument, options);
        }
export type AnimatorGatheringPollDetailsQueryHookResult = ReturnType<typeof useAnimatorGatheringPollDetailsQuery>;
export type AnimatorGatheringPollDetailsLazyQueryHookResult = ReturnType<typeof useAnimatorGatheringPollDetailsLazyQuery>;
export type AnimatorGatheringPollDetailsQueryResult = Apollo.QueryResult<AnimatorGatheringPollDetailsQuery, AnimatorGatheringPollDetailsQueryVariables>;
export const VoteOnAnimatorGatheringPollDocument = gql`
    mutation voteOnAnimatorGatheringPoll($optionId: Int!) {
  voteOnAnimatorGatheringPoll(optionId: $optionId)
}
    `;
export type VoteOnAnimatorGatheringPollMutationFn = Apollo.MutationFunction<VoteOnAnimatorGatheringPollMutation, VoteOnAnimatorGatheringPollMutationVariables>;

/**
 * __useVoteOnAnimatorGatheringPollMutation__
 *
 * To run a mutation, you first call `useVoteOnAnimatorGatheringPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteOnAnimatorGatheringPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteOnAnimatorGatheringPollMutation, { data, loading, error }] = useVoteOnAnimatorGatheringPollMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useVoteOnAnimatorGatheringPollMutation(baseOptions?: Apollo.MutationHookOptions<VoteOnAnimatorGatheringPollMutation, VoteOnAnimatorGatheringPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteOnAnimatorGatheringPollMutation, VoteOnAnimatorGatheringPollMutationVariables>(VoteOnAnimatorGatheringPollDocument, options);
      }
export type VoteOnAnimatorGatheringPollMutationHookResult = ReturnType<typeof useVoteOnAnimatorGatheringPollMutation>;
export type VoteOnAnimatorGatheringPollMutationResult = Apollo.MutationResult<VoteOnAnimatorGatheringPollMutation>;
export type VoteOnAnimatorGatheringPollMutationOptions = Apollo.BaseMutationOptions<VoteOnAnimatorGatheringPollMutation, VoteOnAnimatorGatheringPollMutationVariables>;
export const AnimatorGatheringPollUpdatedDocument = gql`
    subscription animatorGatheringPollUpdated($id: Int!) {
  animatorGatheringPollUpdated(id: $id) {
    id
    title
    round
    description
    opened
    closed
    valid
    final
    options {
      id
      title
      order
      voteCount
      abstention
    }
    totalVoteCount
    ownVote {
      id
    }
    winningOption {
      id
    }
    predecessor {
      id
    }
    successor {
      id
    }
  }
}
    `;

/**
 * __useAnimatorGatheringPollUpdatedSubscription__
 *
 * To run a query within a React component, call `useAnimatorGatheringPollUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringPollUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringPollUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnimatorGatheringPollUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<AnimatorGatheringPollUpdatedSubscription, AnimatorGatheringPollUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AnimatorGatheringPollUpdatedSubscription, AnimatorGatheringPollUpdatedSubscriptionVariables>(AnimatorGatheringPollUpdatedDocument, options);
      }
export type AnimatorGatheringPollUpdatedSubscriptionHookResult = ReturnType<typeof useAnimatorGatheringPollUpdatedSubscription>;
export type AnimatorGatheringPollUpdatedSubscriptionResult = Apollo.SubscriptionResult<AnimatorGatheringPollUpdatedSubscription>;
export const AnimatorGatheringHistoryDocument = gql`
    query animatorGatheringHistory($title: String) {
  animatorGathering(title: $title, completed: true) {
    id
    event {
      title
      date
    }
    location
    chairman {
      id
      user {
        id
        ldapUsername
        givenName
        familyName
      }
    }
    participantCount
    stages {
      id
    }
  }
}
    `;

/**
 * __useAnimatorGatheringHistoryQuery__
 *
 * To run a query within a React component, call `useAnimatorGatheringHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringHistoryQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAnimatorGatheringHistoryQuery(baseOptions?: Apollo.QueryHookOptions<AnimatorGatheringHistoryQuery, AnimatorGatheringHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorGatheringHistoryQuery, AnimatorGatheringHistoryQueryVariables>(AnimatorGatheringHistoryDocument, options);
      }
export function useAnimatorGatheringHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorGatheringHistoryQuery, AnimatorGatheringHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorGatheringHistoryQuery, AnimatorGatheringHistoryQueryVariables>(AnimatorGatheringHistoryDocument, options);
        }
export type AnimatorGatheringHistoryQueryHookResult = ReturnType<typeof useAnimatorGatheringHistoryQuery>;
export type AnimatorGatheringHistoryLazyQueryHookResult = ReturnType<typeof useAnimatorGatheringHistoryLazyQuery>;
export type AnimatorGatheringHistoryQueryResult = Apollo.QueryResult<AnimatorGatheringHistoryQuery, AnimatorGatheringHistoryQueryVariables>;
export const AnimatorGatheringBannerDocument = gql`
    query animatorGatheringBanner {
  animatorActiveGathering {
    id
    event {
      title
    }
    location
    participantCount
  }
  animatorUpcomingGathering {
    event {
      title
    }
    location
    scheduledStartTime
  }
}
    `;

/**
 * __useAnimatorGatheringBannerQuery__
 *
 * To run a query within a React component, call `useAnimatorGatheringBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnimatorGatheringBannerQuery(baseOptions?: Apollo.QueryHookOptions<AnimatorGatheringBannerQuery, AnimatorGatheringBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorGatheringBannerQuery, AnimatorGatheringBannerQueryVariables>(AnimatorGatheringBannerDocument, options);
      }
export function useAnimatorGatheringBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorGatheringBannerQuery, AnimatorGatheringBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorGatheringBannerQuery, AnimatorGatheringBannerQueryVariables>(AnimatorGatheringBannerDocument, options);
        }
export type AnimatorGatheringBannerQueryHookResult = ReturnType<typeof useAnimatorGatheringBannerQuery>;
export type AnimatorGatheringBannerLazyQueryHookResult = ReturnType<typeof useAnimatorGatheringBannerLazyQuery>;
export type AnimatorGatheringBannerQueryResult = Apollo.QueryResult<AnimatorGatheringBannerQuery, AnimatorGatheringBannerQueryVariables>;
export const AnimatorGatheringDetailsDocument = gql`
    query animatorGatheringDetails($id: Int!) {
  animatorGathering(id: $id) {
    id
    completed
    started
    participantCount
    amITheChairman
    event {
      title
    }
    stages {
      id
      title
      completed
      order
      ... on AnimatorGatheringTextStage {
        content
      }
      ... on AnimatorGatheringAgendaStage {
        agendaItems {
          id
          title
          content
          completed
          order
          polls {
            id
            opened
            closed
            final
            order
          }
        }
      }
      ... on AnimatorGatheringPollStage {
        title
        poll {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAnimatorGatheringDetailsQuery__
 *
 * To run a query within a React component, call `useAnimatorGatheringDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorGatheringDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorGatheringDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnimatorGatheringDetailsQuery(baseOptions: Apollo.QueryHookOptions<AnimatorGatheringDetailsQuery, AnimatorGatheringDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorGatheringDetailsQuery, AnimatorGatheringDetailsQueryVariables>(AnimatorGatheringDetailsDocument, options);
      }
export function useAnimatorGatheringDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorGatheringDetailsQuery, AnimatorGatheringDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorGatheringDetailsQuery, AnimatorGatheringDetailsQueryVariables>(AnimatorGatheringDetailsDocument, options);
        }
export type AnimatorGatheringDetailsQueryHookResult = ReturnType<typeof useAnimatorGatheringDetailsQuery>;
export type AnimatorGatheringDetailsLazyQueryHookResult = ReturnType<typeof useAnimatorGatheringDetailsLazyQuery>;
export type AnimatorGatheringDetailsQueryResult = Apollo.QueryResult<AnimatorGatheringDetailsQuery, AnimatorGatheringDetailsQueryVariables>;
export const AnimatorSelfDocument = gql`
    query animatorSelf {
  animatorSelf {
    id
  }
}
    `;

/**
 * __useAnimatorSelfQuery__
 *
 * To run a query within a React component, call `useAnimatorSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnimatorSelfQuery(baseOptions?: Apollo.QueryHookOptions<AnimatorSelfQuery, AnimatorSelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorSelfQuery, AnimatorSelfQueryVariables>(AnimatorSelfDocument, options);
      }
export function useAnimatorSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorSelfQuery, AnimatorSelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorSelfQuery, AnimatorSelfQueryVariables>(AnimatorSelfDocument, options);
        }
export type AnimatorSelfQueryHookResult = ReturnType<typeof useAnimatorSelfQuery>;
export type AnimatorSelfLazyQueryHookResult = ReturnType<typeof useAnimatorSelfLazyQuery>;
export type AnimatorSelfQueryResult = Apollo.QueryResult<AnimatorSelfQuery, AnimatorSelfQueryVariables>;
export const CreateAnimatorGatheringDocument = gql`
    mutation createAnimatorGathering($creator: Int!, $gathering: AnimatorGatheringInput!) {
  createAnimatorGathering(creator: $creator, gathering: $gathering)
}
    `;
export type CreateAnimatorGatheringMutationFn = Apollo.MutationFunction<CreateAnimatorGatheringMutation, CreateAnimatorGatheringMutationVariables>;

/**
 * __useCreateAnimatorGatheringMutation__
 *
 * To run a mutation, you first call `useCreateAnimatorGatheringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnimatorGatheringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnimatorGatheringMutation, { data, loading, error }] = useCreateAnimatorGatheringMutation({
 *   variables: {
 *      creator: // value for 'creator'
 *      gathering: // value for 'gathering'
 *   },
 * });
 */
export function useCreateAnimatorGatheringMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnimatorGatheringMutation, CreateAnimatorGatheringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnimatorGatheringMutation, CreateAnimatorGatheringMutationVariables>(CreateAnimatorGatheringDocument, options);
      }
export type CreateAnimatorGatheringMutationHookResult = ReturnType<typeof useCreateAnimatorGatheringMutation>;
export type CreateAnimatorGatheringMutationResult = Apollo.MutationResult<CreateAnimatorGatheringMutation>;
export type CreateAnimatorGatheringMutationOptions = Apollo.BaseMutationOptions<CreateAnimatorGatheringMutation, CreateAnimatorGatheringMutationVariables>;
export const AnimatorMembersDocument = gql`
    query animatorMembers {
  animator {
    id
    user {
      id
      ldapUsername
      familyName
      givenName
    }
    status
  }
}
    `;

/**
 * __useAnimatorMembersQuery__
 *
 * To run a query within a React component, call `useAnimatorMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnimatorMembersQuery(baseOptions?: Apollo.QueryHookOptions<AnimatorMembersQuery, AnimatorMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorMembersQuery, AnimatorMembersQueryVariables>(AnimatorMembersDocument, options);
      }
export function useAnimatorMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorMembersQuery, AnimatorMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorMembersQuery, AnimatorMembersQueryVariables>(AnimatorMembersDocument, options);
        }
export type AnimatorMembersQueryHookResult = ReturnType<typeof useAnimatorMembersQuery>;
export type AnimatorMembersLazyQueryHookResult = ReturnType<typeof useAnimatorMembersLazyQuery>;
export type AnimatorMembersQueryResult = Apollo.QueryResult<AnimatorMembersQuery, AnimatorMembersQueryVariables>;
export const AnimatorDetailsDocument = gql`
    query animatorDetails($id: Int!) {
  animator(id: $id) {
    id
    user {
      id
      ldapUsername
      familyName
      givenName
      email
    }
    status
    role
  }
}
    `;

/**
 * __useAnimatorDetailsQuery__
 *
 * To run a query within a React component, call `useAnimatorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnimatorDetailsQuery(baseOptions: Apollo.QueryHookOptions<AnimatorDetailsQuery, AnimatorDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorDetailsQuery, AnimatorDetailsQueryVariables>(AnimatorDetailsDocument, options);
      }
export function useAnimatorDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorDetailsQuery, AnimatorDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorDetailsQuery, AnimatorDetailsQueryVariables>(AnimatorDetailsDocument, options);
        }
export type AnimatorDetailsQueryHookResult = ReturnType<typeof useAnimatorDetailsQuery>;
export type AnimatorDetailsLazyQueryHookResult = ReturnType<typeof useAnimatorDetailsLazyQuery>;
export type AnimatorDetailsQueryResult = Apollo.QueryResult<AnimatorDetailsQuery, AnimatorDetailsQueryVariables>;
export const AnimatorCurrentLeadershipMembersDocument = gql`
    query animatorCurrentLeadershipMembers {
  animatorCurrentLeadershipMembers {
    id
    user {
      id
      familyName
      givenName
    }
    role
  }
}
    `;

/**
 * __useAnimatorCurrentLeadershipMembersQuery__
 *
 * To run a query within a React component, call `useAnimatorCurrentLeadershipMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorCurrentLeadershipMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorCurrentLeadershipMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnimatorCurrentLeadershipMembersQuery(baseOptions?: Apollo.QueryHookOptions<AnimatorCurrentLeadershipMembersQuery, AnimatorCurrentLeadershipMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorCurrentLeadershipMembersQuery, AnimatorCurrentLeadershipMembersQueryVariables>(AnimatorCurrentLeadershipMembersDocument, options);
      }
export function useAnimatorCurrentLeadershipMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorCurrentLeadershipMembersQuery, AnimatorCurrentLeadershipMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorCurrentLeadershipMembersQuery, AnimatorCurrentLeadershipMembersQueryVariables>(AnimatorCurrentLeadershipMembersDocument, options);
        }
export type AnimatorCurrentLeadershipMembersQueryHookResult = ReturnType<typeof useAnimatorCurrentLeadershipMembersQuery>;
export type AnimatorCurrentLeadershipMembersLazyQueryHookResult = ReturnType<typeof useAnimatorCurrentLeadershipMembersLazyQuery>;
export type AnimatorCurrentLeadershipMembersQueryResult = Apollo.QueryResult<AnimatorCurrentLeadershipMembersQuery, AnimatorCurrentLeadershipMembersQueryVariables>;
export const AnimatorYearDocument = gql`
    query animatorYear($id: Int, $year: Int) {
  animatorYear(id: $id, year: $year) {
    id
    year
  }
}
    `;

/**
 * __useAnimatorYearQuery__
 *
 * To run a query within a React component, call `useAnimatorYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimatorYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimatorYearQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useAnimatorYearQuery(baseOptions?: Apollo.QueryHookOptions<AnimatorYearQuery, AnimatorYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimatorYearQuery, AnimatorYearQueryVariables>(AnimatorYearDocument, options);
      }
export function useAnimatorYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimatorYearQuery, AnimatorYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimatorYearQuery, AnimatorYearQueryVariables>(AnimatorYearDocument, options);
        }
export type AnimatorYearQueryHookResult = ReturnType<typeof useAnimatorYearQuery>;
export type AnimatorYearLazyQueryHookResult = ReturnType<typeof useAnimatorYearLazyQuery>;
export type AnimatorYearQueryResult = Apollo.QueryResult<AnimatorYearQuery, AnimatorYearQueryVariables>;
export const AutosavedFormDocument = gql`
    query autosavedForm($key: String!) {
  autosavedForm(key: $key) {
    key
    data
    lastUpdated
    clientData @client
    clientLastUpdated @client
  }
}
    `;

/**
 * __useAutosavedFormQuery__
 *
 * To run a query within a React component, call `useAutosavedFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutosavedFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutosavedFormQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useAutosavedFormQuery(baseOptions: Apollo.QueryHookOptions<AutosavedFormQuery, AutosavedFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutosavedFormQuery, AutosavedFormQueryVariables>(AutosavedFormDocument, options);
      }
export function useAutosavedFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutosavedFormQuery, AutosavedFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutosavedFormQuery, AutosavedFormQueryVariables>(AutosavedFormDocument, options);
        }
export type AutosavedFormQueryHookResult = ReturnType<typeof useAutosavedFormQuery>;
export type AutosavedFormLazyQueryHookResult = ReturnType<typeof useAutosavedFormLazyQuery>;
export type AutosavedFormQueryResult = Apollo.QueryResult<AutosavedFormQuery, AutosavedFormQueryVariables>;
export const SaveAutosavedFormDocument = gql`
    mutation saveAutosavedForm($key: String!, $data: JSON!) {
  saveAutosavedForm(key: $key, data: $data) {
    key
    data
    lastUpdated
    clientData @client
    clientLastUpdated @client
  }
}
    `;
export type SaveAutosavedFormMutationFn = Apollo.MutationFunction<SaveAutosavedFormMutation, SaveAutosavedFormMutationVariables>;

/**
 * __useSaveAutosavedFormMutation__
 *
 * To run a mutation, you first call `useSaveAutosavedFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAutosavedFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAutosavedFormMutation, { data, loading, error }] = useSaveAutosavedFormMutation({
 *   variables: {
 *      key: // value for 'key'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveAutosavedFormMutation(baseOptions?: Apollo.MutationHookOptions<SaveAutosavedFormMutation, SaveAutosavedFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAutosavedFormMutation, SaveAutosavedFormMutationVariables>(SaveAutosavedFormDocument, options);
      }
export type SaveAutosavedFormMutationHookResult = ReturnType<typeof useSaveAutosavedFormMutation>;
export type SaveAutosavedFormMutationResult = Apollo.MutationResult<SaveAutosavedFormMutation>;
export type SaveAutosavedFormMutationOptions = Apollo.BaseMutationOptions<SaveAutosavedFormMutation, SaveAutosavedFormMutationVariables>;
export const DeleteAutosavedFormDocument = gql`
    mutation deleteAutosavedForm($key: String!) {
  deleteAutosavedForm(key: $key)
}
    `;
export type DeleteAutosavedFormMutationFn = Apollo.MutationFunction<DeleteAutosavedFormMutation, DeleteAutosavedFormMutationVariables>;

/**
 * __useDeleteAutosavedFormMutation__
 *
 * To run a mutation, you first call `useDeleteAutosavedFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAutosavedFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAutosavedFormMutation, { data, loading, error }] = useDeleteAutosavedFormMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteAutosavedFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAutosavedFormMutation, DeleteAutosavedFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAutosavedFormMutation, DeleteAutosavedFormMutationVariables>(DeleteAutosavedFormDocument, options);
      }
export type DeleteAutosavedFormMutationHookResult = ReturnType<typeof useDeleteAutosavedFormMutation>;
export type DeleteAutosavedFormMutationResult = Apollo.MutationResult<DeleteAutosavedFormMutation>;
export type DeleteAutosavedFormMutationOptions = Apollo.BaseMutationOptions<DeleteAutosavedFormMutation, DeleteAutosavedFormMutationVariables>;
export const AutosavedFormUpdatedDocument = gql`
    subscription autosavedFormUpdated($key: String!) {
  autosavedFormUpdated(key: $key) {
    key
    data
    lastUpdated
    clientData @client
    clientLastUpdated @client
  }
}
    `;

/**
 * __useAutosavedFormUpdatedSubscription__
 *
 * To run a query within a React component, call `useAutosavedFormUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAutosavedFormUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutosavedFormUpdatedSubscription({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useAutosavedFormUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<AutosavedFormUpdatedSubscription, AutosavedFormUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AutosavedFormUpdatedSubscription, AutosavedFormUpdatedSubscriptionVariables>(AutosavedFormUpdatedDocument, options);
      }
export type AutosavedFormUpdatedSubscriptionHookResult = ReturnType<typeof useAutosavedFormUpdatedSubscription>;
export type AutosavedFormUpdatedSubscriptionResult = Apollo.SubscriptionResult<AutosavedFormUpdatedSubscription>;
export const FeatureFlagDocument = gql`
    query featureFlag($name: String!) {
  featureFlag(name: $name)
}
    `;

/**
 * __useFeatureFlagQuery__
 *
 * To run a query within a React component, call `useFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, options);
      }
export function useFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, options);
        }
export type FeatureFlagQueryHookResult = ReturnType<typeof useFeatureFlagQuery>;
export type FeatureFlagLazyQueryHookResult = ReturnType<typeof useFeatureFlagLazyQuery>;
export type FeatureFlagQueryResult = Apollo.QueryResult<FeatureFlagQuery, FeatureFlagQueryVariables>;
export const RequestPasswordlessChallengeDocument = gql`
    mutation requestPasswordlessChallenge {
  requestPasswordlessChallenge
}
    `;
export type RequestPasswordlessChallengeMutationFn = Apollo.MutationFunction<RequestPasswordlessChallengeMutation, RequestPasswordlessChallengeMutationVariables>;

/**
 * __useRequestPasswordlessChallengeMutation__
 *
 * To run a mutation, you first call `useRequestPasswordlessChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordlessChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordlessChallengeMutation, { data, loading, error }] = useRequestPasswordlessChallengeMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestPasswordlessChallengeMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordlessChallengeMutation, RequestPasswordlessChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordlessChallengeMutation, RequestPasswordlessChallengeMutationVariables>(RequestPasswordlessChallengeDocument, options);
      }
export type RequestPasswordlessChallengeMutationHookResult = ReturnType<typeof useRequestPasswordlessChallengeMutation>;
export type RequestPasswordlessChallengeMutationResult = Apollo.MutationResult<RequestPasswordlessChallengeMutation>;
export type RequestPasswordlessChallengeMutationOptions = Apollo.BaseMutationOptions<RequestPasswordlessChallengeMutation, RequestPasswordlessChallengeMutationVariables>;
export const RegisterPasswordlessCredentialsDocument = gql`
    mutation registerPasswordlessCredentials($registration: JSONObject!) {
  registerPasswordlessCredentials(registration: $registration)
}
    `;
export type RegisterPasswordlessCredentialsMutationFn = Apollo.MutationFunction<RegisterPasswordlessCredentialsMutation, RegisterPasswordlessCredentialsMutationVariables>;

/**
 * __useRegisterPasswordlessCredentialsMutation__
 *
 * To run a mutation, you first call `useRegisterPasswordlessCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPasswordlessCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPasswordlessCredentialsMutation, { data, loading, error }] = useRegisterPasswordlessCredentialsMutation({
 *   variables: {
 *      registration: // value for 'registration'
 *   },
 * });
 */
export function useRegisterPasswordlessCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<RegisterPasswordlessCredentialsMutation, RegisterPasswordlessCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterPasswordlessCredentialsMutation, RegisterPasswordlessCredentialsMutationVariables>(RegisterPasswordlessCredentialsDocument, options);
      }
export type RegisterPasswordlessCredentialsMutationHookResult = ReturnType<typeof useRegisterPasswordlessCredentialsMutation>;
export type RegisterPasswordlessCredentialsMutationResult = Apollo.MutationResult<RegisterPasswordlessCredentialsMutation>;
export type RegisterPasswordlessCredentialsMutationOptions = Apollo.BaseMutationOptions<RegisterPasswordlessCredentialsMutation, RegisterPasswordlessCredentialsMutationVariables>;
export const PasswordlessLoginDocument = gql`
    mutation passwordlessLogin($authentication: JSONObject!) {
  passwordlessLogin(authentication: $authentication) {
    token
    user {
      id
      ldapUsername
      email
      familyName
      givenName
    }
    roles
  }
}
    `;
export type PasswordlessLoginMutationFn = Apollo.MutationFunction<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>;

/**
 * __usePasswordlessLoginMutation__
 *
 * To run a mutation, you first call `usePasswordlessLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordlessLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordlessLoginMutation, { data, loading, error }] = usePasswordlessLoginMutation({
 *   variables: {
 *      authentication: // value for 'authentication'
 *   },
 * });
 */
export function usePasswordlessLoginMutation(baseOptions?: Apollo.MutationHookOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>(PasswordlessLoginDocument, options);
      }
export type PasswordlessLoginMutationHookResult = ReturnType<typeof usePasswordlessLoginMutation>;
export type PasswordlessLoginMutationResult = Apollo.MutationResult<PasswordlessLoginMutation>;
export type PasswordlessLoginMutationOptions = Apollo.BaseMutationOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>;
export const GetAllowedPasswordlessCredentialsDocument = gql`
    query getAllowedPasswordlessCredentials($username: String!) {
  getAllowedPasswordlessCredentials(username: $username)
}
    `;

/**
 * __useGetAllowedPasswordlessCredentialsQuery__
 *
 * To run a query within a React component, call `useGetAllowedPasswordlessCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedPasswordlessCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedPasswordlessCredentialsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetAllowedPasswordlessCredentialsQuery(baseOptions: Apollo.QueryHookOptions<GetAllowedPasswordlessCredentialsQuery, GetAllowedPasswordlessCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllowedPasswordlessCredentialsQuery, GetAllowedPasswordlessCredentialsQueryVariables>(GetAllowedPasswordlessCredentialsDocument, options);
      }
export function useGetAllowedPasswordlessCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllowedPasswordlessCredentialsQuery, GetAllowedPasswordlessCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllowedPasswordlessCredentialsQuery, GetAllowedPasswordlessCredentialsQueryVariables>(GetAllowedPasswordlessCredentialsDocument, options);
        }
export type GetAllowedPasswordlessCredentialsQueryHookResult = ReturnType<typeof useGetAllowedPasswordlessCredentialsQuery>;
export type GetAllowedPasswordlessCredentialsLazyQueryHookResult = ReturnType<typeof useGetAllowedPasswordlessCredentialsLazyQuery>;
export type GetAllowedPasswordlessCredentialsQueryResult = Apollo.QueryResult<GetAllowedPasswordlessCredentialsQuery, GetAllowedPasswordlessCredentialsQueryVariables>;
export const UsernameDocument = gql`
    query username {
  me {
    user {
      id
      ldapUsername
    }
  }
}
    `;

/**
 * __useUsernameQuery__
 *
 * To run a query within a React component, call `useUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsernameQuery(baseOptions?: Apollo.QueryHookOptions<UsernameQuery, UsernameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsernameQuery, UsernameQueryVariables>(UsernameDocument, options);
      }
export function useUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameQuery, UsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsernameQuery, UsernameQueryVariables>(UsernameDocument, options);
        }
export type UsernameQueryHookResult = ReturnType<typeof useUsernameQuery>;
export type UsernameLazyQueryHookResult = ReturnType<typeof useUsernameLazyQuery>;
export type UsernameQueryResult = Apollo.QueryResult<UsernameQuery, UsernameQueryVariables>;