import { RichTextReadOnly, RichTextReadOnlyProps } from 'mui-tiptap'
import useExtensions from './useExtensions'

type WrapperProps = Partial<Omit<RichTextReadOnlyProps, 'content'>> & {
  content: Required<RichTextReadOnlyProps>['content']
}

export default function Wrapper(props: WrapperProps) {
  const extensions = useExtensions()

  return <RichTextReadOnly {...{ ...props, extensions }} />
}
