import { Palette, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

type StyledBadgeProps = PropsWithChildren<{
  color?: Exclude<
    keyof Palette,
    | 'common'
    | 'mode'
    | 'tonalOffset'
    | 'contrastThreshold'
    | 'getContrastText'
    | 'augmentColor'
    | 'background'
    | 'action'
    | 'text'
    | 'divider'
  >
}>

export default function StyledBadge(props: StyledBadgeProps) {
  const theme = useTheme()
  const background =
    props.color === 'grey'
      ? theme.palette.mode === 'dark'
        ? theme.palette.grey[600]
        : theme.palette.grey[500]
      : theme.palette[props.color || 'primary'].gradient ||
        theme.palette[props.color || 'primary'].main

  const color =
    props.color === 'grey'
      ? '#fff'
      : theme.palette[props.color || 'primary'].contrastText

  return (
    <span
      style={{
        background,
        color,
        borderRadius: '0.1875rem',
        padding: '0 0.4rem',
        display: 'flex',
        gap: '0.1875rem',
        alignItems: 'center',
        textWrap: 'nowrap',
      }}
    >
      {props.children}
    </span>
  )
}
