import { AppBarContext } from './AppBarContext'
import {
  AppBar,
  Box,
  IconButton,
  SvgIcon,
  Toolbar,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { ReactComponent as ItkAppLogo } from '@/shared/assets/logos/itkapp.svg'
import { ReactComponent as AnimatorLogo } from '@/shared/assets/logos/animator.svg'
import React, { DOMAttributes, PropsWithChildren, useState } from 'react'
import ProfileDropDown from './ProfileDropDown'
import useNormalizedPathname from '@/shared/hooks/useNormalizedPathname'
import { useRegisterSW } from 'virtual:pwa-register/react'
import SessionTimeoutNotification from './SessionTimeoutNotification'
import StandaloneAppBar from './StandaloneAppBar'
import usePageTitle from '@/shared/hooks/usePageTitle'

const AppBarBox = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  touchAction: 'none',
}))

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

export type AppBarProps = PropsWithChildren<{
  appBarBoxProps: DOMAttributes<any> | null | undefined
  appBarButtonRef: React.RefObject<HTMLButtonElement>
  notificationButtonRef: React.RefObject<HTMLButtonElement>
  onAppButtonClicked?: () => void
  onProfileButtonClicked?: () => void
  onNotificationClicked?: () => void
}>

function AppBarTitle(props: { titlebar?: boolean }) {
  const [title] = usePageTitle()

  return (
    <Typography
      variant="h6"
      component="div"
      sx={{
        flexGrow: 1,
        flexShrink: 5,
        textWrap: 'nowrap',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        viewTransitionName: 'appbar-title',
        ...(props.titlebar && { fontSize: '16px !important' }),
      }}
    >
      {title}
    </Typography>
  )
}

export function AppBarContent(props: AppBarProps & { titlebar?: boolean }) {
  const theme = useTheme()
  const enableMobileSessionTimeoutNotification = useMediaQuery(
    theme.breakpoints.down('md')
  )

  const [profileAnchorEl, setProfileAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const handleProfileDropdownClose = () => {
    setProfileAnchorEl(null)
  }

  const handleProfileClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onProfileButtonClicked?.()
    setProfileAnchorEl(e.currentTarget)
  }

  const {
    needRefresh: [pwaUpdateAvailable],
    updateServiceWorker,
  } = useRegisterSW()

  return (
    <>
      {props.children}
      <Toolbar
        variant="regular"
        sx={{
          viewTransitionName: 'appbar-toolbar',
          zIndex: 1000,
          minHeight: props.titlebar ? '0px !important' : undefined,
          ...(props.titlebar && {
            ml: 'env(titlebar-area-x, 3px)',
            mt: 'env(titlebar-area-y, 0)',
            width: 'calc(env(titlebar-area-width, 100%) - 1em)',
          }),
        }}
        disableGutters={props.titlebar}
      >
        <IconButton
          ref={props.appBarButtonRef}
          edge="start"
          color="inherit"
          aria-label="app button"
          size={props.titlebar ? 'small' : undefined}
          sx={{ mr: 2, appRegion: 'no-drag', '-webkit-app-region': 'no-drag' }}
          onClick={() => props.onAppButtonClicked?.()}
        >
          <AppBarLogo titlebar={props.titlebar} />
        </IconButton>

        <AppBarTitle titlebar={props.titlebar} />

        <AppBarContext
          handleProfileClick={handleProfileClick}
          pwaUpdateAvailable={pwaUpdateAvailable}
          updateServiceWorker={updateServiceWorker}
          onNotificationClicked={props.onNotificationClicked}
          titlebar={props.titlebar}
          notificationButtonRef={props.notificationButtonRef}
        />
      </Toolbar>
      {enableMobileSessionTimeoutNotification && !props.titlebar && (
        <SessionTimeoutNotification titlebar={props.titlebar} />
      )}

      <ProfileDropDown
        anchorTo={profileAnchorEl}
        handleClose={handleProfileDropdownClose}
      />
    </>
  )
}

export default function AppBarWrapper(props: AppBarProps) {
  const isWindowOverlayEnabled =
    useMediaQuery('(display-mode: window-controls-overlay)') &&
    'windowControlsOverlay' in navigator
  const theme = useTheme()
  const enableMobileSessionTimeoutNotification = useMediaQuery(
    theme.breakpoints.down('md')
  )

  // forcibly use StandaloneAppBar for testing locally

  // return (
  //   <>
  //     <StandaloneAppBar {...props} />
  //     {enableMobileSessionTimeoutNotification && (
  //       <SessionTimeoutNotification titlebar />
  //     )}
  //   </>
  // )

  if (isWindowOverlayEnabled)
    return (
      <>
        <StandaloneAppBar {...props} />
        {enableMobileSessionTimeoutNotification && (
          <SessionTimeoutNotification titlebar />
        )}
      </>
    )

  return (
    <>
      <AppBarBox {...props.appBarBoxProps}>
        <Offset />

        <AppBar position="fixed" elevation={2}>
          <AppBarContent {...props} children={<></>} />
        </AppBar>
        {props.children}
      </AppBarBox>
    </>
  )
}

function AppBarLogo(props: { titlebar?: boolean }) {
  const AppBarSVGIcon = styled(SvgIcon)(({ theme }) => ({
    fill: theme.palette.primary.contrastText,
  }))

  const [, pathTree] = useNormalizedPathname()

  return (
    <AppBarSVGIcon fontSize={props.titlebar ? 'small' : undefined}>
      {pathTree[0] === 'animator' ? <AnimatorLogo /> : <ItkAppLogo />}
    </AppBarSVGIcon>
  )
}
