import { useModals } from '@/routes'
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useSyncExternalStore,
} from 'react'
import useNormalizedPathname from '@/shared/hooks/useNormalizedPathname'
import pageTitleStore from './store'
import { metas as pages } from '@/modulePages'

type UsePageTitleResult = [
  string,
  string,
  { title: string; path: string }[],
  (title: string) => void
]

/**
 *  Hook for accessing the active (or other) page title
 *
 * @param path (optional) path to use to get title for, if not provided current path is used
 * @returns [
 *
 *  current page title,
 *
 * app icon path,
 *
 * array of page titles from farthest parent to current path,
 *
 * function for setting current title dynamically
 *
 * ]
 */
export default function usePageTitle(path?: string): UsePageTitleResult {
  const [currentPath, pathTree] = useNormalizedPathname()
  const modals = useModals()
  const [title, setTitle] = useState('')
  const [iconHref, setIconHref] = useState('/favicon.ico')
  const dynamicTitles = useSyncExternalStore(
    pageTitleStore.subscribe,
    pageTitleStore.getSnapshot
  )

  const tree = useMemo(
    () =>
      pathTree.map((path, i, arr) => {
        const fullPath = arr.slice(0, i + 1).join('/')
        const match = pages[`/${fullPath}`]
        if (match === undefined) {
          return {
            path: fullPath,
            title: dynamicTitles[`/${fullPath}`] || path,
          }
        } else
          return {
            path: fullPath,
            title: match.title || '',
          }
      }),
    [pathTree, dynamicTitles]
  )

  useEffect(() => {
    const activePath: string =
      path !== undefined
        ? path
        : modals.current === ''
        ? currentPath
        : modals.current

    const newTitle = dynamicTitles[activePath] || pages[activePath]?.title

    setTitle(newTitle || '')

    setIconHref(
      pathTree[0] === 'animator' ? '/favicon-animator.ico' : '/favicon.ico'
    )
  }, [currentPath, modals, path, pathTree, dynamicTitles])

  const setDynamicTitle = useCallback(
    (title: string) => {
      pageTitleStore.set({ path: currentPath, title })
    },
    [currentPath]
  )

  return [title, iconHref, tree, setDynamicTitle]
}
