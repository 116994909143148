import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Returns the current location pathname in a consumable way.
 *
 * The following transformations are applied:
 *
 * - The `/[lang]` optional prefix is removed
 */
export default function useNormalizedPathname(): [string, string[]] {
  const location = useLocation()
  const pathTree = useMemo(
    () =>
      location.pathname
        .split('/')
        .filter((s) => s !== '')
        .filter((s, i) => !(i === 0 && s.match(/en|hu/)))
        .filter((p) => !(p === '/' || p === '')),
    [location.pathname]
  )

  return [`/${pathTree.join('/')}`, pathTree]
}
