import { createTheme } from '@mui/material'
import {
  light as defaultLightTheme,
  dark as defaultDarkTheme,
} from './defaultTheme'

declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string
    gradient?: string
  }

  interface SimplePaletteColorOptions {
    lighter?: string
    gradient?: string
  }
}

// Augment the palette to include a newColor
// declare module '@mui/material/styles' {
//   interface Palette {
//     newColor: Palette['primary']
//   }

//   interface PaletteOptions {
//     newColor?: PaletteOptions['primary']
//   }
// }

export const light = createTheme({
  ...defaultLightTheme,
  palette: {
    primary: {
      main: '#BD0505',
      contrastText: '#fcfcfc',
      gradient:
        'linear-gradient(90deg, rgb(189, 5, 5) 0%, rgb(217, 32, 6) 100%)',
    },
    secondary: {
      main: '#841000',
      contrastText: '#fcfcfc',
      lighter: '#8410001A',
    },
    action: {
      selectedOpacity: 0.16,
    },
  },
})

export const dark = createTheme({
  ...defaultDarkTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#e00606',
      gradient:
        'linear-gradient(90deg, rgb(189, 5, 5) 0%, rgb(217, 32, 6) 100%)',
    },
    secondary: {
      main: '#991200',
      lighter: '#84100040',
    },
  },
})
