import { useContext } from 'react'
import { OfflineModeContext } from '../OfflineModeProvider'
import {
  Collapse,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { CloudOff } from '@mui/icons-material'

type OfflineModeNotificationProps = {
  titlebar?: boolean
}

export default function OfflineModeNotification(
  props: OfflineModeNotificationProps
) {
  const { isOffline } = useContext(OfflineModeContext)
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Collapse
      in={isOffline}
      unmountOnExit
      sx={{ ...(!(smallScreen || props.titlebar) && { mr: 1, ml: 1 }) }}
    >
      <Paper
        sx={{
          ...((smallScreen || props.titlebar) && {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }),
          display: 'flex',
          p: props.titlebar && !smallScreen ? '0 0 0 0.5rem' : 1,
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
          width: '100%',
          gap: 1,
        }}
        elevation={0}
      >
        <CloudOff />

        <Divider flexItem orientation="vertical" />

        <Typography>Nincs internetkapcsolat</Typography>
      </Paper>
    </Collapse>
  )
}
