import useFullscreenRoute from '@/shared/hooks/useFullscreenRoute'
import { PropsWithChildren, createContext, useState } from 'react'

export const FullscreenContext = createContext({
  fullscreen: false,
  toggleFullscreen: () => {},
  setFullscreen: (fullscreen: boolean) => {},
})

export default function FullscreenProvider(props: PropsWithChildren) {
  const fullscreenRoute = useFullscreenRoute()
  const [fullscreen, setEnableFullscreen] = useState(false)

  return (
    <FullscreenContext.Provider
      value={{
        fullscreen: fullscreen || fullscreenRoute,
        setFullscreen: (fullscreen: boolean) => {
          setEnableFullscreen(fullscreen)
        },
        toggleFullscreen: () => {
          setEnableFullscreen((fullscreen) => !fullscreen)
        },
      }}
    >
      {props.children}
    </FullscreenContext.Provider>
  )
}
