import {
  Box,
  BoxProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Skeleton,
  styled,
} from '@mui/material'
import { PropsWithChildren, Suspense, useEffect, useMemo, useRef } from 'react'
import useNormalizedPathname from '../hooks/useNormalizedPathname'
import { Circle } from '@mui/icons-material'
import useModulePages from '../hooks/useModulePages'
import { useSuspenseFeatureFlag } from '../hooks/useFeatureFlag'

const LeftScrollbarBox = styled(Box)(({ theme }) => ({
  direction: 'rtl',
}))

const RightScrollbarBox = styled(Box)(({ theme }) => ({}))

function LeftScrollbarWrapper({
  leftScrollbar,
  children,
  ...boxProps
}: PropsWithChildren<{ leftScrollbar?: boolean } & Partial<BoxProps>>) {
  return leftScrollbar ? (
    <LeftScrollbarBox component="div" {...boxProps}>
      {children}
    </LeftScrollbarBox>
  ) : (
    <RightScrollbarBox component="div" {...boxProps}>
      {children}
    </RightScrollbarBox>
  )
}

function ModulePageEntryLoader() {
  return (
    <Skeleton
      variant="rounded"
      height={40}
      sx={{ ml: 1, mr: 1, mb: 1, mt: 1, borderRadius: 2 }}
    />
  )
}

type ModulePageEntryProps = {
  title: string
  href: string
  icon?: JSX.Element
  onClick?: () => void
  active?: boolean
  hideFeatureFlag?: string
}

function ModulePageEntry(props: ModulePageEntryProps) {
  const ref = useRef<HTMLLIElement | null>(null)
  const hideFeatureFlag = useSuspenseFeatureFlag(props.hideFeatureFlag ?? '', {
    fetchPolicy: 'cache-and-network',
    skip: props.hideFeatureFlag === undefined,
  })

  useEffect(() => {
    if (props.active)
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'center',
      })
  }, [props.active])

  if (hideFeatureFlag) return null

  return (
    <ListItem ref={ref} sx={{ pl: 1, pr: 1, pb: 0.5, pt: 0.5 }}>
      <ListItemButton
        sx={{ borderRadius: 2 }}
        href={props.href}
        onClick={props.onClick}
        selected={props.active}
      >
        {props.icon ? (
          <ListItemIcon>{props.icon}</ListItemIcon>
        ) : (
          <ListItemIcon>
            <Circle sx={{ ml: '9px', fontSize: 6 }} />
          </ListItemIcon>
        )}
        {props.title}
      </ListItemButton>
    </ListItem>
  )
}

type ModulePagesProps = {
  leftScrollbar?: boolean
  onClick?: () => void
}

export default function ModulePages({
  leftScrollbar,
  onClick,
}: ModulePagesProps) {
  const pages = useModulePages()
  const [currentPath] = useNormalizedPathname()

  // the closest path from list of pages that matches the current path
  const currentModulePagePath = useMemo(
    () => pages.toSorted((a, b) => b.path.length - a.path.length),
    [pages]
  ).find((page) => currentPath.includes(page.path))?.path

  return (
    <LeftScrollbarWrapper
      {...{ leftScrollbar }}
      sx={{
        overflow: 'auto',
      }}
    >
      {pages.map((page) => (
        <Suspense key={page.path} fallback={<ModulePageEntryLoader />}>
          <ModulePageEntry
            title={page.label ?? (page.title || page.path)}
            href={page.path}
            onClick={onClick}
            icon={page.icon && <page.icon />}
            active={currentModulePagePath === page.path}
            hideFeatureFlag={page.hideFeatureFlag}
          />
        </Suspense>
      ))}
    </LeftScrollbarWrapper>
  )
}
