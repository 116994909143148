import { Stack, Typography } from '@mui/material'
import ContextLogo from './ContextLogo'

type UnauthorizedNoticeProps = {
  logo?: JSX.Element
  title?: string
  message?: string
}

export default function UnauthorizedNotice(props: UnauthorizedNoticeProps) {
  const logo = props.logo ?? <ContextLogo />
  const title = props.title ?? 'Hozzáférés megtagadva!'

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      padding={2}
      sx={{ height: '100%' }}
    >
      {logo}
      <Typography variant="h5">{title}</Typography>
      {props.message ? <Typography>{props.message}</Typography> : null}
    </Stack>
  )
}
