import {
  AnimatorCurrentLeadershipMembersDocument,
  AnimatorCurrentLeadershipMembersQuery,
} from '@/gql/generated'
import { useSuspenseQuery } from '@apollo/client'
import { MenuItem, TextField, TextFieldProps } from '@mui/material'

type MemberSelectProps = {
  title: string
  onChange: (selectedYearId: number) => void
} & Partial<Omit<TextFieldProps, 'onChange'>>

export function MemberSelect(props: MemberSelectProps) {
  const { onChange, title, ...others } = props

  const { data } = useSuspenseQuery<AnimatorCurrentLeadershipMembersQuery>(
    AnimatorCurrentLeadershipMembersDocument,
    { fetchPolicy: 'cache-and-network' }
  )

  return (
    <TextField
      select
      id="memberselect"
      label={title}
      onChange={(e) => {
        onChange(parseInt(e.target.value))
      }}
      {...others}
    >
      {data.animatorCurrentLeadershipMembers?.map((animator) => (
        <MenuItem
          value={animator?.id}
          key={animator?.id}
        >{`${animator?.user.familyName} ${animator?.user.givenName}`}</MenuItem>
      ))}
    </TextField>
  )
}
