import {
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client'
import { ApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import {
  IsLoggedInDocument,
  HandleLoginInput,
  LogoutDocument,
  MeDocument,
} from './generated'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { RetryLink } from '@apollo/client/link/retry'
import cache from './cache'

/**
 * Logs out the client, deleting the user token from local storage.
 */
function doLogout(client: ApolloClient<unknown>) {
  localStorage.removeItem('token')
  client.mutate({
    mutation: LogoutDocument,
  })
}

function decode_base64url(data: string) {
  return atob(data.replace(/-/g, '+').replace(/_/g, '/'))
}

/**
 * @returns the iat (issued at) timestamp of the user token, or 0 if no token is present
 */
export function getTokenIat() {
  const token = localStorage.getItem('token')
  if (token === null) return 0
  const payload = JSON.parse(decode_base64url(token.split('.')[1]))
  return payload.iat
}

const httpLink = createHttpLink({
  uri: `${document.baseURI}GraphQL`,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${document.baseURI
      .replace(/^https:/, 'wss:')
      .replace(/^http:/, 'ws:')}subscriptions`,
    connectionParams: () => ({
      authToken: localStorage.getItem('token'),
    }),
    shouldRetry: () => true,
    retryAttempts: 10,
  })
)

/**
 * Currently active setTimeout handle, which calls doLogout() if the client session has expired
 */
let sessionTimeoutHandle: number | null = null

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  }
})

const sessionLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-session-id': sessionStorage.getItem('sessionId') || undefined,
    },
  }))

  return forward(operation).map((response) => {
    const context = operation.getContext()
    const {
      response: { headers },
    } = context

    if (headers) {
      const sessionId = headers.get('x-session-id')
      if (sessionId) sessionStorage.setItem('sessionId', sessionId)
    }

    return response
  })
})

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 10,
    retryIf: (error, _operation) => !!error,
  },
})

const httpFinalLink = authLink
  .concat(sessionLink)
  .concat(retryLink)
  .concat(httpLink)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpFinalLink
)

const isLoggedIn = localStorage.getItem('token') !== null
cache.writeQuery({ query: IsLoggedInDocument, data: { isLoggedIn } })
if (!isLoggedIn)
  cache.writeQuery({
    query: MeDocument,
    data: { me: null },
  })

const client = new ApolloClient({
  cache: cache,
  link: splitLink,
  resolvers: {
    Mutation: {
      handleLogin: (
        _,
        { loginResult }: { loginResult: HandleLoginInput },
        { cache }: { cache: InMemoryCache }
      ) => {
        cache.writeQuery({
          query: IsLoggedInDocument,
          data: { isLoggedIn: true },
        })
        localStorage.setItem('token', loginResult.token)
        if (sessionTimeoutHandle !== null) clearTimeout(sessionTimeoutHandle)
        sessionTimeoutHandle = setTimeout(
          () => doLogout(client),
          SESSION_TIMEOUT
        ) as unknown as number
        cache.writeQuery({
          query: MeDocument,
          data: {
            me: {
              user: loginResult.user,
              roles: loginResult.roles,
            },
          },
        })
        client.getObservableQueries('active').forEach((query) => {
          if (query.queryName === 'me') return
          query.refetch()
        })
        return true
      },
      handleTokenRenewal: (
        _,
        { token }: { token: string },
        { cache }: { cache: InMemoryCache }
      ) => {
        localStorage.setItem('token', token)
        if (sessionTimeoutHandle !== null) clearTimeout(sessionTimeoutHandle)
        sessionTimeoutHandle = setTimeout(
          () => doLogout(client),
          SESSION_TIMEOUT
        ) as unknown as number
      },
      logout: async (_, __, { cache }: { cache: InMemoryCache }) => {
        await client.resetStore()
        cache.writeQuery({
          query: IsLoggedInDocument,
          data: { isLoggedIn: false },
        })
        localStorage.removeItem('token')
        localStorage.removeItem('lastActivity')
        return true
      },
    },
    Query: {
      isLoggedIn: (_, __, ___) => {
        return localStorage.getItem('token') !== null
      },
    },
  },
})

// check if user session is still valid after reloading
const iat = getTokenIat()
if (Number.isNaN(iat) || iat + SESSION_TIMEOUT < Date.now()) {
  // user session expired, log out if logged in
  if (localStorage.getItem('token') !== null)
    client.mutate({ mutation: LogoutDocument })
} else {
  // session still valid, setup handle
  sessionTimeoutHandle = setTimeout(
    () => doLogout(client),
    iat + SESSION_TIMEOUT - Date.now()
  ) as unknown as number
}

export default client
