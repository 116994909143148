import usePageTitle from './usePageTitle'

/**
 * Wrapper for setting current page title dynamically from `usePageTitle()` hook
 * @returns page title setter function, which should be called from an `useEffect()` hook.
 * Usually it should only run once after the page has rendered.
 */
export default function useSetPageTitle() {
  const [, , , setDynamicTitle] = usePageTitle()
  return setDynamicTitle
}
