import { useCallback, useState } from 'react'

export default function useInput(initialValue = '') {
  const [value, setValue] = useState(initialValue)
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value)
  }, [])

  return {
    props: {
      onChange,
      value,
    },
    setValue,
  }
}
