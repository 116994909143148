import { IsLoggedInDocument, IsLoggedInQuery } from '@/gql/generated'
import { useSuspenseQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

/**
 * A helper hook that only updates when the user logs in. It does not refetch
 * after the user session times out or the user logs out.
 *
 * This is useful if the component has content that should persist before the user
 * logs in again.
 *
 * @param alwaysUpdate If true, the hook will always update when the user logs in, thus overriding the default behavior.
 *
 * @returns true if the user is logged in. Only updates once, after the user initially logs in.
 * Does not update if the user is already logged in, unless alwaysUpdate is set.
 */
export default function useIsLoggedIn(alwaysUpdate = false) {
  const [updated, setUpdated] = useState(false)
  const { data } = useSuspenseQuery<IsLoggedInQuery>(IsLoggedInDocument, {
    skip: alwaysUpdate ? false : updated,
    errorPolicy: 'ignore',
  })

  useEffect(() => {
    if (updated) return
    if (data?.isLoggedIn) setUpdated(true)
  }, [data?.isLoggedIn, updated])

  return data?.isLoggedIn || false
}
