import { useTheme } from '@mui/material'
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuButtonCodeBlock,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonImageUpload,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
} from 'mui-tiptap'

export default function EditorMenuControls() {
  const theme = useTheme()

  // TODO: localization and translation

  return (
    <MenuControlsContainer>
      <MenuButtonUndo tooltipLabel="Visszavonás" />
      <MenuButtonRedo tooltipLabel="Előre" />

      <MenuDivider />

      <MenuSelectHeading />

      <MenuDivider />

      <MenuSelectFontSize />

      <MenuDivider />

      <MenuButtonBold tooltipLabel="Félkövér" />

      <MenuButtonItalic tooltipLabel="Dőlt" />

      <MenuButtonUnderline tooltipLabel="Aláhúzott" />

      <MenuButtonStrikethrough tooltipLabel="Áthúzott" />

      <MenuDivider />

      <MenuButtonTextColor
        tooltipLabel="Betűszín"
        defaultTextColor={theme.palette.text.primary}
        swatchColors={[
          { value: '#000000', label: 'Black' },
          { value: '#ffffff', label: 'White' },
          { value: '#888888', label: 'Grey' },
          { value: '#ff0000', label: 'Red' },
          { value: '#ff9900', label: 'Orange' },
          { value: '#ffff00', label: 'Yellow' },
          { value: '#00d000', label: 'Green' },
          { value: '#0000ff', label: 'Blue' },
        ]}
      />

      <MenuButtonHighlightColor
        tooltipLabel="Kiemelés színe"
        swatchColors={[
          { value: '#595959', label: 'Dark grey' },
          { value: '#dddddd', label: 'Light grey' },
          { value: '#ffa6a6', label: 'Light red' },
          { value: '#ffd699', label: 'Light orange' },
          { value: '#ffff00', label: 'Yellow' },
          { value: '#99cc99', label: 'Light green' },
          { value: '#90c6ff', label: 'Light blue' },
          { value: '#8085e9', label: 'Light purple' },
        ]}
      />

      <MenuDivider />

      <MenuButtonEditLink />

      <MenuDivider />

      <MenuSelectTextAlign />

      <MenuDivider />

      <MenuButtonOrderedList tooltipLabel="Rendezett lista" />

      <MenuButtonBulletedList tooltipLabel="Lista" />

      <MenuButtonTaskList tooltipLabel="Feladatlista" />

      {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
      {isTouchDevice() && (
        <>
          <MenuButtonIndent />

          <MenuButtonUnindent />
        </>
      )}

      <MenuDivider />

      <MenuButtonBlockquote tooltipLabel="Idézet" />

      <MenuDivider />

      <MenuButtonCode tooltipLabel="Kód" />

      <MenuButtonCodeBlock tooltipLabel="Kódblokk" />

      <MenuDivider />

      <MenuButtonImageUpload
        tooltipLabel="Képfeltöltés"
        onUploadFiles={(files) =>
          files.map((file) => ({
            src: URL.createObjectURL(file),
            alt: file.name,
          }))
        }
      />

      <MenuDivider />

      <MenuButtonHorizontalRule tooltipLabel="Vízszintes vonal" />

      <MenuButtonAddTable tooltipLabel="Táblázat" />

      <MenuDivider />

      <MenuButtonRemoveFormatting tooltipLabel="Formázás törlése" />
    </MenuControlsContainer>
  )
}
