import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import * as ReactDOMClient from 'react-dom/client'
import client from '@/gql/client'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { routes } from '@generouted/react-router/lazy'
import dayjs from 'dayjs'
import 'dayjs/locale/hu'
import RelativeTime from 'dayjs/plugin/relativeTime'

import './index.css'
import './App.css'
import '@fontsource/pt-sans/400.css'
import '@fontsource/pt-sans/700.css'

dayjs.locale('hu')
dayjs.extend(RelativeTime)

const serviceWorkerUpdateIntervalMS = 10 * 60 * 1000 // 10 minutes

export default function Index(props: { env: any; baseUrl: string }) {
  const router = createBrowserRouter(routes)

  // taken from: https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
  useRegisterSW({
    onRegisteredSW(url, registration) {
      registration &&
        setInterval(async () => {
          if (!(registration.installing && navigator)) return

          if ('connection' in navigator && !navigator.onLine) return

          const response = await fetch(url, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          })

          if (response?.status === 200) await registration.update()
        }, serviceWorkerUpdateIntervalMS)
    },
  })

  return (
    <React.StrictMode>
      <ApolloProvider client={props.env.client}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ApolloProvider>
    </React.StrictMode>
  )
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!
const container = document.getElementById('root')!
const root = ReactDOMClient.createRoot(container)
root.render(<Index env={{ client }} baseUrl={baseUrl} />)
