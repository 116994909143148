import useInput from '@/shared/hooks/useInput'
import { useEffect, useState } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material'
import ResponsiveDialog from '@/shared/components/ResponsiveDialog'
import { Add } from '@mui/icons-material'

export type CreateOptionTextDialogProps = {
  title: string
  label: string
  blacklist?: string[]
  open: boolean
  setOpen: (open: boolean) => void
  disable?: boolean
  createNewOption: (title: string) => void
}

export function CreateOptionTextDialog(props: CreateOptionTextDialogProps) {
  const title = useInput()
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [lastError, setLastError] = useState<Error | null>(null)

  const handleClose = () => {
    props.setOpen(false)
    title.setValue('')
    setLastError(null)
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleClick()
  }

  //TODO: Make a more efficient error handling here
  useEffect(() => {
    if (title.props.value.length > 0) {
      if (
        props.blacklist !== null &&
        !props.blacklist?.includes(title.props.value)
      ) {
        setLastError(null)
      }
    }
  }, [props.blacklist, title.props.value])

  useEffect(() => {
    title.setValue('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    try {
      if (title.props.value.length > 0) {
        if (
          props.blacklist !== null &&
          props.blacklist?.includes(title.props.value)
        ) {
          setLastError(new Error('Érvénytelen megadott opció'))
        } else {
          props.createNewOption(title.props.value)
          handleClose()
        }
      } else {
        setLastError(new Error('Üres opció'))
      }
    } catch (e) {
      setLastError(e as any)
      setOpenSnackbar(true)
    }
  }

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={handleClose}
      disableRestoreFocus
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <TextField
          error={lastError !== null}
          autoFocus
          margin="dense"
          fullWidth
          label={props.label}
          disabled={props.disable}
          onKeyUp={onKeyUp}
          helperText={lastError !== null ? lastError?.toString() : ''}
          {...title.props}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={props.disable}>
          Mégsem
        </Button>
        <Button
          variant="contained"
          onClick={() => handleClick()}
          disabled={
            props.disable ||
            lastError !== null ||
            title.props.value.length === 0
          }
          endIcon={props.disable ? <CircularProgress size={20} /> : <Add />}
        >
          Hozzáadás
        </Button>
      </DialogActions>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="error">{lastError?.toString()}</Alert>
      </Snackbar>
    </ResponsiveDialog>
  )
}
