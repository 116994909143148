import { LinkProps, createTheme } from '@mui/material'
import LinkBehavior from './LinkBehavior'
declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string
    gradient?: string
  }

  interface SimplePaletteColorOptions {
    lighter?: string
    gradient?: string
  }
}

const theme = createTheme()

const fontSizeBaseline = {
  h1: 3,
  h2: 2.5,
  h3: 2,
  h4: 1.5,
  h5: 1.25,
  h6: 1.1111,
} as const

const fontSizeBreakpointDeltas = {
  lg: {
    value: 1.2,
    mode: 'up',
  },
  sm: {
    value: 0.9,
    mode: 'down',
  },
} as const

const typographyFontSizes = Object.entries(fontSizeBaseline).reduce(
  (others, [key, value]) => {
    const base = {
      fontSize: `${value}rem`,
    }

    const deltas = Object.entries(fontSizeBreakpointDeltas).reduce(
      (others, [breakpoint, { value, mode }]) => ({
        ...others,
        [theme.breakpoints[mode](
          breakpoint as keyof typeof fontSizeBreakpointDeltas
        )]: {
          fontSize: `${
            value * fontSizeBaseline[key as keyof typeof fontSizeBaseline]
          }rem`,
        },
      }),
      {} as Record<string, any>
    )

    return {
      ...others,
      [key]: {
        ...base,
        ...deltas,
      },
    }
  },
  {} as Record<string, any>
)

export const light = createTheme({
  palette: {
    primary: {
      main: '#00a6e7',
      contrastText: '#fcfcfc',
    },
    secondary: {
      main: '#00507D',
      contrastText: '#fcfcfc',
    },
  },
  shadows: [
    'none',
    '0px 5px 12px 0px rgba(0, 0, 0, 0.20)',
    '0px 8px 14px 0px rgba(0, 0, 0, 0.20)',
    '0px 10px 24px 0px rgba(0, 0, 0, 0.20)',
    '0px 10px 32px 0px rgba(0, 0, 0, 0.20)',
    ...Array<string>(21).fill('none'),
  ] as any,
  typography: {
    fontFamily: [
      'PT Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    ...typographyFontSizes,
    // h1: {
    //   fontSize: '3rem',
    //   [theme.breakpoints.up('lg')]: {
    //     fontSize: '3.4rem',
    //   },
    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: '2.4rem',
    //   },
    // },
    // h2: {
    //   fontSize: '2.5rem',
    //   [theme.breakpoints.up('lg')]: {
    //     fontSize: '3rem',
    //   },
    // },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
})

export const dark = createTheme({
  ...light,
  palette: {
    mode: 'dark',
    primary: light.palette.primary,
    secondary: light.palette.secondary,
  },
  shadows: [
    'none',
    '0px 5px 12px 0px rgba(255, 255, 255, 0.02)',
    '0px 8px 14px 0px rgba(255, 255, 255, 0.02)',
    '0px 10px 24px 0px rgba(255, 255, 255, 0.02)',
    '0px 10px 32px 0px rgba(255, 255, 255, 0.02)',
    ...Array<string>(21).fill('none'),
  ] as any,
})
