import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material'
import { Delete, DragIndicator } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { Draggable, DraggableStyle } from '@hello-pangea/dnd'
import { DraggableOption } from './DraggableOptionsList'

type DraggableOptionProps = {
  option: DraggableOption
  index: number
  disableReorder?: boolean
  disableDelete?: boolean
  disableEdit?: boolean
  icon?: JSX.Element
  onDelete: (id: number) => void
  onEdit?: (id: number) => void
}

export default function DraggableOptionItem(props: DraggableOptionProps) {
  const theme = useTheme()

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggableStyle | undefined
  ) => ({
    // TODO: Change background colour if dragging
    background: isDragging ? theme.palette.action.hover : 'inherit',

    borderRadius: 5,

    marginBottom: 5,

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  return (
    <Draggable
      draggableId={`option-${props.option.id}`}
      index={props.index}
      isDragDisabled={props.disableReorder}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <ListItem sx={{ borderRadius: 2 }}>
            <ListItemIcon {...provided.dragHandleProps}>
              <DragIndicator
                color={props.disableReorder ? 'disabled' : undefined}
              />
              {props.icon && props.icon}
            </ListItemIcon>
            <ListItemText sx={{ flexGrow: 1 }}>
              {props.option.title}
            </ListItemText>
            {!props.disableEdit && (
              <IconButton
                onClick={() => {
                  props.onEdit && props.onEdit(props.option.id)
                }}
                disabled={props.disableEdit}
              >
                <EditIcon />
              </IconButton>
            )}
            <IconButton
              onClick={() => props.onDelete(props.option.id)}
              disabled={props.disableDelete}
            >
              <Delete />
            </IconButton>
          </ListItem>
        </div>
      )}
    </Draggable>
  )
}
