import Login from '@/shared/components/Login'
import { PageMeta } from '@/vite/modulePages/types'

export default function LoginModal() {
  return <Login />
}

export const pageMeta: PageMeta = {
  title: 'Bejelentkezés',
  hidden: true,
}
