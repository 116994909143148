import {
  LinkBubbleMenu,
  RichTextEditor,
  RichTextEditorProps,
  RichTextEditorRef,
  TableBubbleMenu,
} from 'mui-tiptap'
import EditorMenuControls from './EditorMenuControls'
import useExtensions from './useExtensions'
import { Content, JSONContent } from '@tiptap/core'
import { MarkdownStorage } from 'tiptap-markdown'
import { Ref, forwardRef } from 'react'

type EditorJSONOutput = {
  type: 'json'
  content: JSONContent
}
type EditorMarkdownOutput = {
  type: 'markdown'
  content: string
}

type EditorOutput = EditorJSONOutput | EditorMarkdownOutput

type EditorOutputContent<OutputType extends EditorOutput['type']> = Extract<
  EditorOutput,
  { type: OutputType }
>['content']

type EditorPropsWithOutput<OutputType extends EditorOutput['type']> = {
  onContentChanged: (output: EditorOutputContent<OutputType>) => void
  outputType: OutputType
  placeholder?: string
  content?: Content
}

type EditorPropsWithoutOutput = {
  outputType?: never
  onContentChanged?: never
  placeholder?: string
  content?: Content
}

const Editor = forwardRef(
  <OutputVariant extends EditorOutput['type']>(
    // taken from: https://github.com/microsoft/TypeScript/issues/52327#issuecomment-1398578557
    // this allows for narrowing OutputVariant union type to a single type specified by props.outputType
    props: OutputVariant extends unknown
      ? EditorPropsWithOutput<OutputVariant> | EditorPropsWithoutOutput
      : never,
    ref: Ref<RichTextEditorRef>
  ) => {
    const extensions = useExtensions({
      placeholder: props.placeholder,
    })

    const onUpdate: RichTextEditorProps['onUpdate'] = ({
      editor,
      transaction,
    }) => {
      switch (props.outputType) {
        case 'json':
          props.onContentChanged?.(editor.getJSON())
          break

        case 'markdown':
          const markdown = (
            editor.storage.markdown as MarkdownStorage
          ).getMarkdown()
          props.onContentChanged?.(markdown)
          break

        case undefined:
          if (props.onContentChanged)
            console.warn(
              'onContentChanged callback defined, but no outputType is not defined'
            )
          break

        default:
          assertNever(props)
          break
      }
    }

    return (
      <RichTextEditor
        ref={ref}
        content={props.content}
        extensions={extensions}
        editable={true}
        renderControls={() => <EditorMenuControls />}
        RichTextFieldProps={{
          variant: 'outlined',
        }}
        onUpdate={onUpdate}
      >
        {() => (
          <>
            {/*  TODO: label translations */}
            <LinkBubbleMenu />
            <TableBubbleMenu />
          </>
        )}
      </RichTextEditor>
    )
  }
)

export default Editor
