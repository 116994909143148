import { useRouteError } from 'react-router-dom'

export default function Error() {
  const error = useRouteError() as unknown
  return (
    <>
      <h2>Error</h2>
      <h3>this is an ugly error page, please make me look nicer :(</h3>
      <a href="/">Back Home</a>
      {error ? (
        <div>
          <h4>error details:</h4>
          <span>{error.toString()}</span>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
