import type { PageMeta } from '@/vite/modulePages/types'
import { metas, topLevelPages } from '@/modulePages'
import useNormalizedPathname from './useNormalizedPathname'
import { useMemo } from 'react'

type PageMetaWithPath = PageMeta & { path: string }

/**
 * A helper hook for getting all pages of the current module.
 * @returns array of `PageMeta` objects with an extra `path` property
 */
export default function useModulePages(): PageMetaWithPath[] {
  const [, pathTree] = useNormalizedPathname()

  const modulePages = useMemo(
    () =>
      Object.entries(metas)
        .filter(
          (meta) => meta[0].startsWith(`/${pathTree[0]}/`) && !meta[1].hidden
        )
        .map((meta) => ({ path: meta[0], ...meta[1] }))
        .toSorted((a, b) => (a.order ?? 0) - (b.order ?? 0)),
    [pathTree]
  )

  if (pathTree.length === 0 || !metas[`/${pathTree[0]}`]?.moduleRoot) {
    return topLevelPages
  }

  return modulePages
}
