import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd'
import DraggableOptionItem from './DraggableOptionItem'
import { useEffect, useState } from 'react'

export type DraggableOption = {
  id: number
  title: string
  order: number
  icon?: JSX.Element
}

type DraggableOptionsListProps = {
  options: DraggableOption[]
  disableReorder?: boolean
  disableDelete?: boolean
  disableEdit?: boolean
  orderChangedUseIds?: (id: number, beforeId: number, afterId: number) => void
  orderChangedUseOrders?: (options: DraggableOption[]) => void
  onDelete: (id: number) => void
  onEdit?: (id: number) => void
}

export default function DraggableOptionsList(props: DraggableOptionsListProps) {
  const [options, setOptions] = useState(props.options)
  useEffect(() => setOptions(props.options), [props.options])

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return

    const newOptions = Array.from(props.options)
    const [removed] = newOptions.splice(result.source.index, 1)
    newOptions.splice(result.destination.index, 0, removed)

    const id = removed.id
    const beforeId = newOptions[result.destination.index - 1]?.id
    const afterId = newOptions[result.destination.index + 1]?.id

    const previousOption = options.find((option) => option.id === beforeId)
    const nextOption = options.find((option) => option.id === afterId)

    const previousOrder = previousOption?.order ?? -1
    const nextOrder = nextOption?.order ?? options.length

    //setOptions(newOptions.toSorted((a, b) => a.order - b.order))

    setOptions(newOptions)

    props.orderChangedUseIds && props.orderChangedUseIds(id, beforeId, afterId)
    props.orderChangedUseOrders &&
      props.orderChangedUseOrders(
        options.map((option) => ({
          ...option,
          order:
            option.id === id ? (previousOrder + nextOrder) / 2 : option.order,
        }))
      )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="optionsList">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {options.map((option, index) => (
              <DraggableOptionItem
                key={option.id}
                index={index}
                option={option}
                icon={option.icon}
                disableReorder={props.disableReorder}
                disableDelete={props.disableDelete}
                disableEdit={props.disableEdit}
                onDelete={props.onDelete}
                onEdit={props.onEdit}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
