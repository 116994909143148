import {
  PasswordlessLoginDocument,
  PasswordlessLoginMutation,
  PasswordlessLoginMutationVariables,
  RegisterPasswordlessCredentialsDocument,
  RegisterPasswordlessCredentialsMutation,
  RegisterPasswordlessCredentialsMutationVariables,
  RequestPasswordlessChallengeDocument,
  RequestPasswordlessChallengeMutation,
  RequestPasswordlessChallengeMutationVariables,
  UsernameDocument,
  UsernameQuery,
  UsernameQueryVariables,
  useHandleLoginMutation,
} from '@/gql/generated'
import apolloClient from '@/gql/client'
import { client } from '@passwordless-id/webauthn'
import { useCallback, useState } from 'react'

async function getChallenge() {
  const challenge = await apolloClient.mutate<
    RequestPasswordlessChallengeMutation,
    RequestPasswordlessChallengeMutationVariables
  >({
    mutation: RequestPasswordlessChallengeDocument,
  })

  return challenge.data?.requestPasswordlessChallenge
}

async function getUsername() {
  const username = await apolloClient.query<
    UsernameQuery,
    UsernameQueryVariables
  >({
    query: UsernameDocument,
  })

  return username.data?.me?.user?.ldapUsername
}

async function register() {
  const username = await getUsername()
  if (!username) return
  const challenge = await getChallenge()
  if (!challenge) return

  const registration = await client.register(username, challenge)

  const result = await apolloClient.mutate<
    RegisterPasswordlessCredentialsMutation,
    RegisterPasswordlessCredentialsMutationVariables
  >({
    mutation: RegisterPasswordlessCredentialsDocument,
    variables: {
      registration,
    },
  })

  return result
}

async function login() {
  const challenge = await getChallenge()
  if (!challenge) return

  const authentication = await client.authenticate([], challenge)

  const loginResult = await apolloClient.mutate<
    PasswordlessLoginMutation,
    PasswordlessLoginMutationVariables
  >({
    mutation: PasswordlessLoginDocument,
    variables: {
      authentication,
    },
  })

  return loginResult.data?.passwordlessLogin
}

export default function usePasswordlessAuth() {
  const [handleLogin] = useHandleLoginMutation()
  const [loading, setLoading] = useState(false)

  return {
    register: useCallback(async () => {
      setLoading(true)
      try {
        const result = await register()
        setLoading(false)
        return result
      } catch (e) {
        setLoading(false)
        throw e
      }
    }, []),
    login: useCallback(async () => {
      setLoading(true)
      try {
        const result = await login()
        if (result && result.token !== null && result.token !== undefined) {
          await handleLogin({ variables: { loginResult: result as any } })
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        throw e
      }
    }, [handleLogin]),
    passwordlessLoginSupported: client.isAvailable(),
    loading,
  }
}
