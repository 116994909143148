import usePageTitle from '@/shared/hooks/usePageTitle'
import { Helmet } from 'react-helmet'

export default function BrowserTab() {
  const [pageTitle, iconHref] = usePageTitle()

  return (
    <Helmet>
      <title>{pageTitle !== '' ? `${pageTitle} | ITKapp` : 'ITKapp'}</title>
      <link rel="icon" href={iconHref} />
    </Helmet>
  )
}
