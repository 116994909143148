// This file is generated by the following script:
// src/vite/modulePages/generate.ts
//
// Do NOT edit this file manually!
// instead, build with vite to update this file
/* eslint-disable */

import type { PageMeta } from '@/vite/modulePages/types'
import { ITKAppLogo } from '@/shared/components/Logos';
import { AnimatorLogo } from '@/shared/components/Logos';
import { Event as EventIcon } from '@mui/icons-material';
import { Add, MeetingRoom as MeetingRoomIcon } from '@mui/icons-material';
import { Groups as GroupsIcon } from '@mui/icons-material';

export const metas = {
  ['/edu']: {
  title: 'Tanulmányi',
  moduleRoot: true,
  icon: ITKAppLogo,
  order: 1
},
  ['/']: {
  moduleRoot: true,
  icon: ITKAppLogo,
  label: 'Kezdőlap',
  order: 0
},
  ['/changelog']: {
  title: 'Újdonságok',
  hidden: true
},
  ['/animator']: {
  title: 'Animátor',
  moduleRoot: true,
  icon: AnimatorLogo,
  order: 2
},
  ['/profile']: {
  title: 'Profil',
  hidden: true
},
  ['/profile/settings']: {
  title: 'Beállítások',
  hidden: true
},
  ['/animator/esemenyek']: {
  title: 'Események',
  icon: EventIcon,
  hideFeatureFlag: 'page_hide_animator_events'
},
  ['/animator/kozgyulesek']: {
  title: 'Közgyűlések',
  icon: MeetingRoomIcon
},
  ['/animator/tagok']: {
  title: 'Taglista',
  icon: GroupsIcon,
  hideFeatureFlag: 'page_hide_animator_memberlist'
},
  ['/animator/kozgyulesek/uj']: {
  title: 'Új közgyűlés',
  hidden: true
},
  ['/login']: {
  title: 'Bejelentkezés',
  hidden: true,
  modal: true
}
} as Record<string, PageMeta>

export const modules = Object.entries(metas)
  .filter((meta) => meta[1].moduleRoot && !meta[1].hidden)
  .map(([path, meta]) => ({ ...meta, path }))
  .toSorted((a, b) => (a.order ?? 0) - (b.order ?? 0))

export const topLevelPages = Object.entries(metas)
  .filter((meta) => !meta[1].moduleRoot && !meta[1].hidden)
  .filter(([path]) => !metas[`/${path.split('/')[1]}`]?.moduleRoot)
  .map(([path, meta]) => ({ ...meta, path }))
  .toSorted((a, b) => (a.order ?? 0) - (b.order ?? 0))
