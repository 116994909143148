import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material'

type CircularProgressWithLabelProps = {
  label: string
} & CircularProgressProps

export default function CircularProgressWithLabel(
  props: CircularProgressWithLabelProps
) {
  const { label, ...others } = props

  return (
    <Box component="div" sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress {...others} />
      <Box
        component="div"
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption">{label}</Typography>
      </Box>
    </Box>
  )
}
