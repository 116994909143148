import { Suspense } from 'react'
import { ITKAppLogo, AnimatorLogo } from '@/shared/components/Logos'
import useNormalizedPathname from '../hooks/useNormalizedPathname'
import LogoPaperBase from './Logos/LogoPaperBase'

export default function ContextLogo() {
  const [, pathTree] = useNormalizedPathname()

  return (
    <Suspense>
      {pathTree[0] === 'animator' ? (
        <LogoPaperBase>
          <AnimatorLogo />
        </LogoPaperBase>
      ) : (
        <LogoPaperBase>
          <ITKAppLogo />
        </LogoPaperBase>
      )}
    </Suspense>
  )
}
