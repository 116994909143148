import {
  ListItemButton,
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { modules } from '@/modulePages'
import { useLocation, useNavigate } from 'react-router-dom'
import LogoPaperBase from '../../Logos/LogoPaperBase'
import useNormalizedPathname from '@/shared/hooks/useNormalizedPathname'
import { useMeQuery } from '@/gql/generated'

const StyledButton = styled(ListItemButton)(({ theme }) => ({
  minWidth: '6em',
  color: theme.palette.text.primary,
  textTransform: 'none',
  display: 'inline-flex',
  padding: [0, 1, 1, 0],
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  flexGrow: 0,
  flexShrink: 0,
}))

type PickerButtonProps = PropsWithChildren<
  {
    href: string
    label: string
    stackPadding?: number | string
    onClick?: () => void
    active?: boolean
  } & Partial<StackProps>
>

export function PickerButton({
  href,
  label,
  children,
  stackPadding,
  onClick,
  active,
  ...others
}: PickerButtonProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement | null>(null)

  const handleClick = () => {
    onClick?.()
    if (location.pathname !== href) navigate(href)
  }

  useEffect(() => {
    if (active)
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      })
  }, [active])

  return (
    <StyledButton
      ref={ref}
      onClick={handleClick}
      sx={{
        borderRadius: '1rem',
      }}
      selected={active}
    >
      <Stack
        alignItems="center"
        gap="0.75rem"
        sx={{ p: stackPadding !== undefined ? stackPadding : '0.5rem' }}
        {...others}
      >
        {children}
        <Typography variant="body1">{label}</Typography>
      </Stack>
    </StyledButton>
  )
}

const CenteredStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '0.375rem',
  padding: '0.375rem 0',
  '&::before, &::after': {
    // taken from:
    // https://stackoverflow.com/a/34455253
    content: "''",
    margin: 'auto',
  },
}))

type ModulePickerProps = {
  small?: boolean
  onClick?: () => void
} & Partial<StackProps>

export default function ModulePicker(props: ModulePickerProps) {
  const [, pathTree] = useNormalizedPathname()
  const currentModulePath = `/${pathTree[0] !== undefined ? pathTree[0] : ''}`

  const { data: meQuery } = useMeQuery()

  return (
    <CenteredStack {...props}>
      {modules
        .filter(
          (module) =>
            !(
              module.path === '/animator' &&
              !meQuery?.me?.roles?.includes('animator')
            )
        )

        .map((module) => (
          <PickerButton
            key={module.path}
            href={module.path}
            onClick={props.onClick}
            label={module.label ?? (module.title || module.path)}
            active={module.path === currentModulePath}
          >
            <LogoPaperBase small={props.small}>
              {module.icon && <module.icon />}
            </LogoPaperBase>
          </PickerButton>
        ))}
    </CenteredStack>
  )
}
