import {
  a,
  useSpring,
  config,
  SpringValue,
  Interpolation,
} from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { useCallback, MutableRefObject, useRef } from 'react'
import { Box, Divider, IconButton, Paper } from '@mui/material'
import { styled } from '@mui/material'
import ModulePicker from '@/shared/components/AppShell/MobileNavigationPopup/ModulePicker'
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types'
import ModulePages from '@/shared/components/ModulePages'
import { ExpandLess } from '@mui/icons-material'

/**
 * Overlay over the page, which greys out when the popup is open
 */
const PopupDivBackdrop = styled(a.div)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 998,
}))

/**
 * The popup itself that opens when dragged down from the AppBar
 */
const PopupDiv = styled(a.div)(({ theme }) => ({
  position: 'fixed',
  viewTransitionName: 'module-picker-popup',
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 999,
  flexDirection: 'column',
  minHeight: 0,
  // place below AppBar
  top: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(1)})`,
  [theme.breakpoints.down('sm')]: {
    top: `${theme.mixins.toolbar.minHeight}px`,
  },
}))

type MobileNavigationWrapperProps = {
  refs: MobileNavigationPopupProps['refs']
  front: JSX.Element
  back: JSX.Element
}

function MobileNavigationWrapper(props: MobileNavigationWrapperProps) {
  // height of module pickers and app bar
  const height = 144 + 56

  const backRef = useRef<HTMLDivElement | null>(null)

  const [{ y }, spring] = useSpring(() => ({
    y: -height,
  }))

  // taken from: https://codesandbox.io/s/github/pmndrs/use-gesture/tree/main/demo/src/sandboxes/action-sheet

  const open = ({ canceled }: { canceled: boolean }) => {
    spring.start({
      y: 0,
      immediate: false,
      config: canceled ? config.wobbly : config.stiff,
    })
  }

  const close = useCallback(
    (velocity = 0, immediate = false) => {
      spring.start({
        y: -height,
        immediate,
        config: { ...config.stiff, velocity },
      })
    },
    [spring, height]
  )

  /* c8 ignore start */
  // we can't test user dragging with jsdom :(
  const bind = useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      offset: [, oy],
      cancel,
      canceled,
    }) => {
      if (oy > 70) cancel()

      if (last) {
        oy < -height / 2 || (vy < -0.5 && dy < 0)
          ? close(-vy * 0.3)
          : open({ canceled })
      } else {
        spring.start({ y: oy, immediate: true })
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      threshold: 15,
      bounds: { bottom: 0 },
      rubberband: true,
    }
  )
  /* c8 ignore end */

  const backY = y.to(
    (y) =>
      y - (backRef.current?.offsetHeight || 5000) * (1 - (y + height) / height)
  )

  const display = y.to((py) => (py > -height ? 'flex' : 'none'))

  const ratio = y.to((y) => (y + height) / height)

  const displayBackdrop = ratio.to((r) => (r > 0.2 ? 'block' : 'none'))

  props.refs.current = {
    open,
    close,
    bind,
    springValue: ratio,
  }

  return (
    <>
      <PopupDivBackdrop
        style={{
          display: displayBackdrop,
          backgroundColor: ratio.to((r) => `rgba(0, 0, 0, ${r * 0.6 - 0.2})`),
        }}
        onClick={() => close(0)}
      />

      <PopupDiv
        style={{
          display,
          y,
        }}
        role="menu"
        aria-label="module selector popup"
      >
        {/* When the picker bouncing, the background doesn't show through with a large padding.
        The margin is subtracted by the same amount to place the element to it's original place. */}
        <Paper
          sx={{
            mt: -5000,
            pt: 5002,
            zIndex: 500,
            flexShrink: 0,
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
          elevation={1}
        >
          {props.front}

          <Divider flexItem orientation="horizontal" sx={{ pt: 1 }} />
        </Paper>

        <a.div
          ref={backRef}
          style={{
            y: backY,
            display,
            flexDirection: 'column',
            minHeight: 0,
            flexShrink: 8,
            flexGrow: 1,
          }}
        >
          <Paper
            sx={{
              borderRadius: 2,
              mt: -5000,
              pt: 5002,
              pb: 2,
              zIndex: 499,
              display: 'flex',
              flexDirection: 'column',
              minHeight: 0,
            }}
          >
            {props.back}
          </Paper>
        </a.div>

        <a.div
          style={{
            y: backY,
            flexShrink: 1,
            flexGrow: 1,
            zIndex: 498,
          }}
        >
          <Box
            component="span"
            sx={{ display: 'flex', justifyContent: 'center', p: 4 }}
          >
            <IconButton
              onClick={() => close(0)}
              color="inherit"
              sx={{
                zIndex: 498,
              }}
            >
              <ExpandLess />
            </IconButton>
          </Box>
        </a.div>
      </PopupDiv>
    </>
  )
}

export type MobileNavigationPopupRefTypes = {
  /**
   * spring value that represents the open state of the picker
   */
  springValue: SpringValue<number> | Interpolation<any, number>
  /**
   * function that opens the picker
   */
  open: ({ canceled }: { canceled: boolean }) => void
  /**
   * function that closes the picker
   */
  close: (velocity?: number, immediate?: boolean) => void
  /**
   * function that returns the attributes that bind the element to dragging down the popup
   */
  bind: (...args: any[]) => ReactDOMAttributes
}

export type MobileNavigationPopupProps = {
  refs: MutableRefObject<MobileNavigationPopupRefTypes | null>
}

export default function MobileNavigationPopup({
  refs,
}: MobileNavigationPopupProps) {
  const close = () => refs.current?.close()

  return (
    <MobileNavigationWrapper
      {...{ refs }}
      front={<ModulePicker sx={{ overflow: 'auto' }} onClick={close} />}
      back={<ModulePages onClick={close} />}
    />
  )
}
