import { useEffect, useState } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material'
import ResponsiveDialog from '@/shared/components/ResponsiveDialog'
import { Add } from '@mui/icons-material'

type SelectType = {
  id: number
  title: string
}

export type CreateOptionSelectDialogProps = {
  items: SelectType[]
  title: string
  label: string
  open: boolean
  setOpen: (open: boolean) => void
  disable?: boolean
  createNewOption: (selectedOption: SelectType) => void
}

export function CreateOptionSelectDialog(props: CreateOptionSelectDialogProps) {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [lastError, setLastError] = useState<Error | null>(null)
  const [selectedItem, setSelectedItem] = useState<SelectType | null>(null)

  const handleClose = () => {
    props.setOpen(false)
    setLastError(null)
  }

  const handleClick = () => {
    try {
      if (selectedItem) props.createNewOption(selectedItem)
      handleClose()
    } catch (e) {
      setLastError(e as any)
      setOpenSnackbar(true)
    }
  }

  useEffect(() => {
    if (props.open) setSelectedItem(null)
  }, [props.open])

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={handleClose}
      disableRestoreFocus
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <TextField
          select
          error={lastError !== null}
          autoFocus
          margin="dense"
          fullWidth
          label={props.label}
          //value={selectedItem}
          disabled={props.disable}
          onChange={(e) => {
            setSelectedItem(
              props.items.find(
                (item) => item.id === parseInt(e.target.value)
              ) ?? null
            )
          }}
          helperText={lastError !== null ? lastError?.toString() : ''}
        >
          {props.items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={props.disable}>
          Mégsem
        </Button>
        <Button
          variant="contained"
          onClick={() => handleClick()}
          disabled={props.disable || selectedItem === null}
          endIcon={props.disable ? <CircularProgress size={20} /> : <Add />}
        >
          Hozzáadás
        </Button>
      </DialogActions>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="error">{lastError?.toString()}</Alert>
      </Snackbar>
    </ResponsiveDialog>
  )
}
