import {
  FeatureFlagDocument,
  FeatureFlagQuery,
  FeatureFlagQueryVariables,
  useFeatureFlagQuery,
} from '@/gql/generated'
import {
  SuspenseQueryHookFetchPolicy,
  WatchQueryFetchPolicy,
  skipToken,
  useSuspenseQuery,
} from '@apollo/client'

export function useFeatureFlag(
  name: string,
  {
    fetchPolicy,
    skip,
  }: { fetchPolicy?: WatchQueryFetchPolicy; skip?: boolean } | undefined = {}
) {
  const query = useFeatureFlagQuery({ variables: { name }, fetchPolicy, skip })

  return query.data?.featureFlag
}

export function useSuspenseFeatureFlag(
  name: string,
  {
    fetchPolicy,
    skip,
  }:
    | { fetchPolicy?: SuspenseQueryHookFetchPolicy; skip?: boolean }
    | undefined = {}
) {
  const query = useSuspenseQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(
    FeatureFlagDocument,
    skip ? skipToken : { variables: { name }, fetchPolicy }
  )

  return query.data?.featureFlag
}
