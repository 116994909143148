import {
  ClickAwayListener,
  Paper,
  PaperProps,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'

const maxWidth = 480

const Backdrop = styled(Paper)(({ theme }) => ({
  right: 0,
  flexGrow: 1,
  viewTransitionName: 'notification-sidebar',
  maxWidth: `${maxWidth}px`,
  width: '100%',
  borderRadius: 0,
  position: 'fixed',
  top: `calc(env(titlebar-area-y, ${theme.mixins.toolbar.minHeight}px) + env(titlebar-area-height, 0px))`,
  bottom: 0,
  overflow: 'auto',
  background:
    theme.palette.mode === 'dark'
      ? 'rgba(0,0,0,0.05)'
      : 'rgba(255,255,255,0.4)',

  [theme.breakpoints.down('sm')]: {
    left: 0,
    maxWidth: 'unset',
  },

  [theme.breakpoints.up('sm')]: {
    top: `calc(env(titlebar-area-y, ${theme.mixins.toolbar.minHeight}px) + env(titlebar-area-height, 8px))`,
  },
}))

function NotificationListEmpty() {
  return (
    <Typography alignSelf="center" sx={{ m: 'auto' }}>
      Nincsenek új értesítéseid!
    </Typography>
  )
}

// function NotificationList() {
//   return (
//     <List>
//       {...new Array(100).fill(0).map((_, i) => (
//         <ListItem key={i}>
//           <Typography>Notification {i}</Typography>
//         </ListItem>
//       ))}
//     </List>
//   )
// }

type NotificationSideBarProps = Partial<PaperProps>

const NotificationSideBar = forwardRef<any, NotificationSideBarProps>(
  (props, ref) => {
    return (
      <Backdrop ref={ref} {...props} data-testid="notification sidebar">
        <Stack gap={2} padding={2} height="100%">
          <Typography variant="h4">Értesítések</Typography>

          {/* <NotificationList /> */}

          <NotificationListEmpty />
        </Stack>
      </Backdrop>
    )
  }
)

const defaultStyle: React.CSSProperties = {
  transition: `transform 320ms cubic-bezier(.28,.28,.13,1.02), backdrop-filter 320ms cubic-bezier(.28,.28,.13,1.02)`,
  transform: 'translateY(0)',
  backdropFilter: 'blur(20px)',
  WebkitBackdropFilter: 'blur(20px)',
}

type TransitionStyles = Partial<{
  [key in TransitionStatus]: React.CSSProperties
}>

const transitionStyles: TransitionStyles = {
  entering: { transform: 'translateY(0)', backdropFilter: 'blur(20px)' },
  entered: { transform: 'translateY(0)', backdropFilter: 'blur(20px)' },
  exiting: { transform: 'translateY(-100%)', backdropFilter: 'none' },
  exited: { transform: 'translateY(-100%)', backdropFilter: 'none' },
  unmounted: { transform: 'translateY(-100%)', backdropFilter: 'none' },
}

export default function Wrapper({
  disabled,
  notificationButtonRef,
  ...paperProps
}: {
  disabled?: boolean
  notificationButtonRef: React.RefObject<HTMLButtonElement>
} & Partial<PaperProps>) {
  const [open, setOpen] = useState(false)
  const nodeRef = useRef<HTMLDivElement>(null)

  const appBarButtonClicked = useCallback(() => {
    setOpen((open) => !open)
  }, [])

  const onBlur = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (!notificationButtonRef.current) return
    const button = notificationButtonRef.current

    button.addEventListener('click', appBarButtonClicked)
    return () => button.removeEventListener('click', appBarButtonClicked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appBarButtonClicked, notificationButtonRef.current])

  return (
    <Transition
      nodeRef={nodeRef}
      in={open && !disabled}
      timeout={{
        enter: 310,
        appear: 0,
        exit: 310,
      }}
      unmountOnExit
      mountOnEnter
    >
      {(state) => (
        <ClickAwayListener onClickAway={onBlur}>
          <NotificationSideBar
            ref={nodeRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          />
        </ClickAwayListener>
      )}
    </Transition>
  )
}
