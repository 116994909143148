import {
  styled,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Stack,
  Backdrop,
  CssBaseline,
} from '@mui/material'
import {
  PropsWithChildren,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import AppBar from './AppBar'
import GlobalThemeProvider from '@/shared/components/GlobalThemeProvider'
import { a } from '@react-spring/web'
import { MobileNavigationPopupRefTypes } from './MobileNavigationPopup'
import DesktopSideBar from './SideBars/DesktopSideBar'
import MobileNavigationPopup from './MobileNavigationPopup'
import NotificationSideBar from './SideBars/NotificationSideBar'
import { Modals } from '@generouted/react-router/lazy'
import NavigationBreadcrumbs from './NavigationBreadcrumbs'
import FullscreenProvider, { FullscreenContext } from './FullscreenProvider'
import BrowserTab from './BrowserTab'
import OfflineModeProvider from '../OfflineModeProvider'

const AppStyles = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '*::-webkit-scrollbar': {
    width: 5,
    height: 5,
  },
  '*::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: 4,
  },
}))

const ShellStack = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  flexDirection: 'row',
  maxWidth: '100vw',
}))

const ContentBox = styled(a.div)(
  ({ theme }) =>
    ({
      width: '100%',
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 8px)`,
      [theme.breakpoints.down('sm')]: {
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px )`,
      },
      [`@media (display-mode: window-controls-overlay)`]: {
        height: `calc(100vh - env(titlebar-area-height, 64px)) !important`,
      },
      padding: theme.spacing(2),
      viewTransitionName: 'page',
      zIndex: 0,
      overflow: 'auto',
    } as any)
)

function ModalLoader() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => setShow(true), 1000)
    return () => setShow(false)
  }, [])

  return (
    <Backdrop open={show}>
      <CircularProgress sx={{ color: '#fff' }} />
    </Backdrop>
  )
}

export function AppContentLoader() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => setShow(true), 1000)
    return () => setShow(false)
  }, [])

  return (
    <Stack
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
      direction="column"
      alignItems="stretch"
      flexGrow={1}
    >
      <NavigationBreadcrumbs />
      <Stack
        sx={{
          color: '#fff',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
        direction="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        {show && <CircularProgress />}
      </Stack>
    </Stack>
  )
}

type AppContentProps = PropsWithChildren<{
  fullscreen?: boolean
}>

export function AppContent({ children, fullscreen }: AppContentProps) {
  return (
    <ContentBox id="app-content">
      <Suspense fallback={<AppContentLoader />}>
        <Stack sx={{ height: '100%' }}>
          {!fullscreen && <NavigationBreadcrumbs />}
          {children}
        </Stack>
      </Suspense>
    </ContentBox>
  )
}

function AppShell({ children }: PropsWithChildren) {
  const theme = useTheme()
  const enableDesktopNavigation = useMediaQuery(theme.breakpoints.up('md'))
  const fullscreen = useContext(FullscreenContext).fullscreen

  const appBarButtonRef = useRef<HTMLButtonElement>(null)
  const notificationButtonRef = useRef<HTMLButtonElement>(null)

  // mobile popup and desktop sidebar springing
  const mobileNavRefs = useRef<MobileNavigationPopupRefTypes | null>(null)
  const appBarBoxProps = !enableDesktopNavigation
    ? mobileNavRefs.current?.bind()
    : null

  const onAppButtonClicked = () => {
    if (!enableDesktopNavigation) {
      if (mobileNavRefs.current !== null)
        mobileNavRefs.current.springValue.get() > 0.9
          ? mobileNavRefs.current.close()
          : mobileNavRefs.current.open({ canceled: false })
    }
  }

  const onProfileButtonClicked = () => {
    mobileNavRefs.current && mobileNavRefs.current.close()
  }

  return (
    <>
      {!fullscreen && (
        <AppBar
          appBarButtonRef={appBarButtonRef}
          notificationButtonRef={notificationButtonRef}
          {...{
            onAppButtonClicked,
            onProfileButtonClicked,
            appBarBoxProps,
          }}
        >
          <MobileNavigationPopup refs={mobileNavRefs} />
        </AppBar>
      )}

      <Suspense fallback={<ModalLoader />}>
        <Modals />
      </Suspense>

      <ShellStack>
        <DesktopSideBar
          appBarButtonRef={appBarButtonRef}
          enabled={enableDesktopNavigation && !fullscreen}
        />

        <AppContent fullscreen={fullscreen}>{children}</AppContent>

        <NotificationSideBar
          notificationButtonRef={notificationButtonRef}
          disabled={fullscreen}
        />
      </ShellStack>
    </>
  )
}

export default function Base({ children }: PropsWithChildren) {
  return (
    <>
      <BrowserTab />
      <FullscreenProvider>
        <GlobalThemeProvider>
          <CssBaseline />
          <AppStyles>
            <OfflineModeProvider>
              <AppShell>{children}</AppShell>
            </OfflineModeProvider>
          </AppStyles>
        </GlobalThemeProvider>
      </FullscreenProvider>
    </>
  )
}
