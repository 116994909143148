import { getTokenIat } from '@/gql/client'
import { useEffect, useState } from 'react'

/**
 * Hook for getting remaining user session validity
 * @returns an array with two elements: minutes and seconds until
 * the session is invalidated. `null` is returned if the remaining time cannot be determined.
 * (eg. the user is not logged in)
 */
export function useSessionTimeout(): [number, number] | null {
  const getRemaining = () => {
    const iat = getTokenIat()
    if (iat === 0) {
      return null
    }
    const timeoutDate = new Date(iat! + SESSION_TIMEOUT)
    const remainingTotal = new Date(timeoutDate.getTime() - Date.now())
    return remainingTotal
  }

  const [remaining, setRemaining] = useState<Date | null>(getRemaining())

  useEffect(() => {
    const interval = setInterval(() => {
      const newRemaining = getRemaining()
      if (
        newRemaining !== null &&
        ((newRemaining.getTime() > 10 * 60 * 1000 &&
          remaining &&
          remaining >= newRemaining) ||
          remaining === newRemaining)
      )
        return

      setRemaining(newRemaining)
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (remaining === null) return null
  return [remaining.getMinutes(), remaining.getSeconds()]
}
