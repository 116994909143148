import { AnimatorYearDocument, AnimatorYearQuery } from '@/gql/generated'
import { useSuspenseQuery } from '@apollo/client'
import { MenuItem, TextField, TextFieldProps } from '@mui/material'

type AnimatorAnimatorYearSelectProps = {
  onChange: (selectedYearId: number) => void
} & Partial<Omit<TextFieldProps, 'onChange'>>

export function AnimatorYearSelect(props: AnimatorAnimatorYearSelectProps) {
  const { onChange, ...others } = props

  const { data } = useSuspenseQuery<AnimatorYearQuery>(AnimatorYearDocument)
  return (
    <TextField
      select
      id="gathering-year"
      label="Animátorév"
      onChange={(e) => {
        onChange(parseInt(e.target.value))
      }}
      {...others}
    >
      {data.animatorYear?.map((year) => (
        <MenuItem value={year?.id} key={year?.id}>
          {year?.year}
        </MenuItem>
      ))}
    </TextField>
  )
}
