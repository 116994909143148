import { ListItem, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { DarkModePreferenceContext } from '@/shared/components/GlobalThemeProvider'
import { useContext } from 'react'
import { BrightnessAuto, DarkMode, LightMode } from '@mui/icons-material'

export default function DarkModeToggle() {
  const context = useContext(DarkModePreferenceContext)
  const current =
    context.userDarkModePreference === null
      ? 'system'
      : context.userDarkModePreference === false
      ? 'light'
      : 'dark'

  const onChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    context.setDarkModePreference(
      value === 'system'
        ? null
        : value === 'light'
        ? false
        : value === 'dark'
        ? true
        : null
    )
  }

  return (
    <ListItem disablePadding disableGutters>
      <ToggleButtonGroup
        color="primary"
        sx={{
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          pt: 1,
          width: '100%',
          '& .MuiToggleButton-root': {
            borderRadius: '0 !important',
            padding: '22px !important',
          },
        }}
        exclusive
        value={current}
        onChange={onChange}
      >
        <ToggleButton value={'system'} sx={{ borderLeft: 'none' }}>
          <BrightnessAuto />
        </ToggleButton>
        <ToggleButton value={'light'}>
          <LightMode />
        </ToggleButton>
        <ToggleButton value={'dark'} sx={{ borderRight: 'none' }}>
          <DarkMode />
        </ToggleButton>
      </ToggleButtonGroup>
    </ListItem>
  )
}
