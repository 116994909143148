import { routes } from '@generouted/react-router/lazy'
import { matchRoutes, useLocation, useRouteLoaderData } from 'react-router'

export default function useFullscreenRoute() {
  const location = useLocation()
  const routeMatches = matchRoutes(routes, location)
  const routeId =
    routeMatches?.at((routeMatches?.length || 1) - 1)?.route.id || ''
  const loaderData = useRouteLoaderData(routeId) as any

  return loaderData?.fullscreen || false
}
