import { Cached } from '@mui/icons-material'
import {
  Button,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Collapse,
} from '@mui/material'
import { useSessionTimeout } from './hooks'
import {
  useHandleTokenRenewalMutation,
  useRenewUserSessionTokenMutation,
} from '@/gql/generated'
import { useState } from 'react'

type SessionTimeoutNotificationProps = {
  titlebar?: boolean
}

export default function SessionTimeoutNotification(
  props: SessionTimeoutNotificationProps
) {
  const [renewUserSessionToken] = useRenewUserSessionTokenMutation()
  const [handleTokenRenewal] = useHandleTokenRenewalMutation()
  const [loading, setLoading] = useState(false)

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const timeout = useSessionTimeout()

  const collapse =
    timeout === null ||
    timeout[0] >= 10 ||
    (timeout[0] === 0 && timeout[1] === 0)
  const timeText =
    timeout === null
      ? ''
      : `${timeout[0]}:${
          timeout[1].toString().length === 1 ? '0' + timeout[1] : timeout[1]
        }`

  const doExtendUserSession = async () => {
    setLoading(true)
    try {
      const { data } = await renewUserSessionToken()
      if (data?.renewUserSessionToken)
        await handleTokenRenewal({
          variables: { token: data.renewUserSessionToken },
        })
      else throw new Error('Token renewal failed!')
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  return (
    <div
      style={{
        ...(smallScreen &&
          props.titlebar && {
            position: 'fixed',
            top: 'calc(env(titlebar-area-height, 64px) + env(titlebar-area-y, 0px))',
            left: 0,
            right: 0,
            zIndex: 1000,
          }),
      }}
    >
      <Collapse in={!collapse} unmountOnExit>
        <Paper
          sx={{
            display: 'flex',
            p: props.titlebar && !smallScreen ? '0 0 0 0.5rem' : 1,
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            width: '100%',
            gap: 1,
            borderTopLeftRadius: smallScreen ? 0 : undefined,
            borderTopRightRadius: smallScreen ? 0 : undefined,
          }}
          elevation={smallScreen ? 1 : 0}
        >
          <Typography>{timeText}</Typography>
          <Divider flexItem orientation="vertical" />
          <Typography flexShrink={0}>Hamarosan lejár a munkamenet!</Typography>

          <Button
            size="small"
            variant="contained"
            color="secondary"
            sx={{
              ...(smallScreen ? { minWidth: 0, flexGrow: 0 } : undefined),
              appRegion: 'no-drag',
              '-webkit-app-region': 'no-drag',
            }}
            onClick={doExtendUserSession}
            disabled={loading}
          >
            {smallScreen ? <Cached fontSize="small" /> : 'Megújítás'}
          </Button>
        </Paper>
      </Collapse>
    </div>
  )
}
