import { Dialog, DialogProps, useMediaQuery, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

type ResponsiveDialogProps = PropsWithChildren<{
  open: boolean
  onClose?: () => void
  minWidth?: number
}>

export default function ResponsiveDialog(
  props: ResponsiveDialogProps & Partial<DialogProps>
) {
  const { open, onClose, ...others } = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      {...others}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { p: 1, minWidth: props.minWidth },
      }}
    >
      {props.children}
    </Dialog>
  )
}
