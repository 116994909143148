let state: Record<string, string> = {}
let listeners: (() => void)[] = []

function emitChange() {
  for (const listener of listeners) {
    listener()
  }
}

const pageTitleStore = {
  subscribe(listener: any) {
    listeners = [...listeners, listener]
    return () => {
      listeners = listeners.filter((l) => l !== listener)
    }
  },

  getSnapshot() {
    return state
  },

  set(newTitle: { path: string; title: string }) {
    if (state[newTitle.path] === newTitle.title) return

    state = { ...state, [newTitle.path]: newTitle.title }
    emitChange()
  },
}

export default pageTitleStore
