import { Box, Paper, PaperProps, useMediaQuery, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

export type LogoPaperBaseProps = PropsWithChildren<
  {
    small?: boolean
  } & Partial<PaperProps>
>

export default function LogoPaperBase({
  children,
  small,
  ...others
}: LogoPaperBaseProps) {
  const theme = useTheme()
  const big = useMediaQuery(theme.breakpoints.up('sm'))

  const paperSize = small ? '2.5rem' : big ? '6rem' : '4rem'
  const logoSize = small ? '1.4rem' : big ? '4rem' : '2.5rem'

  return (
    <Paper
      sx={{
        p: 1,
        width: paperSize,
        height: paperSize,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: small ? '0.5rem' : big ? '1.0625rem' : '0.8125rem',
        background: theme.palette.action.hover,
        alignSelf: 'center',
      }}
      {...others}
    >
      <Box
        component="div"
        sx={{ width: logoSize, height: logoSize, p: 0, m: 0 }}
      >
        {children}
      </Box>
    </Paper>
  )
}
