import Menu from '@mui/material/Menu'
import { Avatar, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import { styled } from '@mui/material'
import { useLogoutMutation, useMeQuery } from '@/gql/generated'
import { RouteButton } from '@/shared/components/RouteComponents'
import DarkModeToggle from './DarkModeToggle'
import { useNavigate } from '@/routes'
import { useParams } from 'react-router-dom'

const DropDownMenu = styled(Menu)(({ theme }) => ({
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    marginLeft: -0.5,
    marginRight: 6,
  },
}))

export default function ProfileDropDown({
  anchorTo,
  handleClose,
}: {
  anchorTo: HTMLElement | null
  handleClose: () => void
}) {
  const [logoutMutation] = useLogoutMutation()
  const meQuery = useMeQuery()
  const navigate = useNavigate()
  const params = useParams()

  const user = meQuery.data?.me?.user

  const onProfileClicked = () => {
    handleClose()
    navigate('/:lang?/profile', { params })
  }

  const onSettingsClicked = () => {
    handleClose()
    navigate('/:lang?/profile/settings', { params })
  }

  return (
    <>
      <DropDownMenu
        anchorEl={anchorTo}
        open={Boolean(anchorTo)}
        onClose={handleClose}
        slotProps={{ paper: { sx: { overflowY: 'auto' } } }}
        role="dropdown"
        aria-label="profile dropdown"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 72, left: window.innerWidth - 15 }}
      >
        <MenuItem sx={{ display: 'block' }} onClick={onProfileClicked}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar />

            <Stack>
              <Typography>{`${user?.familyName} ${user?.givenName}`}</Typography>
              <Typography variant="subtitle1" fontSize={14}>
                {user?.ldapUsername}
              </Typography>
            </Stack>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onSettingsClicked}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Beállítások
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            logoutMutation()
          }}
          role="button"
          aria-label="logout"
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Kijelentkezés
        </MenuItem>

        <DarkModeToggle />

        <Stack
          sx={{ pt: 1 }}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Tooltip title={APP_BRANCH} placement="bottom" arrow>
            <Typography variant="body2">v{APP_VERSION}</Typography>
          </Tooltip>
          <RouteButton variant="text" href={'/changelog'} onClick={handleClose}>
            Újdonságok
          </RouteButton>
        </Stack>
      </DropDownMenu>
    </>
  )
}
