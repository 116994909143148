import {
  PropsWithChildren,
  createContext,
  useLayoutEffect,
  useState,
} from 'react'
import { useMediaQuery } from '@mui/material'
import useNormalizedPathname from '../../hooks/useNormalizedPathname'
import {
  light as defaultLightTheme,
  dark as defaultDarkTheme,
} from './defaultTheme'
import {
  light as animatorLightTheme,
  dark as animatorDarkTheme,
} from './animatorTheme'
import { ThemeProvider } from '@mui/system'
import { Helmet } from 'react-helmet'

function cycleDarkModePreference(previous: boolean | null) {
  if (previous === null) return true
  if (previous) return false
  return null
}

function getInitialDarkModePreference() {
  const raw = localStorage.getItem('dark_mode')
  try {
    return JSON.parse(raw || 'null') as boolean | null
  } catch {
    localStorage.removeItem('dark_mode')
    return null
  }
}

export const DarkModePreferenceContext = createContext({
  userDarkModePreference: null as boolean | null,
  cycleDarkModePreference: () => {},
  setDarkModePreference: (value: boolean | null) => {},
})

export default function GlobalThemeProvider(props: PropsWithChildren) {
  const [, pathTree] = useNormalizedPathname()
  const systemDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [userDarkModePreference, setUserDarkModePreference] = useState<
    boolean | null
  >(getInitialDarkModePreference())
  const darkMode = userDarkModePreference ?? systemDarkMode
  const [theme, setTheme] = useState(
    darkMode ? defaultDarkTheme : defaultLightTheme
  )

  // in dark mode, the palette.background.paper color is not the same
  // as the appbar's background color with elevation = 2
  const metaTagDarkColor = '#232323'

  useLayoutEffect(() => {
    let newTheme = darkMode ? defaultDarkTheme : defaultLightTheme
    if (pathTree[0] === 'animator')
      newTheme = darkMode ? animatorDarkTheme : animatorLightTheme

    setTheme(newTheme)
  }, [pathTree, darkMode])

  return (
    <DarkModePreferenceContext.Provider
      value={{
        userDarkModePreference,
        cycleDarkModePreference: () => {
          const result = cycleDarkModePreference(userDarkModePreference)
          setUserDarkModePreference(result)
          if (result === null) return localStorage.removeItem('dark_mode')
          localStorage.setItem('dark_mode', JSON.stringify(result))
        },
        setDarkModePreference: (value: boolean | null) => {
          setUserDarkModePreference(value)
          if (value === null) return localStorage.removeItem('dark_mode')
          localStorage.setItem('dark_mode', JSON.stringify(value))
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta
            name="theme-color"
            content={darkMode ? metaTagDarkColor : theme.palette.primary.main}
          />
        </Helmet>
        {props.children}
      </ThemeProvider>
    </DarkModePreferenceContext.Provider>
  )
}
