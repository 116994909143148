// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from "@generouted/react-router/client";

export type Path =
  | `/:lang?`
  | `/:lang?/animator`
  | `/:lang?/animator/esemenyek`
  | `/:lang?/animator/esemenyek/:id`
  | `/:lang?/animator/kozgyulesek`
  | `/:lang?/animator/kozgyulesek/:id`
  | `/:lang?/animator/kozgyulesek/:id/vetites`
  | `/:lang?/animator/kozgyulesek/uj`
  | `/:lang?/animator/tagok`
  | `/:lang?/animator/tagok/:id`
  | `/:lang?/changelog`
  | `/:lang?/edu`
  | `/:lang?/profile`
  | `/:lang?/profile/settings`;

export type Params = {
  "/:lang?": { lang?: string };
  "/:lang?/animator": { lang?: string };
  "/:lang?/animator/esemenyek": { lang?: string };
  "/:lang?/animator/esemenyek/:id": { lang?: string; id: string };
  "/:lang?/animator/kozgyulesek": { lang?: string };
  "/:lang?/animator/kozgyulesek/:id": { lang?: string; id: string };
  "/:lang?/animator/kozgyulesek/:id/vetites": { lang?: string; id: string };
  "/:lang?/animator/kozgyulesek/uj": { lang?: string };
  "/:lang?/animator/tagok": { lang?: string };
  "/:lang?/animator/tagok/:id": { lang?: string; id: string };
  "/:lang?/changelog": { lang?: string };
  "/:lang?/edu": { lang?: string };
  "/:lang?/profile": { lang?: string };
  "/:lang?/profile/settings": { lang?: string };
};

export type ModalPath = `/login`;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<
  Path,
  Params,
  ModalPath
>();
export const { redirect } = utils<Path, Params>();
